import { LIST_TICKET_SIZE } from "../../Constants/Constants";
import * as actionType from "../actions/authenticationActions";

const initState = {
  session: null,
  psw: null,
  error: null,
  pending: false,
  info: false,
  signedInUser: null,
  userPreferences: null,
  listTicketSize: LIST_TICKET_SIZE.INIT,
  userInfo: null
};

const authenticationReducer = (state = { ...initState, session: undefined }, action) => {
  switch (action.type) {
    case actionType.GET_SESSION_ERROR:
      return {
        ...initState,
        error: { message: "Error during getSession!" },
      };
    case actionType.NO_USER:
      return { ...initState };
    case actionType.SET_SESSION:
      return {
        ...state,
        session: action.payload.session,
      };
    case actionType.LOGOUT:
      return { ...state, session: null, psw: null, signedInUser: null };
    case actionType.LOGIN_START:
      return { ...initState, pending: true };
    case actionType.LOGIN_SUCESS:
      return {
        ...initState,
        pending: false,
        session: action.payload.session,
        psw: action.payload.psw,
        signedInUser: action.payload.signedInUser,
      };
    case actionType.USER_PREFERENCES:
      return {
        ...state,
        userPreferences: action.payload.userPreferences,
      };
    case actionType.LIST_TICKET_SIZE:
      return {
        ...state,
        listTicketSize: action.payload.listTicketSize,
      };
    case actionType.USER_INFO:
      return {
        ...state,
        userInfo: action.payload.userInfo,
      };
    case actionType.LOGIN_FAILED:
      return { ...initState, pending: false, error: action.payload.error };
    case actionType.SIGN_UP_FAILED:
      return {
        ...initState,
        error: action.payload.error,
      };
    case actionType.SIGN_UP_SUCESS:
      return {
        ...initState,
        info: action.payload.info,
      };
    case actionType.SIGN_UP_START:
      return { ...initState, pending: true };
    case actionType.RESET_PSW_START:
      return { ...initState, pending: true };
    case actionType.RESET_PSW_SUCESS:
      return {
        ...initState,
        info: true,
      };
    case actionType.RESET_PSW_FAILED:
      return {
        ...initState,
        info: state.info,
        error: action.payload.error,
      };
    case actionType.RESET_PSW_CONF_REQ:
      return {
        ...initState,
        info: true,
      };

    case actionType.CLEAR_AUTH_STATE:
      return {
        ...state,
        error: null,
        pending: false,
        info: false,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
