import { FETCH_STORAGE_PREFIX } from "./axiosUtil";

export const storage = localStorage;

/**
 * Store data in cache only key value pair
 * @param {String} key key
 * @param {String} value value
 */
export const storeItem = (key, value) => {
  storage.setItem(key, value);
};

/**
 * Get data
 * @param {String} key key
 * @return {String} value
 */
export const restoreItem = (key) => {
  return storage.getItem(key);
};

/**
 * Remove element
 * @param {String} key key
 */
export const removeStorageItem = (key, fetchPrefix = false) => {
  if (fetchPrefix) {
    key = FETCH_STORAGE_PREFIX + key
  }
  storage.removeItem(key);
};

/**
 * Clear storage
 */
export const clearStorage = () => storage.clear();
