import * as msg from "../../../dermuscomponents/src/Constants/Message";

export const UTCToLocal = (timeUTC) =>
  new Date(
    Date.UTC(
      timeUTC.getFullYear(),
      timeUTC.getMonth(),
      timeUTC.getDate(),
      timeUTC.getHours(),
      timeUTC.getMinutes(),
      timeUTC.getSeconds(),
      timeUTC.getMilliseconds()
    )
  );

export const getLocalDateStringFromUTCISO = (str, onlyDays = false, placeholder = msg.NO_RECORD_YET) => {
  if (onlyDays) {
    return str ? UTCToLocal(new Date(str)).toLocaleString('en-GB', { day: "2-digit", month: "2-digit", year: "2-digit" }).split("/").join(".").split(",").join(".") : placeholder;
  }
  return str ? UTCToLocal(new Date(str)).toLocaleString('en-GB', { day: "2-digit", month: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }).split("/").join(".").split(",").join(".") : placeholder;
}

export const convertRawDateToFilenameDate = (rawDate) => {
  let currentDate = rawDate.replaceAll(":", "-").replaceAll(".", "-")
  currentDate = currentDate.replaceAll("- ", "_")
  return currentDate
}

export const getLocalNow = () => {
  return UTCToLocal(new Date()).toLocaleString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" }).split("/").join(".").split(",").join(".")
}

export const getISONow = () => {
  const now = new Date();
  try {
    return now.toISOString().split(".")[0];
  } catch (e) {
    return "";
  }
};

export const sinceGivenDate = (targetDateStr) => {
  if (!targetDateStr) {
    return "";
  }
  const currentDate = new Date();
  const targetDate = UTCToLocal(new Date(targetDateStr));
  var differenceDate;
  if (currentDate > targetDate) {
    differenceDate = new Date(currentDate - targetDate);
  } else {
    differenceDate = new Date(targetDate - currentDate);
  }
  const hours = differenceDate.getUTCHours();
  const minutes = differenceDate.getUTCMinutes();
  const days = differenceDate.getUTCDate() - 1;
  const months = differenceDate.getUTCMonth();
  const years = differenceDate.getUTCFullYear() - new Date(0).getFullYear();

  if (years > 3) {
    return msg.YEARS_AGO;
  } else if (years > 1) {
    return msg.FEW_YEARS_AGO;
  } else if (years > 0) {
    return msg.ONE_YEAR_AGO;
  } else if (months > 3) {
    return msg.MONTHS_AGO;
  } else if (months > 1) {
    return msg.FEW_MONTHS_AGO;
  } else if (months > 0) {
    return msg.A_MONTH_AGO;
  } else if (days > 14) {
    return msg.FEW_WEEKS_AGO;
  } else if (days > 6) {
    return msg.A_WEEK_AGO;
  } else if (days > 1) {
    return msg.FEW_DAYS_AGO;
  } else if (days > 0) {
    return msg.A_DAY_AGO;
  } else if (hours > 1) {
    return msg.FEW_HOURS_AGO;
  } else if (hours > 0) {
    return msg.A_HOUR_AGO;
  } else if (minutes > 5) {
    return msg.FEW_MINUTES_AGO;
  } else {
    return msg.RECENTLY;
  }
};
