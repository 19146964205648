class Segmentation {
    /**
     * Constructor
     * @param {Integer} id id
     * @param {Integer} record_id record id
     * @param {String} date date
     * @param {String} file_id file id
     * @param {String} file_url url of file
     * @param {Dict} segmentation_type array of segmentation typoe (AUTOMATA OR MANUAL)
     * @param {Dict} version array or version
     * @param {Dict} state state 
     * @param {Integer} processed_frame processed frame
     * @param {Integer} source_segmentation_id soruce segmentation
     */
    constructor(
        id,
        record_id,
        date,
        file_id,
        file_url,
        segmentation_type,
        version,
        state,
        processed_frame,
        source_segmentation_id
    ) {
        this.id = id;
        this.record_id = record_id;
        this.date = date;
        this.file_id = file_id;
        this.file_url = file_url;
        this.segmentation_type = segmentation_type;
        this.version = version;
        this.state = state;
        this.processed_frame = processed_frame;
        this.source_segmentation_id = source_segmentation_id;
    }
}
export default Segmentation;
