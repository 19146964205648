import { drawAnnotationPoint_v2, drawCircle, drawFilledCircle } from "./imageAux";
import { neutralblack, neutralwhite } from "../../Themes/dermusTheme";
import { NUM_OF_A_LINE, US_ANNOTATION_COLOR } from "../Constants/Constants";
import { getRealCoordPoints } from "./utility";

const MM1_LENGTH = 8;
const MM5_LENGTH = 15;
const FONT_SIZE = 14;
const LINE_WIDTH = 3;
export const LINE_COLOR = "#ff2058";
export const LINE_COLOR_WHITE = "#ffffff";

//Params of adaptive coloring of dimensions and numbers
const WIDTH_TO_SHOW_DECIMALS = 3;
const NUM_OF_DECIMALS_PER_MM_LARGE_IMAGE = 1;
const NUM_OF_DECIMALS_PER_MM_SMALL_IMAGE = 10;

const THRESHOLD_FOR_US = 0.49;
const THRESHOLD_FOR_OPTICS = 0.49;

//Do not show numbers on the upper left corner in an epsilon environment
const EPSILON_HORIZONTAL = 0.5;
const EPSILON_VERTICAL = 0.3;

//Weights for channels from algorithm
//(https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area)
const RED_WEIGHT = 0.299
const GREEN_WEIGHT = 0.587;
const BLUE_WEIGHT = 0.114

export const drawVerticalCenterLine = (ctx, x, config) => {
  let lineWidth
  if (config?.lineWidth) {
    lineWidth = config?.lineWidth
  } else {
    lineWidth = Math.max(1, (ctx.canvas.width / 300) * LINE_WIDTH);
  }
  let color
  if (config?.color) {
    color = config?.color
  } else {
    color = LINE_COLOR
  }
  let fontSize
  if (config?.fontSize) {
    fontSize = config.fontSize;
  } else {
    fontSize = ((ctx.canvas.width / 400) * FONT_SIZE).toString();
  }
  const length = 20

  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.beginPath();
  ctx.moveTo(x, 1.5 * fontSize);
  ctx.lineTo(x, 1.5 * fontSize + length);

  ctx.closePath();
  ctx.stroke();

  ctx.setLineDash([10, 20])
  ctx.beginPath();
  ctx.moveTo(x, 1.5 * fontSize + length);
  ctx.lineTo(x, ctx.canvas.height);
  ctx.closePath();
  ctx.stroke();
  ctx.setLineDash([])
}

/**
 * Draw center line to opt image based on us zooimg
 * @param {Context} ctx context
 * @param {Array<Number>} T trasnformation matrix
 * @param {Object} zoomProps zoom properties {scale, x, y} -> own scale params (x: 0-1, y: 0-1, center of zoomed window)
 * @param {{width, height}} optRealSize opt size  in mm
 * @param {{width, height}} optImageSize opt size in px
 * @param {{width, height}} usRealSize us size in mm
 * @param {Number} paddingInMM padding of opt image in mm
 */
export const drawZoomGeneralCenterLine = (
  ctx,
  T,
  zoomPropsUS,
  zoomPropsOpt,
  optRealSize,
  optImageSize,
  usRealSize,
  paddingInMM,
  usCropX,
  config,
  drawUsCenterPoint
) => {
  let lineWidth
  if (config?.lineWidth) {
    lineWidth = config?.lineWidth
  } else {
    lineWidth = Math.max(1, (ctx.canvas.width / 300) * LINE_WIDTH);
  }
  let color
  if (config?.color) {
    color = config?.color
  } else {
    color = LINE_COLOR
  }
  const realCoordPoints = getRealCoordPoints(T, optImageSize, optRealSize)
  const realTL = realCoordPoints[0]
  const realBR = realCoordPoints[1]
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.beginPath();

  let optWidthMM = optRealSize.width
  const optHeightMM = optRealSize.height
  const usWidthMM = usRealSize.width
  if (paddingInMM) {
    //if us width is larger padding is added to opt (opt width be us  width)
    realTL.x = realTL.x + paddingInMM / 2;
    realBR.x = realBR.x + paddingInMM / 2;
    optWidthMM = optWidthMM + paddingInMM;
  }

  const realDifVector = { x: realBR.x - realTL.x, y: realBR.y - realTL.y };

  const lengthRealDifVector = Math.sqrt(
    realDifVector.x * realDifVector.x + realDifVector.y * realDifVector.y
  );

  const ratio = {
    //x,y componenent ratio
    x: realDifVector.x / lengthRealDifVector,
    y: realDifVector.y / lengthRealDifVector,
  };


  const width = ctx.canvas.width;
  const height = ctx.canvas.height;

  let x = //start pos of line
    (realTL.x / optWidthMM) * width + //offset because uh
    (zoomPropsUS.x - 1 / (2 * zoomPropsUS.scale)) * usWidthMM / optWidthMM * width * ratio.x;
  let y = //start pos of line
    (realTL.y / optHeightMM) * height + //offset because uh
    (zoomPropsUS.x - 1 / (2 * zoomPropsUS.scale)) * usWidthMM / optWidthMM * width * ratio.y;




  const wOffsetRealWorld = { x: ratio.x * usCropX, y: ratio.y * usCropX }; //x,y components of length of the us image
  const offsetPx = {

    x: (wOffsetRealWorld.x * width) / optWidthMM,
    y: (wOffsetRealWorld.y * height) / optHeightMM,
  };
  x = x + offsetPx.x
  y = y + offsetPx.y

  const wRealWorld = { x: ratio.x * usWidthMM, y: ratio.y * usWidthMM }; //x,y components of length of the us image
  const wPx = {
    //length of line /convert wRealWorld to px
    x: ((wRealWorld.x / zoomPropsUS.scale) * width) / optWidthMM,
    y: ((wRealWorld.y / zoomPropsUS.scale) * height) / optHeightMM,
  };

  const zoomedWX = calcZoomedPosition(x + wPx.x, zoomPropsOpt.scale, zoomPropsOpt.x, width);
  const zoomedWY = calcZoomedPosition(y + wPx.y, zoomPropsOpt.scale, zoomPropsOpt.y, height);

  const zX = calcZoomedPosition(x, zoomPropsOpt.scale, zoomPropsOpt.x, width);
  const zY = calcZoomedPosition(y, zoomPropsOpt.scale, zoomPropsOpt.y, height);


  const p1IsInImage = (zX > 0 && zX < width && zY > 0 && zY < height)
  const p2IsInImage = (zoomedWX > 0 && zoomedWX < width && zoomedWY > 0 && zoomedWY < height)


  ctx.moveTo(zX, zY);
  ctx.lineTo(zoomedWX, zoomedWY);
  ctx.stroke();


  const alpha = -Math.atan2(realBR.y - realTL.y, realBR.x - realTL.x)


  if (drawUsCenterPoint) {
    const length = 10
    let x = (realTL.x / optWidthMM) * width + offsetPx.x
    let y = (realTL.y / optHeightMM) * height + offsetPx.y


    const wPx = {
      //length of line /convert wRealWorld to px
      x: ((wRealWorld.x / 1) * width) / optWidthMM,
      y: ((wRealWorld.y / 1) * height) / optHeightMM,
    };

    const oWX = calcZoomedPosition(x + wPx.x, zoomPropsOpt.scale, zoomPropsOpt.x, width);
    const oWY = calcZoomedPosition(y + wPx.y, zoomPropsOpt.scale, zoomPropsOpt.y, height);

    const oX = calcZoomedPosition(x, zoomPropsOpt.scale, zoomPropsOpt.x, width);
    const oY = calcZoomedPosition(y, zoomPropsOpt.scale, zoomPropsOpt.y, height);


    const directionV = { y: -(oWX - oX), x: (oWY - oY) }
    const d = Math.hypot(directionV.x, directionV.y)
    directionV.x = directionV.x / d
    directionV.y = directionV.y / d


    x = (oX + oWX) / 2
    y = (oY + oWY) / 2
    if (Math.min(zY, zoomedWY) <= y && Math.max(zY, zoomedWY) >= y &&
      Math.min(zX, zoomedWX) <= x && Math.max(zX, zoomedWX) >= x) {

      ctx.moveTo((oX + oWX) / 2 - directionV.x * length, (oY + oWY) / 2 - directionV.y * length);
      ctx.lineTo((oX + oWX) / 2 + directionV.x * length, (oY + oWY) / 2 + directionV.y * length);
      ctx.stroke();
    }
  }
  if (p1IsInImage || p2IsInImage) {
    //out of image
    if (zX < 0 || zX > width || zY < 0 || zY > height) {
    } else {
      drawTriangleGeneral(ctx, { x: zX, y: zY }, alpha, color)
    }
  }
};

export const drawCenterCross = (
  ctx,
  T,
  zoomPropsOpt,
  optRealSize,
  optImageSize,
  usRealSize,
  paddingInMM,
  usCropX,
  config,
) => {
  let lineWidth, length
  if (config?.lineWidth) {
    lineWidth = config?.lineWidth
  } else {
    lineWidth = Math.max(1, (ctx.canvas.width / 300) * LINE_WIDTH * 2);
  }
  let color
  if (config?.color) {
    color = config?.color
  } else {
    color = LINE_COLOR
  }
  if (config?.length) {
    length = config?.length
  } else {
    length = Math.max(1, (ctx.canvas.width / 100) * LINE_WIDTH);
  }
  const realCoordPoints = getRealCoordPoints(T, optImageSize, optRealSize)
  const realTL = realCoordPoints[0]
  const realBR = realCoordPoints[1]
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.beginPath();

  let optWidthMM = optRealSize.width
  const optHeightMM = optRealSize.height
  const usWidthMM = usRealSize.width
  if (paddingInMM) {
    //if us width is larger padding is added to opt (opt width be us  width)
    realTL.x = realTL.x + paddingInMM / 2;
    realBR.x = realBR.x + paddingInMM / 2;
    optWidthMM = optWidthMM + paddingInMM;
  }

  const realDifVector = { x: realBR.x - realTL.x, y: realBR.y - realTL.y };

  const lengthRealDifVector = Math.sqrt(
    realDifVector.x * realDifVector.x + realDifVector.y * realDifVector.y
  );

  const ratio = {
    //x,y componenent ratio
    x: realDifVector.x / lengthRealDifVector,
    y: realDifVector.y / lengthRealDifVector,
  };


  const width = ctx.canvas.width;
  const height = ctx.canvas.height;





  const wOffsetRealWorld = { x: ratio.x * usCropX, y: ratio.y * usCropX }; //x,y components of length of the us image
  const offsetPx = {

    x: (wOffsetRealWorld.x * width) / optWidthMM,
    y: (wOffsetRealWorld.y * height) / optHeightMM,
  };
  let x = (realTL.x / optWidthMM) * width + offsetPx.x
  let y = (realTL.y / optHeightMM) * height + offsetPx.y
  const wRealWorld = { x: ratio.x * usWidthMM, y: ratio.y * usWidthMM }; //x,y components of length of the us image





  const wPx = {
    //length of line /convert wRealWorld to px
    x: ((wRealWorld.x / 1) * width) / optWidthMM,
    y: ((wRealWorld.y / 1) * height) / optHeightMM,
  };

  const oWX = calcZoomedPosition(x + wPx.x, zoomPropsOpt.scale, zoomPropsOpt.x, width);
  const oWY = calcZoomedPosition(y + wPx.y, zoomPropsOpt.scale, zoomPropsOpt.y, height);

  const oX = calcZoomedPosition(x, zoomPropsOpt.scale, zoomPropsOpt.x, width);
  const oY = calcZoomedPosition(y, zoomPropsOpt.scale, zoomPropsOpt.y, height);


  const directionV = { y: -(oWX - oX), x: (oWY - oY) }
  const d = Math.hypot(directionV.x, directionV.y)
  directionV.x = directionV.x / d
  directionV.y = directionV.y / d


  ctx.moveTo((oX + oWX) / 2 - directionV.x * length, (oY + oWY) / 2 - directionV.y * length);
  ctx.lineTo((oX + oWX) / 2 + directionV.x * length, (oY + oWY) / 2 + directionV.y * length);


  ctx.moveTo((oX + oWX) / 2 - ratio.x * length, (oY + oWY) / 2 - ratio.y * length);
  ctx.lineTo((oX + oWX) / 2 + ratio.x * length, (oY + oWY) / 2 + ratio.y * length);
  ctx.stroke();


};
/**
 * Draw center line to opt image based on us zooimg
 * @param {Context} ctx context
 * @param {Object} zoomProps zoom properties {scale, x, y} -> own scale params (x: 0-1, y: 0-1, center of zoomed window)
 * @param {{width, height}} optRealSize opt size  in mm
 * @param {{width, height}} optImageSize opt size in px
 * @param {{width, height}} usRealSize us size in mm
 * @param {Number} paddingInMM padding of opt image in mm
 */
export const drawZoomCenterLineMiddle = (
  ctx,
  zoomProps,
  optRealSize,
  optImageSize,
  usRealSize,
  paddingInMM,
  usCropX,
  config
) => {
  let color
  if (config?.color) {
    color = config?.color
  } else {
    color = LINE_COLOR
  }
  ctx.lineWidth = Math.max(1, (ctx.canvas.width / 300) * LINE_WIDTH);
  ctx.strokeStyle = color;
  ctx.beginPath();
  const height = ctx.canvas.height;
  const width = ctx.canvas.width;
  const x = Math.max(0, (optRealSize.width - usRealSize.width) / 2) / optRealSize.width * width + (zoomProps.x - 1 / 2 / zoomProps.scale) * usRealSize.width / optRealSize.width * width

  const widthPX = (usRealSize.width / zoomProps.scale) / optRealSize.width * width
  ctx.moveTo(x, height / 2);
  ctx.lineTo(x + widthPX, height / 2);
  ctx.stroke();
  drawTriangleGeneral(ctx, { x, y: height / 2 }, 0)
};




/**
 * Draw center line to opt image based on us zooimg
 * @param {Context} ctx context
 * @param {Object} zoomProps zoom properties {scale, positionX, positionY}
 * @param {Number} usWidthMM us width in mm
 * @param {Number} optWidthMM opt width in mm
 * @param {Number} optHeightMM opt height in mm
 * @param {Object} realTL start poisition of the line {x,y} in opt real coordinate system
 * @param {Object} realBR other point of the line {x,y} in real opt coordinate system
 * @param {Number} paddingInMM padding of opt image in mm
 * @param {Number} lineWidth line width
 */
export const drawZoomCenterLine = (
  ctx,
  zoomProps,
  usWidthMM,
  optWidthMM,
  optHeightMM,
  realTL,
  realBR,
  paddingInMM,
  usSizePx,
  lineWidth
) => {
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;

  const widthUs = usSizePx.width;
  if (lineWidth) {
    ctx.lineWidth = lineWidth;
  } else {
    ctx.lineWidth = (width / 300) * LINE_WIDTH;
  }
  ctx.strokeStyle = LINE_COLOR;

  ctx.beginPath();

  if (paddingInMM) {
    //if us width is larger padding is added to opt (opt width be us  width)
    realTL.x = realTL.x + paddingInMM / 2;
    realBR.x = realBR.x + paddingInMM / 2;
    optWidthMM = optWidthMM + paddingInMM;
  }

  const realDifVector = { x: realBR.x - realTL.x, y: realBR.y - realTL.y };

  const lengthRealDifVector = Math.sqrt(
    realDifVector.x * realDifVector.x + realDifVector.y * realDifVector.y
  );

  const ratio = {
    //x,y componenent ratio
    x: realDifVector.x / lengthRealDifVector,
    y: realDifVector.y / lengthRealDifVector,
  };

  const x = //start pos of line
    (realTL.x / optWidthMM) * width + //offset because uh
    //(((optWidthMM - usWidthMM) / optWidthMM) * width) / 2 + //opt img is larger and align 2 img vertically center,
    (((Math.abs(zoomProps.positionX / widthUs * width) / zoomProps.scale) * usWidthMM) / // x component of offset because of uh position
      optWidthMM) *
    ratio.x;
  const y = //start pos of line
    (realTL.y / optHeightMM) * height + //offset because uh
    (((Math.abs(zoomProps.positionX / widthUs * width) / zoomProps.scale) * usWidthMM) / // y component of offset because of uh position
      optWidthMM) *
    ratio.y;


  const wRealWorld = { x: ratio.x * usWidthMM, y: ratio.y * usWidthMM }; //x,y components of length of the us image

  const wPx = {
    //length of line /convert wRealWorld to px
    x: ((wRealWorld.x / zoomProps.scale) * width) / optWidthMM,
    y: ((wRealWorld.y / zoomProps.scale) * height) / optHeightMM,
  };
  ctx.moveTo(x, y);
  ctx.lineTo(x + wPx.x, y + wPx.y);
  ctx.stroke();

  return { x, y }
};




/**
 * Draw center line to opt image based on us zooimg
 * @param {Context} ctx context
 * @param {Array<Number>} T trasnformation matrix
 * @param {Object} zoomProps zoom properties {scale, x, y} -> own scale params (x: 0-1, y: 0-1, center of zoomed window)
 * @param {{width, height}} optRealSize opt size  in mm
 * @param {{width, height}} optImageSize opt size in px
 * @param {{width, height}} usRealSize us size in mm
 * @param {Number} paddingInMM padding of opt image in mm
 * @param {Array<{x,y}>} usAnnotationPoints annotation point (0-1 range)
 * @param {Boolean} triangle is it triangle or square
 */
export const drawGeneralUSAnnotationPointsToOpt = (
  ctx,
  T,
  zoomPropsOpt,
  optRealSize,
  optImageSize,
  usRealSize,
  paddingInMM,
  usCropX,
  usAnnotationPoints,
  triangle = false,
  color = LINE_COLOR,
) => {
  const zoomProps = { x: 0.5, y: 0.5, scale: 1 }
  const realCoordPoints = getRealCoordPoints(T, optImageSize, optRealSize)
  const realTL = realCoordPoints[0]
  const realBR = realCoordPoints[1]

  let optWidthMM = optRealSize.width
  const optHeightMM = optRealSize.height
  const usWidthMM = usRealSize.width
  if (paddingInMM) {
    //if us width is larger padding is added to opt (opt width be us  width)
    realTL.x = realTL.x + paddingInMM / 2;
    realBR.x = realBR.x + paddingInMM / 2;
    optWidthMM = optWidthMM + paddingInMM;
  }

  const realDifVector = { x: realBR.x - realTL.x, y: realBR.y - realTL.y };

  const lengthRealDifVector = Math.sqrt(
    realDifVector.x * realDifVector.x + realDifVector.y * realDifVector.y
  );

  const ratio = {
    //x,y componenent ratio
    x: realDifVector.x / lengthRealDifVector,
    y: realDifVector.y / lengthRealDifVector,
  };


  const width = ctx.canvas.width;
  const height = ctx.canvas.height;

  let x = //start pos of line
    (realTL.x / optWidthMM) * width + //offset because uh
    (zoomProps.x - 1 / (2 * zoomProps.scale)) * usWidthMM / optWidthMM * width * ratio.x;
  let y = //start pos of line
    (realTL.y / optHeightMM) * height + //offset because uh
    (zoomProps.x - 1 / (2 * zoomProps.scale)) * usWidthMM / optWidthMM * width * ratio.y;

  const wOffsetRealWorld = { x: ratio.x * usCropX, y: ratio.y * usCropX }; //x,y components of length of the us image
  const offsetPx = {

    x: (wOffsetRealWorld.x * width) / optWidthMM,
    y: (wOffsetRealWorld.y * height) / optHeightMM,
  };
  x = x + offsetPx.x
  y = y + offsetPx.y

  const wRealWorld = { x: ratio.x * usWidthMM, y: ratio.y * usWidthMM }; //x,y components of length of the us image
  const wPx = {
    //length of line /convert wRealWorld to px
    x: ((wRealWorld.x / zoomProps.scale) * width) / optWidthMM,
    y: ((wRealWorld.y / zoomProps.scale) * height) / optHeightMM,
  };
  if (triangle) {
    let angle = -Math.atan2((
      calcZoomedPosition(y + wPx.y * usAnnotationPoints[0][0].x, zoomPropsOpt.scale, zoomPropsOpt.y, height) -
      calcZoomedPosition(y + wPx.y * usAnnotationPoints[1][0].x, zoomPropsOpt.scale, zoomPropsOpt.y, height)),
      (calcZoomedPosition(x + wPx.x * usAnnotationPoints[0][0].x, zoomPropsOpt.scale, zoomPropsOpt.x, width) -
        calcZoomedPosition(x + wPx.x * usAnnotationPoints[1][0].x, zoomPropsOpt.scale, zoomPropsOpt.x, width))) - Math.PI


    usAnnotationPoints.forEach((point, ind) => {
      point.forEach(item => {
        drawTriangleGeneral(ctx,
          {
            x: calcZoomedPosition(x + wPx.x * item.x, zoomPropsOpt.scale, zoomPropsOpt.x, width),
            y: calcZoomedPosition(y + wPx.y * item.x, zoomPropsOpt.scale, zoomPropsOpt.y, height)
          },
          angle + ind * Math.PI,
          color,
          false)
      })
    })
  } else {
    usAnnotationPoints.forEach((point, ind) => {
      point.forEach(item => {
        drawAnnotationPoint_v2(ctx, calcZoomedPosition(x + wPx.x * item.x, zoomPropsOpt.scale, zoomPropsOpt.x, width),
          calcZoomedPosition(y + wPx.y * item.x, zoomPropsOpt.scale, zoomPropsOpt.y, height), item.label || 1, false, true)
      })
    })
  }
}




/**
 * Draw center line to opt image based on us zooimg
 * @param {Context} ctx context
 * @param {Array<Number>} T trasnformation matrix
 * @param {Object} zoomProps zoom properties {scale, x, y} -> own scale params (x: 0-1, y: 0-1, center of zoomed window)
 * @param {{width, height}} optRealSize opt size  in mm
 * @param {{width, height}} optImageSize opt size in px
 * @param {{width, height}} usRealSize us size in mm
 * @param {Number} paddingInMM padding of opt image in mm
 * @param {Array<{x,y}>} usAnnotationPoints annotation point (0-1 range)
 */
export const drawGeneralUSAnnotationPointsToOptLegacy = (
  ctx,
  T,
  optRealSize,
  optImageSize,
  usRealSize,
  paddingInMM,
  usCropX,
  usAnnotationPoints,
) => {

  const zoomProps = { x: 0.5, y: 0.5, scale: 1 }
  const realCoordPoints = getRealCoordPoints(T, optImageSize, optRealSize)
  const realTL = realCoordPoints[0]
  const realBR = realCoordPoints[1]

  let optWidthMM = optRealSize.width
  const optHeightMM = optRealSize.height
  const usWidthMM = usRealSize.width
  if (paddingInMM) {
    //if us width is larger padding is added to opt (opt width be us  width)
    realTL.x = realTL.x + paddingInMM / 2;
    realBR.x = realBR.x + paddingInMM / 2;
    optWidthMM = optWidthMM + paddingInMM;
  }

  const realDifVector = { x: realBR.x - realTL.x, y: realBR.y - realTL.y };

  const lengthRealDifVector = Math.sqrt(
    realDifVector.x * realDifVector.x + realDifVector.y * realDifVector.y
  );

  const ratio = {
    //x,y componenent ratio
    x: realDifVector.x / lengthRealDifVector,
    y: realDifVector.y / lengthRealDifVector,
  };


  const width = ctx.canvas.width;
  const height = ctx.canvas.height;

  let x = //start pos of line
    (realTL.x / optWidthMM) * width + //offset because uh
    (zoomProps.x - 1 / (2 * zoomProps.scale)) * usWidthMM / optWidthMM * width * ratio.x;
  let y = //start pos of line
    (realTL.y / optHeightMM) * height + //offset because uh
    (zoomProps.x - 1 / (2 * zoomProps.scale)) * usWidthMM / optWidthMM * width * ratio.y;

  const wOffsetRealWorld = { x: ratio.x * usCropX, y: ratio.y * usCropX }; //x,y components of length of the us image
  const offsetPx = {

    x: (wOffsetRealWorld.x * width) / optWidthMM,
    y: (wOffsetRealWorld.y * height) / optHeightMM,
  };
  x = x + offsetPx.x
  y = y + offsetPx.y

  const wRealWorld = { x: ratio.x * usWidthMM, y: ratio.y * usWidthMM }; //x,y components of length of the us image
  const wPx = {
    //length of line /convert wRealWorld to px
    x: ((wRealWorld.x / zoomProps.scale) * width) / optWidthMM,
    y: ((wRealWorld.y / zoomProps.scale) * height) / optHeightMM,
  };

  usAnnotationPoints.forEach((point) => {
    drawFilledCircle(x + wPx.x * point.x, y + wPx.y * point.x, ctx, US_ANNOTATION_COLOR);
    drawCircle(x + wPx.x * point.x, y + wPx.y * point.x, ctx, US_ANNOTATION_COLOR);
  })
}



/**
 * Draw annotation point to opt iamge
 * @param {Context} ctx context
 * @param {Array<{x,y}>} usAnnotationPoints annotation point (0-1 range)
 * @param {Object} zoomProps zoom properties {scale, positionX, positionY}
 * @param {Number} usWidthMM us width in mm
 * @param {Number} optWidthMM opt width in mm
 * @param {Number} optHeightMM opt height in mm
 * @param {Object} realTL start poisition of the line {x,y} in opt real coordinate system
 * @param {Object} realBR other point of the line {x,y} in real opt coordinate system
 * @param {Number} paddingInMM padding of opt image in mm
 */
export const drawUSAnnotationPointsToOpt = (ctx, usAnnotationPoints, zoomProps, usWidthMM, optWidthMM, optHeightMM, realTL, realBR, paddingInMM) => {

  const width = ctx.canvas.width;
  const height = ctx.canvas.height;

  if (paddingInMM) {
    //if us width is larger padding is added to opt (opt width be us  width)
    realTL.x = realTL.x + paddingInMM / 2;
    realBR.x = realBR.x + paddingInMM / 2;
    optWidthMM = optWidthMM + paddingInMM;
  }
  const realDifVector = { x: realBR.x - realTL.x, y: realBR.y - realTL.y };

  const lengthRealDifVector = Math.sqrt(
    realDifVector.x * realDifVector.x + realDifVector.y * realDifVector.y
  );
  const ratio = {
    //x,y componenent ratio
    x: realDifVector.x / lengthRealDifVector,
    y: realDifVector.y / lengthRealDifVector,
  };

  const x = (realTL.x / optWidthMM) * width
  const y = (realTL.y / optHeightMM) * height
  usAnnotationPoints.forEach((point) => {
    const wRealWorld = { x: ratio.x * point.x * usWidthMM, y: ratio.y * point.x * usWidthMM }; //x,y components of length of the us image

    const wPx = {
      //length of line /convert wRealWorld to px
      x: ((wRealWorld.x) * width) / optWidthMM,
      y: ((wRealWorld.y) * height) / optHeightMM,
    };
    const origX = x + wPx.x;
    const origY = y + wPx.y

    drawFilledCircle(
      origX * zoomProps.scale -
      Math.abs(zoomProps.positionX),
      origY * zoomProps.scale -
      Math.abs(zoomProps.positionY),
      ctx,
      US_ANNOTATION_COLOR
    );
    drawCircle(
      origX * zoomProps.scale -
      Math.abs(zoomProps.positionX),
      origY * zoomProps.scale -
      Math.abs(zoomProps.positionY),
      ctx,
      US_ANNOTATION_COLOR
    );
  })
}

export const drawZoomCenterLineSave = (
  ctx,
  zoomProps,
  usWidthMM,
  optWidthMM,
  lineWidth
) => {
  const width = ctx.canvas.width;
  if (lineWidth) {
    ctx.lineWidth = lineWidth;
  } else {
    ctx.lineWidth = (width / 300) * LINE_WIDTH;
  }
  ctx.strokeStyle = LINE_COLOR;
  ctx.beginPath();

  let y, w;
  optWidthMM = usWidthMM > optWidthMM ? usWidthMM : optWidthMM; //if us width is larger padding is added to opt (opt width be us  width)
  y =
    (((optWidthMM - usWidthMM) / optWidthMM) * width) / 2 + //opt img is larger and align 2 img vertically center,
    ((Math.abs(zoomProps.positionX) / zoomProps.scale) * usWidthMM) / //offset because of position
    optWidthMM;
  w = ((usWidthMM / zoomProps.scale) * width) / optWidthMM;
  ctx.moveTo(y, ctx.canvas.height / 2);
  ctx.lineTo(y + w, ctx.canvas.height / 2);
  ctx.stroke();
  return { x: y, y: ctx.canvas.height / 2 }
};
/**
 * Drae dimension numbers
 * @param {Context} ctx context
 * @param {Number} zoom zoom
 * @param {Number} imgRealWidth real width in mm
 * @param {Number} imgRealHeight real height in mm
 * @param {String} unit unit of image
 */
export const drawDimNumbers = (
  ctx,
  zoom,
  imgRealWidth,
  imgRealHeight,
  unit,
  lineWidth,
  color,
  adaptiveColors,
  font,
  lengths
) => {
  if (!imgRealHeight && !imgRealWidth) {
    return;
  }

  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  if (!imgRealHeight) {
    imgRealHeight = (imgRealWidth / width) * height;
  }

  if (!imgRealWidth) {
    imgRealWidth = (imgRealHeight / height) * width;
  }
  //Configuration
  if (!lineWidth) {
    ctx.lineWidth = (width / 400) * LINE_WIDTH;
  } else {
    ctx.lineWidth = lineWidth;
  }
  if (color) {
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
  } else {
    ctx.strokeStyle = LINE_COLOR_WHITE;
    ctx.fillStyle = LINE_COLOR_WHITE;
  }
  let length;
  if (font) {
    ctx.font = `${font}px Karla`;
  } else {
    ctx.font = ((width / 400) * FONT_SIZE).toString() + "px Karla";
  }

  //mm label
  ctx.textBaseline = "top";
  ctx.textAlign = "start";
  if (adaptiveColors) {
    ctx.fillStyle = adaptiveColors.topColors[0];
  }
  ctx.fillText(unit ? unit : "mm", 2, 2);



  //Number of lines per mm
  const linePerMm = Math.min(imgRealWidth / zoom.scale, imgRealHeight / zoom.scale) < 3 ? 10 : 1;

  //Horizontal lines
  ctx.textBaseline = "top";
  ctx.textAlign = "center";
  let x;
  let horizontalStartIndex = ((zoom.x - 1 / (2 * zoom.scale)) * imgRealWidth) * linePerMm;
  let horizontalCounter = 0;
  for (let i = Math.ceil(horizontalStartIndex + EPSILON_HORIZONTAL); i < horizontalStartIndex + (imgRealWidth / zoom.scale) * linePerMm; i++) {
    x = ((i - horizontalStartIndex) * width) / ((imgRealWidth / zoom.scale) * linePerMm);
    if (lengths) {
      length = Math.max(0, (i % 5 === 0 ? lengths.mm5 : lengths.mm1)); //5th is longer
    } else {
      length = Math.max(0, (i % 5 === 0 ? (width / 400) * MM5_LENGTH : (width / 400) * MM1_LENGTH) - zoom.scale) + 2; //5th is longer
    }

    if (adaptiveColors) {
      ctx.fillStyle = adaptiveColors.topColors[horizontalCounter];
    }
    if (i / linePerMm * 10 % 5 === 0) {
      ctx.fillText(i / linePerMm, x, length + 2);
    }
    horizontalCounter = horizontalCounter + 1;
  }
  //Vertical lines
  let y;
  ctx.textBaseline = "middle";
  ctx.textAlign = "start";
  let verticalStartIndex = ((zoom.y - 1 / (2 * zoom.scale)) * imgRealHeight) * linePerMm;
  let verticalCounter = 0;
  for (let i = Math.ceil(verticalStartIndex + EPSILON_VERTICAL); i < verticalStartIndex + (imgRealHeight / zoom.scale) * linePerMm; i++) {
    y = ((i - verticalStartIndex) * height) / ((imgRealHeight / zoom.scale) * linePerMm);
    if (lengths) {
      length = Math.max(0, (i % 5 === 0 ? lengths.mm5 : lengths.mm1)); //5th is longer
    } else {
      length = Math.max(0, (i % 5 === 0 ? (width / 400) * MM5_LENGTH : (width / 400) * MM1_LENGTH) - zoom.scale) + 2; //5th is longer
    }
    if (adaptiveColors) {
      ctx.fillStyle = adaptiveColors.leftColors[verticalCounter];
    }
    if (i / linePerMm * 10 % 5 === 0) {
      ctx.fillText(i / linePerMm, length + 2, y);
    }
    verticalCounter = verticalCounter + 1;
  }
};

/**
 * Draw dimension lines to image
 * @param {Context} ctx context onto draw
 * @param {Number} zoom zoom
 * @param {Number} imgRealWidth real width in mm
 * @param {Number} imgRealHeight real height in mm
 */
export const drawDimLines = (ctx, zoom, imgRealWidth, imgRealHeight, lineWidth, adaptiveColors, lengths) => {


  if (!imgRealHeight && !imgRealWidth) {
    return;
  }

  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  if (!imgRealHeight) {
    imgRealHeight = (imgRealWidth / width) * height;
  }

  if (!imgRealWidth) {
    imgRealWidth = (imgRealHeight / height) * width;
  }

  //Configuration
  if (lineWidth) {
    ctx.lineWidth = lineWidth;
  } else {
    ctx.lineWidth = Math.max(1, (width / 400) * LINE_WIDTH);
  }

  // Number of lines per mm
  const linePerMm = Math.min(imgRealWidth / zoom.scale, imgRealHeight / zoom.scale) < 3 ? 10 : 1;

  //Draw lines
  ctx.beginPath();
  let length;
  //Vertical lines
  let x;
  let horizontalStartIndex = ((zoom.x - 1 / (2 * zoom.scale)) * imgRealWidth) * linePerMm;
  let horizontalCounter = 0;
  for (let i = Math.ceil(horizontalStartIndex + EPSILON_HORIZONTAL); i < horizontalStartIndex + (imgRealWidth / zoom.scale) * linePerMm; i++) {
    x = ((i - horizontalStartIndex) * width) / ((imgRealWidth / zoom.scale) * linePerMm);
    if (lengths) {
      length = Math.max(0, (i % 5 === 0 ? lengths.mm5 : lengths.mm1)); //5th is longer
    } else {
      length = Math.max(0, (i % 5 === 0 ? (width / 400) * MM5_LENGTH : (width / 400) * MM1_LENGTH) - zoom.scale) + 2; //5th is longer
    }
    //Top
    if (adaptiveColors) {
      ctx.strokeStyle = adaptiveColors.topColors[horizontalCounter];    //top
    } else {
      ctx.strokeStyle = LINE_COLOR_WHITE;
    }
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, length);
    ctx.stroke();

    if (adaptiveColors) {
      ctx.strokeStyle = adaptiveColors.bottomColors[horizontalCounter];    //bottom
    } else {
      ctx.strokeStyle = LINE_COLOR_WHITE;
    }

    //Down
    ctx.beginPath();
    ctx.moveTo(x, height);
    ctx.lineTo(x, height - length);
    ctx.stroke();
    horizontalCounter = horizontalCounter + 1;
  }
  //Horizontal lines
  let y;
  let verticalStartIndex = ((zoom.y - 1 / (2 * zoom.scale)) * imgRealHeight) * linePerMm;
  let verticalCounter = 0;
  for (let i = Math.ceil(verticalStartIndex + EPSILON_VERTICAL); i < verticalStartIndex + (imgRealHeight / zoom.scale) * linePerMm; i++) {
    y = ((i - verticalStartIndex) * height) / ((imgRealHeight / zoom.scale) * linePerMm);
    if (lengths) {
      length = Math.max(0, (i % 5 === 0 ? lengths.mm5 : lengths.mm1)); //5th is longer
    } else {
      length = Math.max(0, (i % 5 === 0 ? (width / 400) * MM5_LENGTH : (width / 400) * MM1_LENGTH) - zoom.scale) + 2; //5th is longer
    }
    //Right
    if (adaptiveColors) {
      ctx.strokeStyle = adaptiveColors.rightColors[verticalCounter];
    } else {
      ctx.strokeStyle = LINE_COLOR_WHITE;
    }
    ctx.beginPath();
    ctx.moveTo(width, y);
    ctx.lineTo(width - length, y);
    ctx.stroke();

    //Left
    if (adaptiveColors) {
      ctx.strokeStyle = adaptiveColors.leftColors[verticalCounter];
    } else {
      ctx.strokeStyle = LINE_COLOR_WHITE;
    }
    ctx.beginPath();
    ctx.moveTo(0, y);
    ctx.lineTo(length, y);
    ctx.stroke();
    verticalCounter = verticalCounter + 1;
  }

};

/**
 * Draw image to context with padding
 * @param {Context} ctx contex to draw image
 * @param {Image} img image to draw
 * @param {Number} rotation rotation degree of image
 */
export const drawPaddingImage = (ctx, img, padding, rotation) => {
  if (ctx && img) {
    if (!rotation) {
      rotation = 0;
    }
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
    ctx.rotate((rotation * Math.PI) / 180);

    ctx.translate(-ctx.canvas.width / 2, -ctx.canvas.height / 2);
    ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      padding / 2,
      0,
      ctx.canvas.width - padding,
      ctx.canvas.height
    );
    ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
    ctx.rotate(-(rotation * Math.PI) / 180);
    ctx.translate(-ctx.canvas.width / 2, -ctx.canvas.height / 2);
  }
};


/**
 * Draw triangle on ultrasound image
 * @param {*} ctx contex to draw image
 * @param {*} sizeMM size in mm
 */
export const drawTriangleOnUSImage = (ctx, sizeMM, config) => {
  let color
  if (config?.color) {
    color = config?.color
  } else {
    color = LINE_COLOR
  }
  const verticalPosition =
    (3 / 2) * (ctx.canvas.height / sizeMM.height); // between the first and second mm dimension lines
  ctx.fillStyle = color
  ctx.beginPath();
  ctx.moveTo(
    0,
    verticalPosition - ctx.canvas.height * 0.02
  );
  ctx.lineTo(
    ctx.canvas.width * 0.04,
    verticalPosition
  );
  ctx.lineTo(
    0,
    verticalPosition + ctx.canvas.height * 0.02

  );
  ctx.fill();
}

/**
 * Draw triangle on optical image
 * @param {*} ctx contex to draw image
 * @param {*} sizeMM size in mm
 * @param {*} horizontalOffset horizontal offset
 */
export const drawTriangleOnOpticalImage = (ctx, sizeMM, horizontalOffset) => {
  // draw triangle

  const triangleSize = ctx.canvas.width / sizeMM.width / 2;
  ctx.fillStyle = LINE_COLOR
  ctx.beginPath();
  ctx.moveTo(
    ctx.canvas.width / 2 + horizontalOffset * ctx.canvas.width - triangleSize / 2,
    ctx.canvas.height
  );
  ctx.lineTo(
    ctx.canvas.width / 2 + horizontalOffset * ctx.canvas.width,
    ctx.canvas.height - triangleSize
  );
  ctx.lineTo(
    ctx.canvas.width / 2 + horizontalOffset * ctx.canvas.width + triangleSize / 2,
    ctx.canvas.height
  );
  ctx.fill();
}

/**
 * Draw triangle given posotion with given angle
 * @param {Context} ctx context to draw
 * @param {{width, height}} sizeMM size of the image in mm
 * @param {{x,y}} point point do draw
 * @param {Number} angle angel to draw
 */
export const drawTriangleGeneral = (ctx, point, angle, color = LINE_COLOR) => {
  // draw triangle
  const triangleSize = Math.min(ctx.canvas.width * 0.04, 25);

  ctx.fillStyle = color
  ctx.translate(point.x, point.y);
  ctx.rotate(-angle);

  ctx.beginPath();
  ctx.moveTo(0,
    -triangleSize / 2
  );
  ctx.lineTo(
    0,
    triangleSize / 2
  );
  ctx.lineTo(
    triangleSize,
    0
  );
  ctx.rotate(angle);
  ctx.translate(-point.x, -point.y);

  ctx.fill();
}



/**
 * Get adaptive colors for dimensions and numbers
 * @returns adaptive colors
 */
export const getDimLineColors = (ctx, pixelBuffer, pixelBufferWidth, pixelBufferHeight, zoom, imgRealWidth, imgRealHeight, lineWidth, lengths) => {
  if (!imgRealHeight && !imgRealWidth) {
    return;
  }

  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  if (!imgRealHeight) {
    imgRealHeight = (imgRealWidth / width) * height;
  }

  if (!imgRealWidth) {
    imgRealWidth = (imgRealHeight / height) * width;
  }

  //Number of lines per mm
  const linePerMm = Math.min(imgRealWidth / zoom.scale, imgRealHeight / zoom.scale) <
    WIDTH_TO_SHOW_DECIMALS ? NUM_OF_DECIMALS_PER_MM_SMALL_IMAGE : NUM_OF_DECIMALS_PER_MM_LARGE_IMAGE;

  //Threshold, different for us and optics
  const threshold = pixelBufferWidth === NUM_OF_A_LINE ? THRESHOLD_FOR_US : THRESHOLD_FOR_OPTICS;

  //Dash and number environment to examine
  const environmentWidth = Math.round(pixelBufferWidth / ((imgRealWidth / zoom.scale) * linePerMm));
  const environmentHeight = Math.round(pixelBufferHeight / ((imgRealHeight / zoom.scale) * linePerMm) / 2);

  //TOP & BOTTOM COLORS
  let topColors = []
  let bottomColors = []
  let horizontalStartIndex = ((zoom.x - 1 / (2 * zoom.scale)) * imgRealWidth) * linePerMm;
  for (let i = Math.ceil(horizontalStartIndex + EPSILON_HORIZONTAL); i <= horizontalStartIndex + (imgRealWidth / zoom.scale) * linePerMm; i++) {
    //Top base pixel
    let tmpIndexTop = 4 * Math.round((i - horizontalStartIndex) * Math.round(pixelBufferWidth / ((imgRealWidth / zoom.scale) * linePerMm)))
    let sumPixelValuesTop = 0
    let numPixelsTop = 0

    //Tom base index
    let tmpIndexBottom = (4 * pixelBufferWidth * (pixelBufferHeight - 1)) + tmpIndexTop
    let sumPixelValuesBottom = 0
    let numPixelsBottom = 0


    // height loop 0->environmentHeight
    for (let k = 0; k <= environmentHeight; k++) {
      //Relative row index
      let rowInd = k * 4 * pixelBufferWidth;

      // width loop -environmentWidth -> environmentWidth
      for (let j = -environmentWidth; j <= environmentWidth; j++) {
        //Relative column index
        let colInd = j * 4;

        // examine width is out or not - TOP dimensions
        if (tmpIndexTop + j * 4 >= 0 && tmpIndexTop + j * 4 < pixelBufferWidth * 4) {
          if (!isNaN(pixelBuffer[tmpIndexTop + rowInd + colInd]) &&
            !isNaN(pixelBuffer[tmpIndexTop + rowInd + colInd + 1]) &&
            !isNaN(pixelBuffer[tmpIndexTop + rowInd + colInd + 2])) {
            // add pixel
            sumPixelValuesTop += (pixelBuffer[tmpIndexTop + rowInd + colInd] * RED_WEIGHT
              + pixelBuffer[tmpIndexTop + rowInd + colInd + 1] * GREEN_WEIGHT
              + pixelBuffer[tmpIndexTop + rowInd + colInd + 2] * BLUE_WEIGHT);
            // count pixel
            numPixelsTop += 1
          }
        }

        // examine width is out or not - BOTTOM dimensions
        if (tmpIndexBottom + j * 4 >= (4 * pixelBufferWidth * (pixelBufferHeight - 1)) && tmpIndexBottom + j * 4 < (4 * pixelBufferWidth * (pixelBufferHeight - 1)) + pixelBufferWidth * 4) {

          if (!isNaN(pixelBuffer[tmpIndexBottom - rowInd + colInd]) &&
            !isNaN(pixelBuffer[tmpIndexBottom - rowInd + colInd + 1]) &&
            !isNaN(pixelBuffer[tmpIndexBottom - rowInd + colInd + 2])) {
            // add pixel
            sumPixelValuesBottom += (pixelBuffer[tmpIndexBottom - rowInd + colInd] * RED_WEIGHT
              + pixelBuffer[tmpIndexBottom - rowInd + colInd + 1] * GREEN_WEIGHT
              + pixelBuffer[tmpIndexBottom - rowInd + colInd + 2] * BLUE_WEIGHT);
            // count pixel
            numPixelsBottom += 1
          }

        }
      }
    }
    if (pixelBuffer instanceof Uint8Array) {
      sumPixelValuesTop = sumPixelValuesTop / 255
      sumPixelValuesBottom = sumPixelValuesBottom / 255
    }
    topColors.push(sumPixelValuesTop / numPixelsTop > threshold ? neutralblack : neutralwhite);
    bottomColors.push(sumPixelValuesBottom / numPixelsBottom > threshold ? neutralblack : neutralwhite);
  }



  // LEFT & RIGHT COLORS
  let leftColors = []
  let rightColors = []
  let verticalStartIndex = ((zoom.y - 1 / (2 * zoom.scale)) * imgRealHeight) * linePerMm;
  for (let i = Math.ceil(verticalStartIndex + EPSILON_VERTICAL); i <= verticalStartIndex + (imgRealHeight / zoom.scale) * linePerMm; i++) {
    //Left base index
    let tmpIndexLeft = (4 * pixelBufferWidth) *
      Math.round((i - verticalStartIndex) *
        Math.round(pixelBufferHeight / ((imgRealHeight / zoom.scale) * linePerMm)))
    let sumPixelValuesLeft = 0
    let numPixelsLeft = 0

    //Right base index
    let tmpIndexRight = (4 * pixelBufferWidth) * Math.round((i - verticalStartIndex) * Math.round(pixelBufferHeight / ((imgRealHeight / zoom.scale) * linePerMm)))
      + 4 * (pixelBufferWidth - 1)
    let sumPixelValuesRight = 0
    let numPixelsRight = 0

    // width loop 0->environmentHeight
    for (let j = 0; j <= environmentHeight; j++) {
      //Relative column index
      let colInd = j * 4;
      // height loop -environmentWidth -> environmentWidth
      for (let k = -environmentWidth; k <= environmentWidth; k++) {
        //Relative row index
        let rowInd = k * 4 * pixelBufferWidth;

        // examine width is out or not - LEFT dimensions
        if (tmpIndexLeft + (k * 4 * pixelBufferWidth) >= 0 &&
          tmpIndexLeft + (k * 4 * pixelBufferWidth) < pixelBufferHeight * pixelBufferWidth * 4) {
          if (!isNaN(pixelBuffer[tmpIndexLeft + rowInd + colInd]) &&
            !isNaN(pixelBuffer[tmpIndexLeft + rowInd + colInd + 1]) &&
            !isNaN(pixelBuffer[tmpIndexLeft + rowInd + colInd + 2])) {
            // add pixel
            sumPixelValuesLeft += (pixelBuffer[tmpIndexLeft + rowInd + colInd] * RED_WEIGHT
              + pixelBuffer[tmpIndexLeft + rowInd + colInd + 1] * GREEN_WEIGHT
              + pixelBuffer[tmpIndexLeft + rowInd + colInd + 2] * BLUE_WEIGHT);
            // count pixel
            numPixelsLeft += 1
          }
        }

        // examine width is out or not - RIGHT dimensions
        if (tmpIndexRight + (k * 4 * pixelBufferWidth) >= 0 &&
          tmpIndexRight + (k * 4 * pixelBufferWidth) < pixelBufferHeight * pixelBufferWidth * 4) {
          if (!isNaN(pixelBuffer[tmpIndexRight + rowInd - colInd]) &&
            !isNaN(pixelBuffer[tmpIndexRight + rowInd - colInd + 1]) &&
            !isNaN(pixelBuffer[tmpIndexRight + rowInd - colInd + 2])) {
            // add pixel
            sumPixelValuesRight += (pixelBuffer[tmpIndexRight + rowInd - colInd] * RED_WEIGHT
              + pixelBuffer[tmpIndexRight + rowInd - colInd + 1] * GREEN_WEIGHT
              + pixelBuffer[tmpIndexRight + rowInd - colInd + 2] * BLUE_WEIGHT);
            // count pixel
            numPixelsRight += 1
          }
        }

      }
    }

    if (pixelBuffer instanceof Uint8Array) {
      sumPixelValuesLeft = sumPixelValuesLeft / 255
      sumPixelValuesRight = sumPixelValuesRight / 255
    }
    leftColors.push(sumPixelValuesLeft / numPixelsLeft > threshold ? neutralblack : neutralwhite);
    rightColors.push(sumPixelValuesRight / numPixelsRight > threshold ? neutralblack : neutralwhite);
  }

  return { topColors, rightColors, bottomColors, leftColors }
};

/**
 * 
 * @param {Calculate zoomed and panned position} x 
 * @param {Number} scale zoom scale
 * @param {Number} offset zoom offset - certain dimension
 * @param {Number} width image size in pixel - certain dimension
 * @returns 
 */
export const calcZoomedPosition = (x, scale, offset, width) => {
  return scale * x - (scale * offset - 0.5) * width;
}


