import {
  ONLY_OPT,
  ANNOTATION_FLAG,
  ANNOTATION_US_FLAG,
  DIAGNOSIS_ORIGIN,
} from "../../../dermuscomponents/src/Constants/Message";
import { DERMUSAPP_SUPPORTED_DEVICES, DICOM_EXT, ICD_TOKEN_IN_LOCAL_STORAGE, RECORD_TYPE } from "../Constants/Constants";

import axios from "axios";
import dermusAxios from "../API/dermusAxios";
import icdAxios from "../API/icdAxios";
import { ICD_EP, ICD_TOKEN_EP } from "../API/endPoints";
import { v4 as uuidv4 } from 'uuid';
import Stream from "readable-stream";
import { PNG } from 'pngjs/browser';
import { restoreItem, storeItem } from "../API/localStorageAPI";
import CryptoJS from "crypto-js";
import { getISONow } from "./dateConversion";
import { loginSuccessActionCreator } from "../store/actionCreators/authenticationActionCreator";
import html2canvas from "html2canvas";
import { FILTERS_FILTER, MODALITY_FILTER, NUM_OF_VISIT_FILTER, SEX_FILTER } from "../UI/ListElements/useCustomFilter";

export const supportedByDermusApp = async () => {
  if (typeof navigator?.userAgentData?.getHighEntropyValues === "function") {
    const info = await navigator.userAgentData
      .getHighEntropyValues([
        "model",
        "platform"
      ])
    const model = info["model"];
    if (DERMUSAPP_SUPPORTED_DEVICES.filter(item => item.toLocaleLowerCase().replace(/\s+/g, '') === model.toLocaleLowerCase().replace(/\s+/g, '')).length > 0) {
      const agent = window.navigator.userAgent.toLowerCase()
      switch (true) {
        case agent.indexOf("edge") > -1: return false;//"MS Edge";
        case agent.indexOf("edg/") > -1: return true;//"Edge ( chromium based)";
        case agent.indexOf("opr") > -1 && !!window.opr: return false;//"Opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome: return true;//"Chrome";
        case agent.indexOf("trident") > -1: return false;//"MS IE";
        case agent.indexOf("firefox") > -1: return false;//"Mozilla Firefox";
        case agent.indexOf("safari") > -1: return false;//"Safari";
        default: return false;//"other";
      }
    } else {
      return false
    }
  } else {
    return false
  }


}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const getScreenOrientation = () => {
  if (window.innerHeight > window.innerWidth)
    return "portrait";
  else
    return "landscape";
}
//Check validity
export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.checked) {
    isValid = value && isValid;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.equal) {
    isValid = value !== rules.equal && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};
export const isEmail = (value) => {
  const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return pattern.test(value)
}

//Get patient id
export const getPatientId = (location) => {
  let locs = location.pathname.split("/");
  return location.pathname.includes("capture") ?
    (new URLSearchParams(location?.search)).get("patient") : locs?.length > 1 ? locs[1] : null
};
//Get lesion id
export const getLesionId = (location) => {
  let locs = location.pathname.split("/");
  return location.pathname.includes("capture") ?
    (new URLSearchParams(location?.search)).get("lesion") : locs?.length > 2 ? locs[2] : null
};

//Get record id
export const getRecordId = (location) => {
  let locs = location.pathname.split("/");
  return locs?.length > 3 ? locs[3] : null
};
export const base64url = (source) => {
  // Encode in classical base64
  let encodedSource = CryptoJS.enc.Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '');

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
}
export const generateToken = (payload) => {
  let secret
  try {
    secret = process.env.REACT_APP_SECRET
  } catch (e) {
    secret = ""
  }
  const header = {
    "alg": "HS256",
    "typ": "JWT"
  };
  if (!payload?.exp) {
    payload.exp = Math.floor(new Date() / 1000) + 60 * 60 * 24 * 7;
  }
  const encodedHeader = base64url(CryptoJS.enc.Utf8.parse(JSON.stringify(header)));
  const encodedData = base64url(CryptoJS.enc.Utf8.parse(JSON.stringify(payload)));
  const token = encodedHeader + "." + encodedData;
  return token + "." + base64url(CryptoJS.HmacSHA256(token, secret))
}

export const verifyToken = (token, prefix = false) => {
  let secret
  try {
    secret = process.env.REACT_APP_SECRET
  } catch (e) {
    secret = ""
  }
  if (!prefix) {
    token = "." + token
  }

  const tokenArr = token.split(".")
  const sig = base64url(CryptoJS.HmacSHA256(tokenArr[1] + "." + tokenArr[2], secret))
  if (sig !== tokenArr[3]) {
    return undefined
  } else {
    const payload = JSON.parse(CryptoJS.enc.Base64.parse(tokenArr[2]).toString(CryptoJS.enc.Utf8))
    const now = Math.floor(new Date() / 1000);
    if (now < payload.exp) {
      return payload
    } else {
      return undefined
    }
  }

}

export const dispatchDemoSession = (dispatch, demoToken, payload, restored = false) => {
  dispatch(
    loginSuccessActionCreator(
      {
        getIdToken: () => ({ getJwtToken: () => demoToken, jwtToken: demoToken, payload }),
        isValid: () => true,
        restored: restored,
        getAccessToken: () => ({ getJwtToken: () => demoToken, jwtToken: demoToken, payload })
      }
    )
  )
}
export const generateDemoToken = (user = uuidv4()) => {
  const payload = {
    sub: user,
    email: "demo@demo.demo",
    date: getISONow(),
  }
  return { token: "demo." + generateToken(payload), payload }
}

//Only optical image? based on note (backward) and us image
export const isOnlyOptical = (record) => {
  if (!record.usImId) {
    return true;
  }
  let onlyOpt = false;
  //Notes solution: to be backcompatible
  record.notes.forEach((element) => {
    if (element.note && element.note.startsWith(ONLY_OPT)) {
      onlyOpt = true;
    }
  });
  return onlyOpt;
};

//is Dicom extension
export const isDicomExt = (name) => {
  if (name) {
    return name.endsWith(DICOM_EXT);
  } else {
    return false;
  }
};

//Build serach url
export const buildSearchQuery = (params) => {
  const urlWithParams = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach((element) =>
      urlWithParams.append(element[0], element[1])
    );
  }
  return urlWithParams;
};
//Notes contains annotation or not
export const alreadyAnnotated = (notes) => {
  if (!notes) {
    return false;
  }
  let annotated = false;
  notes.forEach((element) => {
    if (
      element.note &&
      (element.note.startsWith(ANNOTATION_FLAG) ||
        element.note.startsWith(ANNOTATION_US_FLAG))
    ) {
      annotated = true;
    }
  });
  return annotated;
};

//Get not specific note (ONLY_OPTICAL, ANNOTATION) as string
export const getNotSpecificNotesAsString = (notes) => {
  let output = "[";
  let firstNote = true;
  notes.forEach((e, i) => {
    if (
      e.note &&
      !e.note.startsWith(ANNOTATION_FLAG) &&
      !e.note.startsWith(ONLY_OPT) &&
      !e.note.startsWith(ANNOTATION_US_FLAG)
    ) {
      output =
        output +
        (firstNote ? "" : ",") +
        '{"note":"' +
        e.note +
        '","date":"' +
        e.date +
        '"}';
      firstNote = false;
    }
  });
  output = output + "]";
  return output;
};

/**
 *
 * @param {Number} containerWidth width of the container
 * @param {Image} img image
 * @param {Number} usWidthMM width of the real us image (mm)
 * @param {Number} optWidthMM width of the real opt image (mm)
 * @param {Boolean} onlyOpt only optocal img
 */
export const calculateOptContainerSize = (
  containerWidth,
  img,
  usWidthMM,
  optWidthMM,
  onlyOpt
) => {
  const width = Math.min(containerWidth * 0.8, 640);
  const padding = onlyOpt
    ? 0
    : usWidthMM < optWidthMM
      ? 0
      : ((usWidthMM - optWidthMM) / usWidthMM) * width;
  const height = ((width - padding) / img.width) * img.height;
  return { width: ~~width, height: ~~height, padding: ~~padding };
};
/**
 * Calculate US container size
 * @param {Number} containerWidth width of the container
 * @param {Image} img image
 */
export const calculateUsContainerSize = (containerWidth, img) => {
  const width = Math.min(containerWidth * 0.8, 640);
  const height = (width / img.width) * img.height;
  return { width: ~~width, height: ~~height };
};

export const getHeader = (token) => ({
  headers: {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
    "API-Version": "v2",
    "Accept-Language": "en",
  },
});

const parseJwt = (token) => {
  try {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null
  }

}

/**
 * decode diagnosis
 * @param {String} diagnosisCodes diagnosis code
 * @param {String} dermusToken dermus token
 * @param {Function} onDecoded ondecoded functon, input : decoded string
 */
export const decodeDiagnosisCode = async (
  diagnosisCodes,
  dermusToken,
  onDecoded,
  onError
) => {

  let token = restoreItem(ICD_TOKEN_IN_LOCAL_STORAGE)
  let validToken = false
  if (token) {
    if (new Date(parseJwt(token).exp * 1000) > new Date()) {
      validToken = true
    }
  }

  if (!validToken) {
    try {
      const tokenReq = await dermusAxios.get(ICD_TOKEN_EP, {
        headers: {
          Authorization: dermusToken,
        },
      })

      storeItem(ICD_TOKEN_IN_LOCAL_STORAGE, tokenReq.data.access_token)
      token = tokenReq.data.access_token
    } catch (e) {
      if (onError) {
        onError();
        return
      }
    }
  }

  const codes = diagnosisCodes ? diagnosisCodes : "";
  let codesArray = codes.split("/");
  if (codesArray.length > 0) {
    codesArray = codesArray[0].split("&");
    axios //Get stemid from code
      .all(
        codesArray.map((item) =>
          icdAxios.get(ICD_EP + item, getHeader(token))
        )
      )
      .then(
        axios.spread((...responses) => {
          axios //Get details
            .all(
              responses.map((itemStem) =>
                icdAxios.get(
                  itemStem.data.stemId.replace("http:", "https:"),
                  getHeader(token)
                )
              )
            )
            .then(
              axios.spread((...responsesStems) => {
                let str = "";
                responsesStems.forEach(
                  (resItem, index) =>
                  (str =
                    str +
                    (index === 0
                      ? resItem.data.title["@value"]
                      : ", [" + resItem.data.title["@value"] + "]"))
                );
                onDecoded({ code: "" + codesArray, title: str });
              })
            )
            .catch(() => {
              if (onError) {
                onError();
              }
            });
        })
      )
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }

};
/**
 * Min max values of array
 * @param {Array} arr array
 * @returns [min, max]
 */
export const arrayMinMax = (arr) =>
  arr.reduce(
    ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
  );

//Get stack trace
export const stackTrace = () => {
  var err = new Error();
  return err.stack;
};

// Check if screen is touchable or not
export const isTouchEnabled = () => {
  if (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * ImageBitmap to canvas converter for saving capture records
 * @param {ImageBitmap} imageBitmap
 * @returns canvas
 */
export const imageToCanvas = (imageBitmap) => {
  // create a canvas
  const canvas = document.createElement("canvas");
  // resize it to the size of our ImageBitmap
  canvas.width = imageBitmap.width;
  canvas.height = imageBitmap.height;
  // try to get a bitmaprenderer context
  let ctx = canvas.getContext("bitmaprenderer");
  if (ctx) {
    // transfer the ImageBitmap to it
    ctx.transferFromImageBitmap(imageBitmap);
  } else {
    // in case someone supports createImageBitmap only
    // twice in memory...
    canvas.getContext("2d").drawImage(imageBitmap, 0, 0);
  }
  return canvas;
};
/**
 * ImageData to canvas converter for saving capture records
 * @param {ImageData} imageData
 * @returns canvas
 */
const imageDataToCanvas = (imageData) => {
  let w = imageData.width;
  let h = imageData.height;
  let canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  let ctx = canvas.getContext("2d");
  ctx.putImageData(imageData, 0, 0);        // synchronous
  return canvas
}


const getPNGBlobFromGPUComposer = (threeGpu, gray = false) => {
  const rt = threeGpu.composer.writeBuffer
  const width = rt.width;
  const height = rt.height;
  const pixelBuffer = new Float32Array((gray ? 1 : 4) * rt.width * rt.height);

  threeGpu.renderer.readRenderTargetPixels(rt, 0, 0, rt.width, rt.height, pixelBuffer)

  const range = 65530
  const buffer = Buffer.alloc(2 * width * height * (gray ? 1 : 3));
  let bitmap = new Uint16Array(buffer.buffer);

  let png
  if (!gray) {
    for (let i = 0; i < height * width; i++) {
      bitmap[i * 3] = Math.min(range, pixelBuffer[i * 4] * range);
      bitmap[i * 3 + 1] = Math.min(range, pixelBuffer[i * 4 + 1] * range);
      bitmap[i * 3 + 2] = Math.min(range, pixelBuffer[i * 4 + 2] * range);
    }
    png = new PNG({
      width,
      height,
      bitDepth: 16,
      colorType: 2,
      inputColorType: 2,
      inputHasAlpha: false,
    });
  } else {
    for (let i = 0; i < height * width; i++) {
      bitmap[i] = Math.min(range, pixelBuffer[i] * range);
    }
    png = new PNG({
      width,
      height,
      bitDepth: 16,
      colorType: 0,
      inputColorType: 0,
      inputHasAlpha: false,
    });
  }

  png.data = buffer;
  const result = [];
  const dest = new Stream.Writable({
    write: (chunk, encoding, cb) => {
      result.push(chunk)
      cb()
    }
  })
  return new Promise((resolve, reject) => {
    png
      .pack()
      .pipe(dest)
      .on("finish", () => {
        const blob = new Blob(result)
        resolve(blob)
      })
      .on('error', (e) => reject(e))
  });

}


/**
 * Get all blobs
 * @param {Array<{frame:ImageBitmap}>} optImageBitmaps
 * @param {Array<{frame:ImageData}>} usImageDatas
 * @param {Array<{opt:number, us:number}>} framesToSave
 * @param {boolean} isSaveAll
 * @param {boolean} cancelingStartedRef 
 */
export const getAllBlobs = async (optImageBitmaps, usImageDatas, framesToSave, rangeToSave, indexFramePairs, cancelingStartedRef, optGpu, usGpu,
  optUniformsUpdate,
  usUniformsUpdate) => {



  const cineloop = {};
  const framepairs = {};
  if (rangeToSave !== null) {
    // CINELOOP
    //Ultrasound image array (blobs)
    const usArray = [];
    const opticalArray = [];
    for (let i = rangeToSave.min; i <= rangeToSave.max; i++) {
      if (cancelingStartedRef.current) {
        return { cineloop, framepairs };
      }
      let ultrasoundCanvas;
      let itWasBitmap = false;
      let usBlob
      if (usImageDatas[i].frame instanceof ImageData) {
        ultrasoundCanvas = imageDataToCanvas(usImageDatas[i].frame);
        usBlob = await new Promise((resolve) => ultrasoundCanvas.toBlob(resolve));
      } else if (usImageDatas[i].frame instanceof ImageBitmap) {
        ultrasoundCanvas = imageToCanvas(usImageDatas[i].frame);
        itWasBitmap = true;
        usBlob = await new Promise((resolve) => ultrasoundCanvas.toBlob(resolve));
      } else {
        usGpu.material.map.image.data = usImageDatas[i].frame;
        usGpu.material.map.needsUpdate = true;
        usUniformsUpdate(usImageDatas[i].uniforms)

        usGpu.composer.render();
        usBlob = await getPNGBlobFromGPUComposer(usGpu, true);
      }

      if (itWasBitmap) {
        usImageDatas[i].frame = await createImageBitmap(ultrasoundCanvas);
      }
      usArray.push(usBlob);

    }

    //Optical image array (blobs)
    for (let i = indexFramePairs[rangeToSave.min]; i <= indexFramePairs[rangeToSave.max]; i++) {
      if (cancelingStartedRef.current) {
        return { cineloop, framepairs };
      }

      let opticalCanvas;
      let itWasBitmap = false;
      let opticalBlob
      if (optImageBitmaps[i].frame instanceof ImageData) {
        opticalCanvas = imageDataToCanvas(optImageBitmaps[i].frame);
        opticalBlob = await new Promise((resolve) => opticalCanvas.toBlob(resolve));
      } else if (optImageBitmaps[i].frame instanceof ImageBitmap) {
        opticalCanvas = imageToCanvas(optImageBitmaps[i].frame);
        itWasBitmap = true
        opticalBlob = await new Promise((resolve) => opticalCanvas.toBlob(resolve));
      } else {
        optGpu.material.map.image.data = optImageBitmaps[i].frame;
        optGpu.material.map.needsUpdate = true;
        optUniformsUpdate(optImageBitmaps[i].uniforms)
        optGpu.composer.render();

        const bitmap = await createImageBitmap(
          optGpu.renderer.domElement
        );
        opticalCanvas = imageToCanvas(bitmap);
        opticalBlob = await new Promise((resolve) =>
          opticalCanvas.toBlob(resolve,
            "image/jpeg",
            0.95)
        );
      }
      if (itWasBitmap) {
        optImageBitmaps[i].frame = await createImageBitmap(opticalBlob);
      }
      opticalArray.push(opticalBlob);
    }
    cineloop.usArray = usArray
    cineloop.opticalArray = opticalArray
  }

  // FRAMEPAIRS
  const usArrayFrames = [];
  const opticalArrayFrames = [];
  for (let index = 0; index < framesToSave.length; index++) {
    if (cancelingStartedRef.current) {
      return { cineloop, framepairs };
    }
    const optInd = framesToSave[index].opt
    const usInd = framesToSave[index].us
    let optExist = null;
    for (let subindex = 0; subindex < index; subindex++) {
      if (optInd === framesToSave[subindex].opt) {
        optExist = subindex
        break;
      }
    }

    if (optExist === null) {
      //Get image canvas from image bitmap
      let opticalCanvas;
      let itWasBitmap = false;
      let optBlob
      if (optImageBitmaps[optInd].frame instanceof ImageData) {
        opticalCanvas = imageDataToCanvas(optImageBitmaps[optInd].frame);
        optBlob = await new Promise((resolve) => opticalCanvas.toBlob(resolve));
      } else if (optImageBitmaps[optInd].frame instanceof ImageBitmap) {
        opticalCanvas = imageToCanvas(optImageBitmaps[optInd].frame);
        itWasBitmap = true;
        optBlob = await new Promise((resolve) => opticalCanvas.toBlob(resolve));
      } else {
        optGpu.material.map.image.data = optImageBitmaps[optInd].frame;
        optGpu.material.map.needsUpdate = true;
        optUniformsUpdate(optImageBitmaps[optInd].uniforms)
        optGpu.composer.render();



        const bitmap = await createImageBitmap(
          optGpu.renderer.domElement
        );
        opticalCanvas = imageToCanvas(bitmap);
        optBlob = await new Promise((resolve) =>
          opticalCanvas.toBlob(resolve,
            "image/jpeg",
            0.95)
        );

      }
      //Get it back as a Blob

      if (itWasBitmap) {
        optImageBitmaps[optInd].frame = await createImageBitmap(optBlob);
      }
      opticalArrayFrames.push(optBlob);
    } else {
      opticalArrayFrames.push(opticalArrayFrames[optExist]);
    }

    let ultrasoundCanvas
    let itWasBitmap = false;
    let usBlob
    if (usImageDatas[usInd].frame instanceof ImageData) {
      ultrasoundCanvas = imageDataToCanvas(usImageDatas[usInd].frame);
      usBlob = await new Promise((resolve) => ultrasoundCanvas.toBlob(resolve));
    } else if (usImageDatas[usInd].frame instanceof ImageBitmap) {
      ultrasoundCanvas = imageToCanvas(usImageDatas[usInd].frame);
      itWasBitmap = true
      usBlob = await new Promise((resolve) => ultrasoundCanvas.toBlob(resolve));
    } else {
      usGpu.material.map.image.data = usImageDatas[usInd].frame;
      usGpu.material.map.needsUpdate = true;
      usUniformsUpdate(usImageDatas[usInd].uniforms)
      usGpu.composer.render();
      usBlob = await getPNGBlobFromGPUComposer(usGpu, true)
    }
    if (itWasBitmap) {
      usImageDatas[usInd].frame = await createImageBitmap(usBlob);
    }

    //Set arrays
    usArrayFrames.push(usBlob);
  }
  framepairs.usArray = usArrayFrames
  framepairs.opticalArray = opticalArrayFrames

  return { cineloop, framepairs };
};

/**
 * Get all blobs in doppler mode
 * @param {Array<{frame:ImageBitmap}>} optImageBitmaps
 * @param {Array<{frame:ImageData}>} usImageDatas
 * @param {Array<{opt:number, us:number}>} framesToSave
 * @param {boolean} isSaveAll
 * @param {boolean} cancelingStartedRef 
 */
export const getAllBlobsDoppler = async (optImageBitmaps, dopplerImageDatas, usImageDatas, framesToSave, rangeToSave, indexFramePairs, usOverlayPairs, cancelingStartedRef, optGpu, overlayGpu, usGpu,
  optUniformsUpdate,
  usUniformsUpdate,
  usOverlayUniformsUpdate,
  usOverlayGpuSize
) => {


  const cineloop = {};
  const framepairs = {};
  if (rangeToSave !== null) {
    // CINELOOP
    //Ultrasound image array (blobs) set of LINE DOPPLER handle as an image, where every line is van DOPPLER Line
    const opticalArray = [];
    const usArray = [];

    const width = rangeToSave.max - rangeToSave.min + 1
    const rt = overlayGpu.composer.writeBuffer
    const height = rt.height;
    let ind = 0
    const buffer = Buffer.alloc(2 * width * height)
    const dopplerImage = new Uint16Array(buffer.buffer);
    for (let i = rangeToSave.min; i <= rangeToSave.max; i++) {


      //Overlay
      overlayGpu.material.map.image.data = dopplerImageDatas[i].frame;
      overlayGpu.material.map.needsUpdate = true;
      usOverlayUniformsUpdate({})
      overlayGpu.composer.render();

      const pixelBuffer = new Float32Array(4 * rt.height);

      overlayGpu.renderer.readRenderTargetPixels(rt, 0, 0, 1, rt.height, pixelBuffer)

      const range = 65530
      for (let j = 0; j < height; j++) {
        dopplerImage[j * width + ind] = Math.min(range, pixelBuffer[4 * j] * range);
      }
      ind++;
    }
    let png = new PNG({
      width,
      height,
      bitDepth: 16,
      colorType: 0,
      inputColorType: 0,
      inputHasAlpha: false,
    });
    png.data = buffer;
    const result = [];
    const dest = new Stream.Writable({
      write: (chunk, encoding, cb) => {
        result.push(chunk)
        cb()
      }
    })

    const dopplerBlob = await new Promise((resolve, reject) => {
      png
        .pack()
        .pipe(dest)
        .on("finish", () => {
          const blob = new Blob(result)
          resolve(blob)
        })
        .on('error', (e) => reject(e))
    });




    //US
    for (let i = usOverlayPairs[rangeToSave.min]; i <= usOverlayPairs[rangeToSave.max]; i++) {
      if (cancelingStartedRef.current) {
        return { cineloop, framepairs };
      }

      let ultrasoundCanvas;
      let itWasBitmap = false;
      let usBlob
      if (usImageDatas[i].frame instanceof ImageData) {
        ultrasoundCanvas = imageDataToCanvas(usImageDatas[i].frame);
        usBlob = await new Promise((resolve) => ultrasoundCanvas.toBlob(resolve));
      } else if (usImageDatas[i].frame instanceof ImageBitmap) {
        ultrasoundCanvas = imageToCanvas(usImageDatas[i].frame);
        itWasBitmap = true;
        usBlob = await new Promise((resolve) => ultrasoundCanvas.toBlob(resolve));
      } else {
        usGpu.material.map.image.data = usImageDatas[i].frame;
        usGpu.material.map.needsUpdate = true;
        usUniformsUpdate(usImageDatas[i].uniforms)

        usGpu.composer.render();
        usBlob = await getPNGBlobFromGPUComposer(usGpu, true);
      }

      if (itWasBitmap) {
        usImageDatas[i].frame = await createImageBitmap(ultrasoundCanvas);
      }
      usArray.push(usBlob);
    }






    //Optical image array (blobs)
    for (let i = indexFramePairs[rangeToSave.min]; i <= indexFramePairs[rangeToSave.max]; i++) {
      if (cancelingStartedRef.current) {
        return { cineloop, framepairs };
      }

      let opticalCanvas;
      let itWasBitmap = false;
      let opticalBlob
      if (optImageBitmaps[i].frame instanceof ImageData) {
        opticalCanvas = imageDataToCanvas(optImageBitmaps[i].frame);
        opticalBlob = await new Promise((resolve) => opticalCanvas.toBlob(resolve));
      } else if (optImageBitmaps[i].frame instanceof ImageBitmap) {
        opticalCanvas = imageToCanvas(optImageBitmaps[i].frame);
        itWasBitmap = true
        opticalBlob = await new Promise((resolve) => opticalCanvas.toBlob(resolve));
      } else {
        optGpu.material.map.image.data = optImageBitmaps[i].frame;
        optGpu.material.map.needsUpdate = true;
        optUniformsUpdate(optImageBitmaps[i].uniforms)
        optGpu.composer.render();

        const bitmap = await createImageBitmap(
          optGpu.renderer.domElement
        );
        opticalCanvas = imageToCanvas(bitmap);
        opticalBlob = await new Promise((resolve) =>
          opticalCanvas.toBlob(resolve,
            "image/jpeg",
            0.95)
        );
      }
      if (itWasBitmap) {
        optImageBitmaps[i].frame = await createImageBitmap(opticalBlob);
      }
      opticalArray.push(opticalBlob);
    }
    cineloop.usArray = usArray
    cineloop.dopplerArray = [dopplerBlob]
    cineloop.opticalArray = opticalArray
  }

  // FRAMEPAIRS
  const dopplerArrayFrames = [];
  const opticalArrayFrames = [];
  const usArrayFrames = [];
  for (let index = 0; index < framesToSave.length; index++) {
    if (cancelingStartedRef.current) {
      return { cineloop, framepairs };
    }
    const optInd = framesToSave[index].opt
    const overlayInd = framesToSave[index].us
    const usInd = usOverlayPairs[framesToSave[index].us]
    let optExist = null;
    let usExist = null;
    for (let subindex = 0; subindex < index; subindex++) {
      if (optInd === framesToSave[subindex].opt) {
        optExist = subindex
        break;
      }
    }
    for (let subindex = 0; subindex < index; subindex++) {
      if (usInd === usOverlayPairs[framesToSave[subindex].us]) {
        usExist = subindex
        break;
      }
    }

    if (optExist === null) {
      //Get image canvas from image bitmap
      let opticalCanvas;
      let itWasBitmap = false;
      let optBlob
      if (optImageBitmaps[optInd].frame instanceof ImageData) {
        opticalCanvas = imageDataToCanvas(optImageBitmaps[optInd].frame);
        optBlob = await new Promise((resolve) => opticalCanvas.toBlob(resolve));
      } else if (optImageBitmaps[optInd].frame instanceof ImageBitmap) {
        opticalCanvas = imageToCanvas(optImageBitmaps[optInd].frame);
        itWasBitmap = true;
        optBlob = await new Promise((resolve) => opticalCanvas.toBlob(resolve));
      } else {
        optGpu.material.map.image.data = optImageBitmaps[optInd].frame;
        optGpu.material.map.needsUpdate = true;
        optUniformsUpdate(optImageBitmaps[optInd].uniforms)
        optGpu.composer.render();



        const bitmap = await createImageBitmap(
          optGpu.renderer.domElement
        );
        opticalCanvas = imageToCanvas(bitmap);
        optBlob = await new Promise((resolve) =>
          opticalCanvas.toBlob(resolve,
            "image/jpeg",
            0.95)
        );

      }
      //Get it back as a Blob

      if (itWasBitmap) {
        optImageBitmaps[optInd].frame = await createImageBitmap(optBlob);
      }
      opticalArrayFrames.push(optBlob);
    } else {
      opticalArrayFrames.push(opticalArrayFrames[optExist]);
    }

    if (usExist === null) {

      let ultrasoundCanvas;
      let itWasBitmap = false;
      let usBlob
      if (usImageDatas[usInd].frame instanceof ImageData) {
        ultrasoundCanvas = imageDataToCanvas(usImageDatas[usInd].frame);
        usBlob = await new Promise((resolve) => ultrasoundCanvas.toBlob(resolve));
      } else if (usImageDatas[usInd].frame instanceof ImageBitmap) {
        ultrasoundCanvas = imageToCanvas(usImageDatas[usInd].frame);
        itWasBitmap = true;
        usBlob = await new Promise((resolve) => ultrasoundCanvas.toBlob(resolve));
      } else {
        usGpu.material.map.image.data = usImageDatas[usInd].frame;
        usGpu.material.map.needsUpdate = true;
        usUniformsUpdate(usImageDatas[usInd].uniforms)

        usGpu.composer.render();
        usBlob = await getPNGBlobFromGPUComposer(usGpu, true);
      }

      if (itWasBitmap) {
        usImageDatas[usInd].frame = await createImageBitmap(ultrasoundCanvas);
      }
      usArrayFrames.push(usBlob);
    } else {
      usArrayFrames.push(usArrayFrames[usExist]);
    }

    const width = 1
    const rt = overlayGpu.composer.writeBuffer
    let height = rt.height;
    const buffer = Buffer.alloc(2 * width * height)
    const dopplerImage = new Uint16Array(buffer.buffer);

    overlayGpu.material.map.image.data = dopplerImageDatas[overlayInd].frame;
    overlayGpu.material.map.needsUpdate = true;
    usOverlayUniformsUpdate()
    overlayGpu.composer.render();



    const pixelBuffer = new Float32Array(4 * rt.height);


    overlayGpu.renderer.readRenderTargetPixels(rt, 0, 0, 1, rt.height, pixelBuffer)

    const range = 65530
    for (let j = 0; j < height; j++) {
      dopplerImage[j] = Math.min(range, pixelBuffer[j * 4] * range);
    }


    let png = new PNG({
      width,
      height,
      bitDepth: 16,
      colorType: 0,
      inputColorType: 0,
      inputHasAlpha: false,
    });
    png.data = buffer;
    const result = [];
    const dest = new Stream.Writable({
      write: (chunk, encoding, cb) => {
        result.push(chunk)
        cb()
      }
    })

    const dopplerBlob = await new Promise((resolve, reject) => {
      png
        .pack()
        .pipe(dest)
        .on("finish", () => {
          const blob = new Blob(result)
          resolve(blob)
        })
        .on('error', (e) => reject(e))
    });


    //Set arrays
    dopplerArrayFrames.push(dopplerBlob);
  }
  framepairs.usArray = usArrayFrames
  framepairs.dopplerArray = dopplerArrayFrames
  framepairs.opticalArray = opticalArrayFrames

  return { cineloop, framepairs };
};

/**
 * Create measurement mode
 * @param {String} flag measuerment mode/flag/pretag
 * @param {Number} x1 x coord of first point
 * @param {Number} y1 y coord of first point
 * @param {Number} x2 x coord of 2nd point
 * @param {Number} y2 y coord of 2nd point
 * @param {Number} dx x distance
 * @param {Number} dy y distance
 * @param {Number} d distance
 * @returns note string
 */
export const createMeasurementNote = (flag, x1, y1, x2, y2, dx, dy, d) => {
  return `${flag}{x1:${x1.toFixed(5)},y1:${y1.toFixed(5)},x2:${x2.toFixed(5)},y2:${y2.toFixed(5)},dx:${dx.toFixed(5)},dy:${dy.toFixed(5)},d:${d.toFixed(5)}}`
}

/**
 * Get transformation matrix
 * @param {Number} alpha degree of the rotation in radian (x,y plane)
 * @param {Number} offsetX shift of the "origo" in px in x dir
 * @param {Number} offsetY shift of the "origo" in px in y dir
 * @param {Number} cropUp crop from the us image in px (z dir)
 * @param {Number} cropLeft crop from the left of us image in px
 * @returns 
 */
export const getTransformationMatrix = (alpha = 0, offsetX = 0, offsetY = 0, cropUp = 0, cropLeft = 0) => [
  Math.cos(alpha), 0.0, Math.sin(alpha), Math.cos(alpha) * (-cropLeft) + offsetX,//h
  Math.sin(alpha), 0.0, -Math.cos(alpha), Math.sin(alpha) * (-cropLeft) + offsetY,  //v
  0.0, 1.0, 0.0, cropUp,
  0.0, 0.0, 0.0, 1.0]

//Get real coord points
export const getRealCoordPoints = (T, imgSize, realSize) => {
  const imgCoordPoints = [
    //top-left and top-roght corners
    { x: 0, y: 0, z: 0 },
    { x: 1, y: 0, z: 0 },
  ];

  const realCoordPoints = imgCoordPoints.map((item) => ({
    //transform and convert to real world
    x:
      ((T[0] * item.x + T[1] * item.y + T[2] * item.z + T[3]) /
        imgSize.width) * //to be percent
      realSize.width, // to be real mm
    y:
      -(
        (T[4] * item.x + T[5] * item.y + T[6] * item.z + T[7]) /
        imgSize.height
      ) * realSize.height, // *(-1) because the transformation matrix is composed as left top corner is the origo and x increases to right, and y decreases to down
  }));
  return realCoordPoints;
};

export const iOS = () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
export const isAndroid = () => navigator.platform.toLowerCase().indexOf("android") > -1
export const isFirefox = () => navigator.userAgent.toLocaleLowerCase().indexOf("firefox") > -1
export const isEdge = () => navigator.userAgent.toLocaleLowerCase().indexOf("edg/") > -1
export const isOpera = () => navigator.userAgent.toLocaleLowerCase().indexOf("opr") > -1


export const oneOfThreeIsTrue = (a, b, c) => {
  return a ? b ? false : c ? false : true : b ? c ? false : true : c ? true : false
}

export const nextLetter = (s) => {
  return s.replace(/([a-zA-Z])[^a-zA-Z]*$/, function (a) {
    var c = a.charCodeAt(0);
    switch (c) {
      case 90: return 'A';
      case 122: return 'a';
      default: return String.fromCharCode(++c);
    }
  });
}



export const isTouchDevice = () => {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}


const arrayCompare = (_arr1, _arr2) => {
  if (
    !Array.isArray(_arr1)
    || !Array.isArray(_arr2)
    || _arr1.length !== _arr2.length
  ) {
    return false;
  }

  // .concat() to not mutate arguments
  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}
export const compareFilterVisibility = (a, b) => {
  if (a === undefined || a === null || b === undefined || b === null) {
    return false
  }
  const aKeys = Object.keys(a)
  let bKeys = Object.keys(b)
  let result = true
  aKeys.forEach(item => {
    if (!bKeys.includes(item) && a[item]?.length > 0) {
      result = false
      return false
    } else {
      bKeys = bKeys.filter(k => k !== item)
      if (!arrayCompare(a[item], b[item])) {
        result = false
        return false
      }
    }
  })
  if (!result) {
    return false
  }
  bKeys.forEach(item => {
    if (!aKeys.includes(item) && b[item]?.length > 0) {
      result = false
      return false
    }
  })
  if (!result) {
    return false
  }
  return true


}
const comparePointSets = (ps1, ps2) => {
  if (ps1 === undefined || ps1 === null || ps2 === undefined || ps2 === null) {
    return false
  }
  if (ps1.length !== ps2.length) {
    return false
  }
  let equal = true
  ps1.forEach((item, index) => {

    if (!Array.isArray(item)) {
      item = [item]
    }
    item.forEach((item2, index2) => {
      let otherItem = ps2[index]
      if (!Array.isArray(otherItem)) {
        otherItem = [otherItem]
      }
      if (item2.x !== otherItem[index2].x) {
        equal = false
        return false
      }

      if (item2.y !== otherItem[index2].y) {
        equal = false
        return false
      }
    })

  })
  return equal
}

/**
 * compare action tools, such as compare annotation, measurement or volume estimation
 * @param {prevActionTool} a 
 * @param {newActionTool} b 
 * @returns 
 */
export const compareActionTools = (a, b) => {
  if (a === undefined || a === null || b === undefined || b === null) {
    return false
  }
  const aKeys = Object.keys(a)
  let bKeys = Object.keys(b)
  let result = true
  aKeys.forEach(item => {
    if (!bKeys.includes(item) && a[item]?.points?.length > 0) {
      result = false
      return false
    } else {
      bKeys = bKeys.filter(k => k !== item)
      if (!comparePointSets(a[item]?.points, b[item]?.points)) {
        result = false
        return false
      }
    }
  })
  if (!result) {
    return false
  }
  bKeys.forEach(item => {
    if (!aKeys.includes(item) && b[item]?.points?.length > 0) {
      result = false
      return false
    }
  })
  if (!result) {
    return false
  }
  return true


}

export const compareFreeText = (a, b) => {
  const aKeys = Object.keys(a)
  let bKeys = Object.keys(b)
  let result = true
  aKeys.forEach(item => {
    if (!bKeys.includes(item) && a[item]?.points?.length > 0) {
      result = false
      return false
    } else {
      bKeys = bKeys.filter(k => k !== item)
      if (a[item]?.text !== b[item]?.text) {
        result = false
        return false
      }
    }
  })
  if (!result) {
    return false
  }
  bKeys.forEach(item => {
    if (!aKeys.includes(item) && b[item]?.points?.length > 0) {
      result = false
      return false
    }
  })
  if (!result) {
    return false
  }
  return true


}


export const bitmapToBlob = async (bitmap) => {
  const bmp = await createImageBitmap(bitmap)
  const canvas = document.createElement('canvas');
  // resize it to the size of our ImageBitmap
  canvas.width = bmp.width;
  canvas.height = bmp.height;
  // get a bitmaprenderer context
  const ctx = canvas.getContext('bitmaprenderer');
  ctx.transferFromImageBitmap(bmp);
  // get it back as a Blob
  return await new Promise((res) => canvas.toBlob(res));
}

export const swap16 = (val) => {
  return ((val & 0xFF) << 8)
    | ((val >> 8) & 0xFF);
}


//Calculate intensity values, based on RGB channels
export const getIntensityImage = (arr) => {
  let newArr = []
  for (let i = 0; i < arr.length - 1; i = i + 4) {
    newArr.push((0.298936 * arr[i] + 0.587043 * arr[i + 1] + 0.114021 * arr[i + 2]))
    //newArr.push((arr[i] + arr[i + 1] + arr[i + 2]) / 3)
  }
  return newArr
}

//Get a given RGB channel array
export const getEveryNth = (arr, nth) => {
  return arr.filter((_element, index) => {
    return index % 4 === nth;
  });
}

//Get mean of array
export const getMean = (arr) => {
  let sum = 0;
  arr.forEach(function (num) { sum += num });
  let average = sum / arr.length;
  return average
}

//Get median of array
export const getMedian = (arr) => {
  arr.sort((a, b) => a - b);
  const middleIndex = Math.floor(arr.length / 2);

  if (arr.length % 2 === 0) {
    return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
  } else {
    return arr[middleIndex];
  }
}

export const getScreenShot = async (screenshotTarget = null) => {

  if (!screenshotTarget) {
    screenshotTarget = document.body;
  }
  const canvas = await html2canvas(screenshotTarget, {
    logging: true, useCORS: true, allowTaint: true,
  })
  return { img: canvas.toDataURL("image/jpeg"), width: canvas.width, height: canvas.height };

}
//Get closest optical frame
export const getClosestIndex = (timeStampArray, currentTime) => {
  let minValue = Math.abs(timeStampArray[0] - currentTime);
  let minIndex = 0;
  timeStampArray.forEach((value, index) => {
    if (Math.abs(value - currentTime) < minValue) {
      minValue = Math.abs(value - currentTime);
      minIndex = index;
    }
  });
  return minIndex;
};

//Get number of frames to save
export const getNumberOfFramesToSave = (framesToSave) => {
  let numOfFramesToSave = 0;
  framesToSave?.forEach((item) => {
    if (item.toSave) {
      numOfFramesToSave = numOfFramesToSave + 1;
    }
  })
  return numOfFramesToSave
};



/**
 * Developer param values
 * @param {*} search 
 * @param {*} paramName 
 * @returns true if any of the cases fits
 */
export const developerParamValue = (search, paramName) => {
  const pv = new URLSearchParams(search).get(paramName)
  if (pv === true) {
    return true
  }
  if (pv === "true") {
    return true
  }
  if (pv === "yes") {
    return true
  }
  if (pv === 1) {
    return true
  }
  if (pv === "1") {
    return true
  }
  if (pv === "y") {
    return true
  }
  return false
}

export const isDoppler = (type) => ((type === RECORD_TYPE.LINE_DOPPLER) || (type === RECORD_TYPE.LINE_DOPPLER_CINELOOP))

// Get certain filter states from filterinfo
export const getNumOfVisits = (filterInfo) => {
  const obj = {}
  obj[NUM_OF_VISIT_FILTER.MORE] = filterInfo?.num_visits_more ?? false;
  obj[NUM_OF_VISIT_FILTER.ONE] = filterInfo?.num_visits_one ?? false;
  return obj
}

export const getSex = (filterInfo) => {
  const obj = {}
  obj[SEX_FILTER.FEMALE] = filterInfo?.female ?? false;
  obj[SEX_FILTER.MALE] = filterInfo?.male ?? false;
  obj[SEX_FILTER.OTHER] = filterInfo?.other ?? false;
  return obj
}

export const getDiagnosisType = (filterInfo) => {
  const obj = {}
  obj[DIAGNOSIS_ORIGIN.preliminaryClinicalDiagnosis] = filterInfo?.preliminary_clinical ?? false;
  obj[DIAGNOSIS_ORIGIN.finalClinicalDiagnosis] = filterInfo?.final_clinical ?? false;
  obj[DIAGNOSIS_ORIGIN.histologicalDiagnosis] = filterInfo?.histological ?? false;
  return obj
}

export const getExtraModality = (filterInfo) => {
  const obj = {}
  obj[MODALITY_FILTER.NONE] = filterInfo?.none ?? false;
  obj[MODALITY_FILTER.CLINICAL] = filterInfo?.clinical ?? false;
  obj[MODALITY_FILTER.DERMOSCOPY] = filterInfo?.dermoscopy ?? false;
  return obj
}

export const getRecordType = (filterInfo) => {
  const obj = {}
  obj[RECORD_TYPE.US_OPT_CINELOOP] = filterInfo?.us_opt_cineloop ?? false;
  obj[RECORD_TYPE.US_OPT_FRAMEPAIR] = filterInfo?.us_opt_framepair ?? false;
  obj[RECORD_TYPE.LINE_DOPPLER_CINELOOP] = filterInfo?.line_doppler_cineloop ?? false;
  obj[RECORD_TYPE.LINE_DOPPLER] = filterInfo?.line_doppler ?? false;
  return obj
}

export const getUsedFilters = (filterInfo) => {
  const obj = {}
  obj[FILTERS_FILTER.NOTE] = filterInfo?.note ?? false;
  obj[FILTERS_FILTER.THICKNESS] = filterInfo?.thickness ?? false;
  obj[FILTERS_FILTER.ANNOTATION] = filterInfo?.annotation ?? false;
  return obj
}
