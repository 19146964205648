import { isSkinAid, isTestDemo } from "../utility/functions";

export const VERSION = "R2409P2"
// Swipe mininmum distance
export const SWIPE_MIN_DISTANCE = 60;

// Layout parameters
// DRAWER SIZE (WIDTH or HEIGHT)
export const DRAWER_SIZE = 56;
export const EXTENDED_DRAWER_SIZE = 80;
//BATCH OPERATIONS HEIGHT
export const BATCH_OPERATIONS_HEIGHT = 64;
// TOOLBAR HEIGHT (path)
export const TOOLBAR_IMAGE = 20;
export const TOOLBAR_IMAGE_PADDING = 5;
export const TOOLBAR_PADDING = 10;
export const TOOLBAR_HEIGHT = 2 * TOOLBAR_IMAGE + (window.location.pathname.includes("capture") ? 0 : (2 * TOOLBAR_IMAGE_PADDING + TOOLBAR_PADDING));

// RIGHT BUTTON BAR WIDTH
export const RIGHT_BUTTONS_BAR_WIDTH = 60;
// TUNE MODULE WIDTH
export const TUNE_MODULE_WIDTH = 48;

export const MARGIN_SIZE = 20;
export const SCROLLBAR_WIDTH = 20;
export const BOTTOM_SLIDER_BAR_HEIGHT = 60;
export const FONTSIZE_SMALL = { xs: ".4rem", sm: ".5rem", md: ".6rem" };
export const FONTSIZE_MEDIUM = { xs: ".5rem", sm: ".7rem", md: ".9rem" };
export const FONTSIZE_LARGE = { xs: ".6rem", sm: ".8rem", md: "1rem" };
export const FONTSIZE_EXTRA_LARGE = { xs: "1rem", sm: "1.2rem", md: "1.4rem" };

export const POST_PROCESS_BETA_PARAMS = [-0.8, -0.6, -0.4, -0.2, 0, 0.2, 0.4, 0.6, 0.8];
// Dicom "scroll" min distance
export const DCM_SCROLL_MIN_DISTANCE = 5;

export const SHOW_OPTICAL_IMAGE_RESOLUTION_BUTTON = false;
// US-OPT images registration offset values
export const SHOW_POSITION_CALIBRATION_SLIDERS = false;
export const HORIZONTAL_OFFSET = 0.22;
export const VERTICAL_OFFSET = -0.13;

//Cross line color to draw
export const OPT_IMAGE_WIDTH_MM = 15.0;
export const OPT_IMAGE_HEIGHT_MM = 15.0;

export const US_IMAGE_WIDTH_MM = 12;
export const US_IMAGE_HEIGHT_MM = 11.55;
export const MAX_NUMBER_OF_ANNOTATION = 7;

export const US_ANNOTATION_COLOR = "#8af1ff";
export const OPT_ANNOTATION_COLOR = "#16a112";
export const US_THICKNESS_MEASUREMENT_COLOR = "#ff00ff";
export const THICKNESS_MEASUREMENT_COLOR = "#ff00ff";
export const FREEHAND_DRAWING_COLOR = "#ff00ff";

export const DICOM_EXT = "dcm";

export const APP_NAME = isSkinAid() ? "SkinAid" : "SkinScanner"
export const MANUFACTURER_MODEL_NAME = "Dermus Ltd";
export const USER_SIGNED_IN_LOCAL_STORAGE = "userSignedIn";
export const USER_DATA_SIGNED_IN_LOCAL_STORAGE = "userdataSignedIn";
export const REDICERTION_PATH_AUTH_LOCAL_STORAGE = "pathAuthRedirection";
export const SSO_LOCAL_STORAGE = "SSO";
export const INSTALL_APP_LOCAL_STORAGE = "userSignedIn";

export const ICD_TOKEN_IN_LOCAL_STORAGE = "ICDToken"
export const DEMO_DATA_IN_LOCAL_STORAGE = "demoData";
export const LAST_EDITED_LESION_STORAGE = "lastLesion";

export const SAVE_IS_SUCCESSFUL = "Successful save"
export const CHECK_SAVED_RECS = "Check the saved recording"
export const RETURN_TO_CAPTURE = "Return to Capture"
export const UNKNOWN_ERROR = "Unknown error"


export const PLUGIN_SVG_URL = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/please_plug_in_only_phone.svg"
export const PLUGIN_ONLY_DESKTOP_SVG_URL = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/please_plug_in_only_desktop.svg"
export const ROTATE_SVG_URL = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/rotate_phone_hamburger.svg"
export const START_SVG_URL = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/start_capture_only_phone.svg"
export const START_ONLY_DEVICE_SVG_URL = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/start_capture_only_device.svg"

export const DERM_IMAGE_TIP_SVG_URL = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/derm_image_tip.svg"
export const CLIN_IMAGE_TIP_SVG_URL = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/clin_image_tip.svg"

export const NO_RECORD_PLACEHOLDER = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/no_record_yet.svg"
export const NO_LESION_PLACEHOLDER = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/no_lesion_yet.svg"

export const THREE_POINT_PICKER_URL = "https://skinaid-public-docs.s3.eu-central-1.amazonaws.com/images/three_points_picker.svg"

export const DEMO_DATA_JSON_URL = "https://dermus-demo-data.s3.eu-central-1.amazonaws.com/captureDemo/demo_data_v2.json"
export const SEGMENTATION_LAYER_ENUM = {};
SEGMENTATION_LAYER_ENUM["1"] = "ABOVE_SKIN";
SEGMENTATION_LAYER_ENUM["2"] = "EPIDERMIS";
SEGMENTATION_LAYER_ENUM["4"] = "SLEB";
SEGMENTATION_LAYER_ENUM["8"] = "LESION";
SEGMENTATION_LAYER_ENUM["16"] = "DERMIS";
SEGMENTATION_LAYER_ENUM["32"] = "SUBCUTIS";
SEGMENTATION_LAYER_ENUM["64"] = "OTHER";
export const SEGMENTATION_LAYER_COLORS = {
  ABOVE_SKIN: "#737373",
  EPIDERMIS: "#ca95f2",
  SLEB: "#ff9acb",
  LESION: "#ff9acb",
  DERMIS: "#a8f387",
  SUBCUTIS: "#f2d66f",
  OTHER: "#16D6FA",
};

export const SSU_FW_PREFIX = "SSU_FW_"

//Measurements style of plot
export const DEFAULT_STYLE_OF_BAR = {
  backgroundColor: ["#e2304c33"],
  borderColor: ["#e2304c"],
  borderWidth: 1,
};

//Record types
export const RECORD_TYPE = {
  US_OPT_CINELOOP: 'US_OPT_CINELOOP',
  US_OPT_FRAMEPAIR: 'US_OPT_FRAMEPAIR',
  OPTOFUSE: 'OPTOFUSE',
  OPTICAL: 'OPTICAL',
  LINE_DOPPLER: 'LINE_DOPPLER',
  LINE_DOPPLER_CINELOOP: 'LINE_DOPPLER_CINELOOP'
}

//Patient types
export const PATIENT_TYPE = {
  HUMAN: 'HUMAN',
  ANIMAL: 'ANIMAL'
}


//Animal types
export const ANIMAL_TYPE = {
  MOUSE: "MOUSE",
  RAT: "RAT",
  PIG: "PIG",
  RABBIT: "RABBIT",
  DOG: "DOG",
  CAT: "CAT",
  HORSE: "HORSE",
  PRIMATE: "PRIMATE"
}

export const COLORMAP_SHADES = 1000;
export const IM_TYPE = { OPT: "OPT", US: "US", US_MF: "US_MF" };
export const DEPTH_MAP_UNIT = "mm";
export const MICRON = "um";

export const SEGMENTATION_LAYERS = {
  ABOVE_SKIN: "above skin",
  EPIDERMIS: "epidermis",
  // SLEB: "sleb", still lesion and sleb are not handled differently
  LESION: "lesion/sleb",
  DERMIS: "dermis",
  SUBCUTIS: "subcutis",
  OTHER: "other",
};
export const COLORMAP_NAME = "blackbody";
export const COLORMAP_NLABEL = 6;

export const transformWrapperConfig = {
  options: { maxScale: 12 },
  pan: {
    paddingSize: 0,
    velocityBaseTime: 0,
    //  disableOnTarget: ["canvas"],
  }, //no pan animation
  scalePadding: { disabled: true },
}; //no zoom animation (scale padding)

export const TRAINING_VIDEOS_LINK = "https://www.dermusvision.com/training-materials";
export const TERMS_AND_CONDITION_CURRENT_LINK =
  "https://dermus.atlassian.net/wiki/spaces/DOC/pages/3335782419/SkinAid+Terms+Conditions";
export const PRIVACY_POLICY_CURRENT_LINK =
  "https://dermus.atlassian.net/wiki/spaces/DOC/pages/3335618575/Privacy+Policy";
export const SKIN_SCANNER_U_RELEASE_NOTES_LINK =
  "https://dermus.atlassian.net/wiki/spaces/DOC/pages/3250880527/Release+Notes+SkinScanner-U";
export const SKIN_AID_RELEASE_NOTES_LINK =
  "https://dermus.atlassian.net/wiki/spaces/DOC/pages/3250847772/Release+Notes+SkinAid";

export const DERMUS_APP_SUPPORTED_HOST_DEVICE_LINK = "https://dermus.atlassian.net/wiki/spaces/DOC/pages/3181936652/SkinScanner-U+User+Manual#1.6.-Host-device"
export const OVERVIEW_LINK = "https://dermus.atlassian.net/wiki/spaces/DOC/overview";
export const SKIN_AID_USER_MANUAL_LINK = "https://dermus.atlassian.net/l/cp/HWaZXX0P"
export const INSTRUCTION_VIDEO_LINK = "Cooming soon..."
export const DERMUS_WIKI_LINK = "https://dermus.atlassian.net/l/cp/AKQqshDv"
export const SSU_USER_GUIDE_LINK = "https://dermus.atlassian.net/l/cp/GqLrT2k8"
export const SSU_NAME = "Dermus SkinScanner-U"
export const SKIN_AID_NAME = "Dermus SkinAid"
export const SUPPORT_EMAIL = "support@dermusvision.com"
export const SUPPORT_PHONE = "+36 70 398 9228"
export const US_COLORMAP = [
  0, 0, 0, 0.00392156862745098, 0.027450980392156862, 0.0196078431372549,
  0.00784313725490196, 0.054901960784313725, 0.03529411764705882,
  0.011764705882352941, 0.08627450980392157, 0.054901960784313725,
  0.01568627450980392, 0.11372549019607843, 0.07450980392156863,
  0.0196078431372549, 0.1411764705882353, 0.09019607843137255,
  0.023529411764705882, 0.16862745098039217, 0.10980392156862745,
  0.027450980392156862, 0.19607843137254902, 0.12549019607843137,
  0.03137254901960784, 0.22745098039215686, 0.1450980392156863,
  0.03529411764705882, 0.2549019607843137, 0.16470588235294117,
  0.0392156862745098, 0.2823529411764706, 0.1803921568627451,
  0.043137254901960784, 0.30980392156862746, 0.2, 0.047058823529411764,
  0.33725490196078434, 0.2196078431372549, 0.050980392156862744,
  0.3686274509803922, 0.23529411764705882, 0.054901960784313725,
  0.396078431372549, 0.2549019607843137, 0.058823529411764705,
  0.4235294117647059, 0.27058823529411763, 0.06274509803921569,
  0.45098039215686275, 0.2901960784313726, 0.06666666666666667,
  0.47843137254901963, 0.30980392156862746, 0.07058823529411765,
  0.5098039215686274, 0.3254901960784314, 0.07450980392156863,
  0.5372549019607843, 0.34509803921568627, 0.0784313725490196,
  0.5647058823529412, 0.36470588235294116, 0.08235294117647059,
  0.592156862745098, 0.3803921568627451, 0.08627450980392157,
  0.6196078431372549, 0.4, 0.09019607843137255, 0.6509803921568628,
  0.41568627450980394, 0.09411764705882353, 0.6784313725490196,
  0.43529411764705883, 0.09803921568627451, 0.7058823529411765,
  0.4549019607843137, 0.10196078431372549, 0.7333333333333333,
  0.47058823529411764, 0.10588235294117647, 0.7607843137254902,
  0.49019607843137253, 0.10980392156862745, 0.792156862745098,
  0.5098039215686274, 0.11372549019607843, 0.8196078431372549,
  0.5254901960784314, 0.11764705882352941, 0.8470588235294118,
  0.5450980392156862, 0.12156862745098039, 0.8745098039215686,
  0.5607843137254902, 0.12549019607843137, 0.9019607843137255,
  0.5803921568627451, 0.12941176470588237, 0.9333333333333333, 0.6,
  0.13333333333333333, 0.9607843137254902, 0.615686274509804,
  0.13725490196078433, 0.9882352941176471, 0.6352941176470588,
  0.1450980392156863, 0.9686274509803922, 0.6431372549019608,
  0.14901960784313725, 0.9450980392156862, 0.6509803921568628,
  0.1568627450980392, 0.9254901960784314, 0.6588235294117647,
  0.1607843137254902, 0.9019607843137255, 0.6666666666666666,
  0.16862745098039217, 0.8823529411764706, 0.6745098039215687,
  0.17254901960784313, 0.8588235294117647, 0.6862745098039216,
  0.1803921568627451, 0.8392156862745098, 0.6941176470588235,
  0.1843137254901961, 0.8156862745098039, 0.7019607843137254,
  0.19215686274509805, 0.796078431372549, 0.7098039215686275,
  0.19607843137254902, 0.7725490196078432, 0.7176470588235294,
  0.20392156862745098, 0.7529411764705882, 0.7254901960784313,
  0.20784313725490197, 0.7294117647058823, 0.7333333333333333,
  0.21568627450980393, 0.7098039215686275, 0.7411764705882353,
  0.2196078431372549, 0.6862745098039216, 0.7490196078431373,
  0.22745098039215686, 0.6666666666666666, 0.7568627450980392,
  0.23137254901960785, 0.6431372549019608, 0.7686274509803922,
  0.23921568627450981, 0.6235294117647059, 0.7764705882352941,
  0.24313725490196078, 0.6, 0.7843137254901961, 0.25098039215686274,
  0.5803921568627451, 0.792156862745098, 0.2549019607843137, 0.5568627450980392,
  0.8, 0.2627450980392157, 0.5372549019607843, 0.807843137254902,
  0.26666666666666666, 0.5137254901960784, 0.8156862745098039,
  0.27450980392156865, 0.49411764705882355, 0.8235294117647058,
  0.2784313725490196, 0.47058823529411764, 0.8313725490196079,
  0.28627450980392155, 0.45098039215686275, 0.8392156862745098,
  0.2901960784313726, 0.42745098039215684, 0.8509803921568627,
  0.2980392156862745, 0.40784313725490196, 0.8588235294117647,
  0.30196078431372547, 0.3843137254901961, 0.8666666666666667,
  0.30980392156862746, 0.36470588235294116, 0.8745098039215686,
  0.3137254901960784, 0.3411764705882353, 0.8823529411764706,
  0.3215686274509804, 0.3215686274509804, 0.8901960784313725,
  0.34509803921568627, 0.30980392156862746, 0.8588235294117647,
  0.3686274509803922, 0.30196078431372547, 0.8313725490196079,
  0.38823529411764707, 0.2901960784313726, 0.8, 0.4117647058823529,
  0.2784313725490196, 0.7725490196078432, 0.43529411764705883,
  0.26666666666666666, 0.7411764705882353, 0.4588235294117647,
  0.25882352941176473, 0.7137254901960784, 0.47843137254901963,
  0.24705882352941178, 0.6823529411764706, 0.5019607843137255,
  0.23529411764705882, 0.6509803921568628, 0.5254901960784314,
  0.2235294117647059, 0.6235294117647059, 0.5490196078431373,
  0.21568627450980393, 0.592156862745098, 0.5686274509803921,
  0.20392156862745098, 0.5647058823529412, 0.592156862745098,
  0.19215686274509805, 0.5333333333333333, 0.615686274509804,
  0.1803921568627451, 0.5058823529411764, 0.6392156862745098,
  0.17254901960784313, 0.4745098039215686, 0.6588235294117647,
  0.1607843137254902, 0.4470588235294118, 0.6823529411764706,
  0.14901960784313725, 0.41568627450980394, 0.7058823529411765,
  0.1411764705882353, 0.3843137254901961, 0.7294117647058823,
  0.12941176470588237, 0.3568627450980392, 0.7529411764705882,
  0.11764705882352941, 0.3254901960784314, 0.7725490196078432,
  0.10588235294117647, 0.2980392156862745, 0.796078431372549,
  0.09803921568627451, 0.26666666666666666, 0.8196078431372549,
  0.08627450980392157, 0.23921568627450981, 0.8431372549019608,
  0.07450980392156863, 0.20784313725490197, 0.8627450980392157,
  0.06274509803921569, 0.17647058823529413, 0.8862745098039215,
  0.054901960784313725, 0.14901960784313725, 0.9098039215686274,
  0.043137254901960784, 0.11764705882352941, 0.9333333333333333,
  0.03137254901960784, 0.09019607843137255, 0.9529411764705882,
  0.0196078431372549, 0.058823529411764705, 0.9764705882352941,
  0.011764705882352941, 0.03137254901960784, 1, 0, 0, 1, 0.01568627450980392, 0,
  1, 0.03137254901960784, 0.00392156862745098, 1, 0.043137254901960784,
  0.00392156862745098, 1, 0.058823529411764705, 0.00392156862745098, 1,
  0.07450980392156863, 0.00392156862745098, 1, 0.09019607843137255,
  0.00784313725490196, 1, 0.10196078431372549, 0.00784313725490196, 1,
  0.11764705882352941, 0.00784313725490196, 1, 0.13333333333333333,
  0.00784313725490196, 1, 0.14901960784313725, 0.011764705882352941, 1,
  0.1607843137254902, 0.011764705882352941, 1, 0.17647058823529413,
  0.011764705882352941, 1, 0.19215686274509805, 0.011764705882352941, 1,
  0.20784313725490197, 0.01568627450980392, 1, 0.2196078431372549,
  0.01568627450980392, 1, 0.23529411764705882, 0.01568627450980392, 1,
  0.25098039215686274, 0.01568627450980392, 1, 0.26666666666666666,
  0.0196078431372549, 1, 0.2784313725490196, 0.0196078431372549, 1,
  0.29411764705882354, 0.0196078431372549, 1, 0.30980392156862746,
  0.0196078431372549, 1, 0.3254901960784314, 0.023529411764705882, 1,
  0.33725490196078434, 0.023529411764705882, 1, 0.35294117647058826,
  0.023529411764705882, 1, 0.3686274509803922, 0.023529411764705882, 1,
  0.3843137254901961, 0.027450980392156862, 1, 0.396078431372549,
  0.027450980392156862, 1, 0.4117647058823529, 0.027450980392156862, 1,
  0.42745098039215684, 0.027450980392156862, 1, 0.44313725490196076,
  0.03137254901960784, 1, 0.4549019607843137, 0.03137254901960784, 1,
  0.47058823529411764, 0.03137254901960784, 1, 0.48627450980392156,
  0.03137254901960784, 1, 0.5019607843137255, 0.03529411764705882, 1,
  0.5137254901960784, 0.03529411764705882, 1, 0.5294117647058824,
  0.03529411764705882, 1, 0.5450980392156862, 0.03529411764705882, 1,
  0.5568627450980392, 0.0392156862745098, 1, 0.5725490196078431,
  0.0392156862745098, 1, 0.5882352941176471, 0.0392156862745098, 1,
  0.6039215686274509, 0.0392156862745098, 1, 0.615686274509804,
  0.043137254901960784, 1, 0.6313725490196078, 0.043137254901960784, 1,
  0.6470588235294118, 0.043137254901960784, 1, 0.6627450980392157,
  0.043137254901960784, 1, 0.6745098039215687, 0.047058823529411764, 1,
  0.6901960784313725, 0.047058823529411764, 1, 0.7058823529411765,
  0.047058823529411764, 1, 0.7215686274509804, 0.047058823529411764, 1,
  0.7333333333333333, 0.050980392156862744, 1, 0.7490196078431373,
  0.050980392156862744, 1, 0.7647058823529411, 0.050980392156862744, 1,
  0.7803921568627451, 0.050980392156862744, 1, 0.792156862745098,
  0.054901960784313725, 1, 0.807843137254902, 0.054901960784313725, 1,
  0.8235294117647058, 0.054901960784313725, 1, 0.8392156862745098,
  0.054901960784313725, 1, 0.8509803921568627, 0.058823529411764705, 1,
  0.8666666666666667, 0.058823529411764705, 1, 0.8823529411764706,
  0.058823529411764705, 1, 0.8980392156862745, 0.058823529411764705, 1,
  0.9098039215686274, 0.06274509803921569, 1, 0.9254901960784314,
  0.06274509803921569, 1, 0.9411764705882353, 0.06274509803921569, 1,
  0.9568627450980393, 0.06274509803921569, 1, 0.9686274509803922,
  0.06666666666666667, 1, 0.984313725490196, 0.06666666666666667, 1, 1,
  0.06666666666666667, 1, 1, 0.07450980392156863, 1, 1, 0.08235294117647059, 1,
  1, 0.08627450980392157, 1, 1, 0.09411764705882353, 1, 1, 0.10196078431372549,
  1, 1, 0.10980392156862745, 1, 1, 0.11764705882352941, 1, 1,
  0.12156862745098039, 1, 1, 0.12941176470588237, 1, 1, 0.13725490196078433, 1,
  1, 0.1450980392156863, 1, 1, 0.15294117647058825, 1, 1, 0.1568627450980392, 1,
  1, 0.16470588235294117, 1, 1, 0.17254901960784313, 1, 1, 0.1803921568627451,
  1, 1, 0.18823529411764706, 1, 1, 0.19215686274509805, 1, 1, 0.2, 1, 1,
  0.20784313725490197, 1, 1, 0.21568627450980393, 1, 1, 0.2235294117647059, 1,
  1, 0.22745098039215686, 1, 1, 0.23529411764705882, 1, 1, 0.24313725490196078,
  1, 1, 0.25098039215686274, 1, 1, 0.25882352941176473, 1, 1,
  0.2627450980392157, 1, 1, 0.27058823529411763, 1, 1, 0.2784313725490196, 1, 1,
  0.28627450980392155, 1, 1, 0.29411764705882354, 1, 1, 0.2980392156862745, 1,
  1, 0.3058823529411765, 1, 1, 0.3137254901960784, 1, 1, 0.3215686274509804, 1,
  1, 0.32941176470588235, 1, 1, 0.3333333333333333, 1, 1, 0.3411764705882353, 1,
  1, 0.34901960784313724, 1, 1, 0.3568627450980392, 1, 1, 0.36470588235294116,
  1, 1, 0.3686274509803922, 1, 1, 0.3764705882352941, 1, 1, 0.3843137254901961,
  0.996078431372549, 1, 0.39215686274509803, 0.996078431372549, 1, 0.4,
  0.996078431372549, 1, 0.40784313725490196, 0.996078431372549, 1,
  0.4117647058823529, 0.996078431372549, 1, 0.4196078431372549,
  0.996078431372549, 1, 0.42745098039215684, 0.996078431372549, 1,
  0.43529411764705883, 0.996078431372549, 1, 0.44313725490196076,
  0.996078431372549, 1, 0.4470588235294118, 0.996078431372549, 1,
  0.4549019607843137, 0.996078431372549, 1, 0.4627450980392157,
  0.996078431372549, 1, 0.47058823529411764, 0.996078431372549, 1,
  0.47843137254901963, 0.996078431372549, 1, 0.4823529411764706,
  0.996078431372549, 1, 0.49019607843137253, 0.996078431372549, 1,
  0.4980392156862745, 0.996078431372549, 1, 0.5058823529411764,
  0.996078431372549, 1, 0.5137254901960784, 0.996078431372549, 1,
  0.5176470588235295, 0.996078431372549, 1, 0.5254901960784314,
  0.996078431372549, 1, 0.5333333333333333, 0.996078431372549, 1,
  0.5411764705882353, 0.996078431372549, 1, 0.5490196078431373,
  0.996078431372549, 1, 0.5529411764705883, 0.996078431372549, 1,
  0.5607843137254902, 0.996078431372549, 1, 0.5686274509803921,
  0.996078431372549, 1, 0.5764705882352941, 0.996078431372549, 1,
  0.5843137254901961, 0.996078431372549, 1, 0.5882352941176471,
  0.996078431372549, 1, 0.596078431372549, 0.996078431372549, 1,
  0.6039215686274509, 0.996078431372549, 1, 0.611764705882353,
  0.996078431372549, 1, 0.6196078431372549, 0.996078431372549, 1,
  0.6235294117647059, 0.996078431372549, 1, 0.6313725490196078,
  0.996078431372549, 1, 0.6392156862745098, 0.996078431372549, 1,
  0.6470588235294118, 0.996078431372549, 1, 0.6549019607843137,
  0.996078431372549, 1, 0.6588235294117647, 0.996078431372549, 1,
  0.6666666666666666, 0.996078431372549, 1, 0.6745098039215687,
  0.996078431372549, 1, 0.6823529411764706, 0.996078431372549, 1,
  0.6901960784313725, 0.996078431372549, 1, 0.6941176470588235,
  0.996078431372549, 1, 0.7019607843137254, 0.996078431372549, 1,
  0.7098039215686275,
];



export const FLOW_DERMUS_COLORMAP = [
  0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00000, 0.00303, 0.02184, 0.01399, 0.00606, 0.04367, 0.02799, 0.00909, 0.06551, 0.04198, 0.01212, 0.08734, 0.05597, 0.01515, 0.10918, 0.06996, 0.01818, 0.13102, 0.08396, 0.02121, 0.15285, 0.09795, 0.02424, 0.17469, 0.11194, 0.02727, 0.19652, 0.12594, 0.03030, 0.21836, 0.13993, 0.03333, 0.24020, 0.15392, 0.03636, 0.26203, 0.16791, 0.03939, 0.28387, 0.18191, 0.04242, 0.30570, 0.19590, 0.04545, 0.32754, 0.20989, 0.04848, 0.34938, 0.22389, 0.05152, 0.37121, 0.23788, 0.05455, 0.39305, 0.25187, 0.05758, 0.41488, 0.26586, 0.06061, 0.43672, 0.27986, 0.06364, 0.45856, 0.29385, 0.06667, 0.48039,
  0.30784, 0.06970, 0.50223, 0.32184, 0.07273, 0.52406, 0.33583, 0.07576, 0.54590, 0.34982, 0.07879, 0.56774, 0.36381, 0.08182, 0.58957, 0.37781, 0.08485, 0.61141, 0.39180, 0.08788, 0.63324, 0.40579, 0.09091, 0.65508, 0.41979, 0.09394, 0.67692, 0.43378, 0.09697, 0.69875, 0.44777, 0.10000, 0.72059, 0.46176, 0.10303, 0.74242, 0.47576, 0.10606, 0.76426, 0.48975, 0.10909, 0.78610, 0.50374, 0.11212, 0.80793, 0.51774, 0.11515, 0.82977, 0.53173, 0.11818, 0.85160, 0.54572, 0.12121, 0.87344, 0.55971, 0.12424, 0.89528, 0.57371, 0.12727, 0.91711, 0.58770, 0.13030, 0.93895, 0.60169, 0.13333, 0.96078, 0.61569, 0.13725, 0.98824, 0.63529, 0.14510, 0.96863, 0.64314, 0.14902, 0.94510, 0.65098, 0.15686, 0.92549, 0.65882, 0.16078, 0.90196, 0.66667, 0.16863, 0.88235, 0.67451, 0.17255, 0.85882, 0.68627, 0.18039, 0.83922, 0.69412, 0.18431, 0.81569, 0.70196, 0.19216, 0.79608, 0.70980, 0.19608, 0.77255, 0.71765, 0.20392, 0.75294, 0.72549, 0.20784, 0.72941, 0.73333, 0.21569, 0.70980, 0.74118, 0.21961, 0.68627, 0.74902, 0.22745, 0.66667, 0.75686, 0.23137, 0.64314, 0.76863, 0.23922, 0.62353, 0.77647, 0.24314, 0.60000, 0.78431, 0.25098, 0.58039, 0.79216, 0.25490, 0.55686,
  0.80000, 0.26275, 0.53725, 0.80784, 0.26667, 0.51373, 0.81569, 0.27451, 0.49412, 0.82353, 0.27843, 0.47059, 0.83137, 0.28627, 0.45098, 0.83922, 0.29020, 0.42745, 0.85098, 0.29804, 0.40784, 0.85882, 0.30196, 0.38431, 0.86667, 0.30980, 0.36471, 0.87451, 0.31373, 0.34118, 0.88235, 0.32157, 0.32157, 0.89020, 0.34510, 0.30980, 0.85882, 0.36863, 0.30196, 0.83137, 0.38824, 0.29020, 0.80000, 0.41176, 0.27843, 0.77255, 0.43529, 0.26667, 0.74118, 0.45882, 0.25882, 0.71373, 0.47843, 0.24706, 0.68235, 0.50196, 0.23529, 0.65098, 0.52549, 0.22353, 0.62353, 0.54902, 0.21569, 0.59216, 0.56863, 0.20392, 0.56471, 0.59216, 0.19216, 0.53333, 0.61569, 0.18039, 0.50588, 0.63922, 0.17255, 0.47451, 0.65882, 0.16078, 0.44706, 0.68235, 0.14902, 0.41569, 0.70588, 0.14118, 0.38431, 0.72941, 0.12941, 0.35686, 0.75294, 0.11765, 0.32549, 0.77255, 0.10588, 0.29804, 0.79608, 0.09804, 0.26667, 0.81961, 0.08627, 0.23922, 0.84314, 0.07451, 0.20784, 0.86275, 0.06275, 0.17647, 0.88627, 0.05490, 0.14902, 0.90980, 0.04314, 0.11765, 0.93333, 0.03137, 0.09020, 0.95294, 0.01961, 0.05882, 0.97647, 0.01176, 0.03137, 1.00000, 0.00000, 0.00000, 1.00000, 0.01569, 0.00000, 1.00000, 0.03137, 0.00392, 1.00000, 0.04314, 0.00392, 1.00000, 0.05882, 0.00392, 1.00000, 0.07451, 0.00392, 1.00000, 0.09020, 0.00784, 1.00000, 0.10196, 0.00784, 1.00000, 0.11765, 0.00784, 1.00000, 0.13333, 0.00784, 1.00000, 0.14902, 0.01176, 1.00000, 0.16078, 0.01176, 1.00000, 0.17647, 0.01176, 1.00000, 0.19216, 0.01176, 1.00000, 0.20784, 0.01569, 1.00000, 0.21961, 0.01569, 1.00000,
  0.23529, 0.01569, 1.00000, 0.25098, 0.01569, 1.00000, 0.26667, 0.01961, 1.00000, 0.27843, 0.01961, 1.00000, 0.29412, 0.01961, 1.00000, 0.30980, 0.01961, 1.00000, 0.32549, 0.02353, 1.00000, 0.33725, 0.02353, 1.00000, 0.35294, 0.02353, 1.00000, 0.36863, 0.02353, 1.00000, 0.38431, 0.02745, 1.00000, 0.39608, 0.02745, 1.00000, 0.41176, 0.02745, 1.00000, 0.42745, 0.02745, 1.00000, 0.44314, 0.03137, 1.00000, 0.45490, 0.03137, 1.00000, 0.47059, 0.03137, 1.00000, 0.48627, 0.03137, 1.00000, 0.50196, 0.03529, 1.00000, 0.51373, 0.03529, 1.00000, 0.52941, 0.03529, 1.00000, 0.54510, 0.03529, 1.00000, 0.55686, 0.03922, 1.00000, 0.57255, 0.03922, 1.00000, 0.58824, 0.03922, 1.00000, 0.60392, 0.03922, 1.00000, 0.61569, 0.04314, 1.00000, 0.63137, 0.04314, 1.00000, 0.64706, 0.04314, 1.00000, 0.66275, 0.04314, 1.00000, 0.67451, 0.04706, 1.00000, 0.69020, 0.04706, 1.00000, 0.70588, 0.04706, 1.00000, 0.72157, 0.04706, 1.00000, 0.73333, 0.05098, 1.00000, 0.74902, 0.05098, 1.00000, 0.76471, 0.05098, 1.00000, 0.78039, 0.05098, 1.00000, 0.79216, 0.05490, 1.00000, 0.80784, 0.05490, 1.00000, 0.82353, 0.05490, 1.00000, 0.83922, 0.05490, 1.00000, 0.85098, 0.05882, 1.00000,
  0.86667, 0.05882, 1.00000, 0.88235, 0.05882, 1.00000, 0.89804, 0.05882, 1.00000, 0.90980, 0.06275, 1.00000, 0.92549, 0.06275, 1.00000, 0.94118, 0.06275, 1.00000, 0.95686, 0.06275, 1.00000, 0.96863, 0.06667, 1.00000, 0.98431, 0.06667, 1.00000, 1.00000, 0.06667, 1.00000, 1.00000, 0.07451, 1.00000, 1.00000, 0.08235, 1.00000, 1.00000, 0.08627, 1.00000, 1.00000, 0.09412, 1.00000, 1.00000, 0.10196, 1.00000, 1.00000, 0.10980, 1.00000, 1.00000, 0.11765, 1.00000, 1.00000, 0.12157, 1.00000, 1.00000, 0.12941, 1.00000, 1.00000, 0.13725, 1.00000, 1.00000, 0.14510, 1.00000, 1.00000, 0.15294, 1.00000, 1.00000, 0.15686, 1.00000, 1.00000, 0.16471, 1.00000, 1.00000, 0.17255, 1.00000, 1.00000, 0.18039, 1.00000, 1.00000, 0.18824, 1.00000, 1.00000, 0.19216, 1.00000, 1.00000, 0.20000, 1.00000, 1.00000, 0.20784, 1.00000, 1.00000, 0.21569, 1.00000, 1.00000, 0.22353, 1.00000, 1.00000, 0.22745, 1.00000, 1.00000, 0.23529, 1.00000, 1.00000, 0.24314, 1.00000, 1.00000, 0.25098, 1.00000, 1.00000, 0.25882, 1.00000, 1.00000, 0.26275, 1.00000, 1.00000, 0.27059, 1.00000, 1.00000, 0.27843, 1.00000, 1.00000, 0.28627, 1.00000, 1.00000, 0.29412, 1.00000, 1.00000, 0.29804, 1.00000, 1.00000, 0.30588, 1.00000, 1.00000, 0.31373, 1.00000, 1.00000, 0.32157, 1.00000, 1.00000, 0.32941, 1.00000, 1.00000, 0.33333, 1.00000, 1.00000, 0.34118, 1.00000, 1.00000, 0.34902, 1.00000, 1.00000, 0.35686, 1.00000, 1.00000, 0.36471, 1.00000, 1.00000, 0.36863, 1.00000, 1.00000, 0.37647, 1.00000, 1.00000, 0.38431, 0.99608, 1.00000, 0.39216, 0.99608, 1.00000, 0.40000]


export const POST_MESSAGE = {
  REPORT: "REPORT",
  DEVICE_CLOSED: "DEVICE_CLOSED",
  WRITE_EEPROM: "WRITE_EEPROM",
  SEND_COMMAND: "SEND_COMMAND",
  ERROR: "ERROR",
  EEPROM_DATA: "EEPROM_DATA",
  BULK_READ: "BULK_READ",
  NEW_IMAGE: "NEW_IMAGE",
  NEW_DOPPLER: "NEW_DOPPLER",
  PACKET_NUM_CONFUSION: "PACKET_NUM_CONFUSION",
  OVER_RUNNING_FRAME: "OVER_RUNNING_FRAME",
  NOT_FULL_FRAME: "NOT_FULL_FRAME",
  CORRUPTED_FRAME: "CORRUPTED_FRAME",
  DEVICE_OPENED: "DEVICE_OPENED",
  INITIALIZED: "INITIALIZED",
  INIT: "INIT",
  OPEN_DEVICE: "OPEN_DEVICE",
  CLOSE_DEVICE: "CLOSE_DEVICE",
  START_STREAMING: "START_STREAMING",
  STOP_STREAMING: "STOP_STREAMING",
  SET_ACTIVE: "SET_ACTIVE",
  EEPROM_BYTE_READ: "EEPROM_BYTE_READ",
  GET_VERSIONS: "GET_VERSIONS",
  LATERAL_POSITION: "LATERAL_POSITION",
  META_REPORT_MIN_Y_Reached: "META_REPORT_MIN_Y_Reached",
  META_REPORT_MIN_X_Reached: "META_REPORT_MIN_X_Reached",
  META_REPORT_MAX_Y_Reached: "META_REPORT_MAX_Y_Reached",
  META_REPORT_MAX_X_Reached: "META_REPORT_MAX_X_Reached",
  NO_ANSWER: "NO_ANSWER",
  DATA_NOT_RECEIVED: "DATA_NOT_RECEIVED",
  DISABLE_FREEZE_UNFREEZE: "DISABLE_FREEZE_UNFREEZE",
  APPLY_DOPPLER: "APPLY_DOPPLER",
  MAIN_VERSION: "MAIN_VERSION"
}

export const ERROR_CODE = {
  FILE_UPLOAD_ERROR: 1,
  DICOM_GENERATION_ERROR: 2,
  ERROR_MISSING_INPUT: 3,
  ADD_RECORD_ERROR: 4,
}

export const WATCHDOG_CHECK_TIME = 1000 * 60;
export const WATCHDOG_MAX_VALUE = 4;

export const ICDSettings = {
  apiServerUrl: "https://id.who.int",
  apiSecured: true,
  icdMinorVersion: "2020-09",
  icdLinearization: "mms",
  language: "en",
  sourceApp: "SkinAid",
  wordsAvailable: false,
  chaptersAvailable: false,
  //chaptersFilter: "14",
  flexisearchAvailable: true,
  height: "200px",
};


export const A_LINE_LENGTH = 1586;
export const NUM_OF_A_LINE = 120;
export const OPT_STREM_SIZE = { width: 960, height: 1080 };

export const PX_MM = 2667 / 20.05;

export const OPT_HEIGHT_MM = 7.5;
export const OPT_WIDTH_MM =
  (OPT_STREM_SIZE.width / OPT_STREM_SIZE.height) * OPT_HEIGHT_MM;

export const US_IMAGE_PXPMM = {
  width: 10,
  height: PX_MM,
};

export const OPT_IMAGE_PXPMM = {
  height: OPT_STREM_SIZE.height / OPT_HEIGHT_MM,
  width: OPT_STREM_SIZE.width / OPT_WIDTH_MM,
};

export const TOOL_ACTION_MODALITY = { US: "US", OPT: "OPT", US_OPT: "US_OPT", CLINICAL: "CLINICAL", DERMOSCOPY: "DERMOSCOPY", NONE: "NONE" }
export const TOOL_ACTION_TYPE = { ANNOTATION: 'ANNOTATION', MEASUREMENT: 'MEASUREMENT', VOLUME_ESTIMATION: 'VOLUME_ESTIMATION', TEXT: "TEXT" }

export const DOPPLER_MODE = {
  LINE_MODE: "LINE_MODE",
  M_MODE: "M_MODE"
}
// User Preferences
export const PREFERENCES = {
  SHOW_ROTATE_PHONE_TIP: "show_rotate_phone_tip",
  SHOW_TO_CAPTURE_TIP: "show_to_capture_tip",
  SHOW_NEW_FEATURE_SKINAID: "show_new_feature_skinaid",
  SHOW_NEW_FEATURE_DERMUS: "show_new_feature_dermus",
  DYNAMIC_RULER_COLORING: "dynamic_ruler_coloring",
  PAN_UP_ULTRASOUND: "pan_up_ultrasound",
  PRESET_OPTICAL_GAIN_INDEX: "preset_optical_gain_index",
  PRESET_ULTRASOUND_GAIN_INDEX: "preset_ultrasound_gain_index",
  MARKER_SIZE: "marker_size",
  START_FROM_CURRENT_LEVEL: "start_from_current_level",
  CATALOG_TICKETS_LARGE_VIEW: "catalog_tickets_large_view",
  INCLUDE_OPT_US_GUIDELINE: "include_opt_us_guideline_downloaded_image",
  INCLUDE_HEADER_IN_DOWNLOADED_IMAGE: "include_header_in_downloaded_image",
  OPT_SHARPENING: "apply_optical_sharpening",
  US_CENTER: "show_us_center",
  SHOW_INTENSITY_VALUES: "show_intensity_values",
  RECORD_LOOP_LENGTH: "record_loop_length"
}

export const LEVELS = {
  ALL: "all",
  PATIENTS: "patients",
  PATIENT: "patient",
  LESION: "lesion",
}

// Filters
export const FILTERS = {
  EXAM_DATE_START: "exam_date_start",
  EXAM_DATE_END: "exam_date_end",
  BIRTH_YEAR_START: "birth_year_start",
  BIRTH_YEAR_END: "birth_year_end",
  SELECTED_DIAGNOSIS: "selected_diagnosis",
  EXTRA_MODALITY: {
    NONE: "none",
    CLINICAL: "clinical",
    DERMOSCOPY: "dermoscopy"
  },
  DIAGNOSIS_TYPE: {
    PRELIMINARY_CLINICAL: "preliminary_clinical",
    FINAL_CLINICAL: "final_clinical",
    HISTOLOGICAL: "histological",
  },
  SEX: {
    FEMALE: "female",
    MALE: "male",
    OTHER: "other"
  },
  NUM_VISITS: {
    NUM_VISITS_ONE: "num_visits_one",
    NUM_VISITS_MORE: "num_visits_more"
  },
  DIAGNOSIS_QUERY: "diagnosis_query",
  RECORD_TYPE: {
    US_OPT_FRAMEPAIR: "us_opt_framepair",
    US_OPT_CINELOOP: "us_opt_cineloop",
    LINE_DOPPLER: "line_doppler",
    LINE_DOPPLER_CINELOOP: "line_doppler_cineloop",
  },
  USED_FILTERS: {
    ANNOTATION: "annotation",
    THICKNESS: "thickness",
    NOTE: "note"
  },
  FAVOURITES: "favourites",
}


//USER PREFERENCES DEFAULT VALUES
export const SHOW_ROTATE_PHONE_TIP_DEFAULT_VALUE = true;
export const SHOW_TO_CAPTURE_TIP_DEFAULT_VALUE = true;
export const INCLUDE_OPT_US_GUIDELINE_DEFAULT_VALUE = true;
export const INCLUDE_HEADER_IN_DONWLOAD_IMAGES_DEFAULT_VALUE = true;
export const DYNAMIC_RULER_COLORING_DEFAULT_VALUE = false;
export const SHOW_INTENSITY_VALUES_DEFAULT_VALUE = false;
export const START_FROM_CURRENT_LEVEL_DEFAULT_VALUE = true;
export const CATALOG_TICKETS_LARGE_VIEW_DEFAULT_VALUE = false;
export const APPLY_OPTICAL_SHARPENING_DEFAULT_VALUE = true;
export const DRAW_US_IMAGE_CENTER_DEFAULT_VALUE = false;
export const PAN_UP_ULTRASOUND_DEFAULT_VALUE = 0;
export const PRESET_ULTRASOUND_GAIN_DEFAULT_INDEX = 6;
export const PRESET_OPTICAL_GAIN_DEFAULT_INDEX = 4;
export const MARKER_SIZE_DEFAULT_VALUE = 5;
export const RECORD_LOOP_VALUES = [100, 300]
export const RECORD_LOOP_LENGTH_DEFAULT_VALUE = RECORD_LOOP_VALUES[0]


// MOBILE VS DESKTOP MODE LIMIT
export const MOBILE_MODE = 550;

// NEW CATALOG WIDTH
export const NEW_CATALOG_WIDTH = 400;


export const DEFAULT_EEPROM_CONFIG = {
  SLD: 0.1,
  PLX: 0.01481481,
  DWBR: 2.12,
  DWBG: 1.28,
  DWBB: 1.07,
  TINT: 14263,//14.263
  LEDDUTY: 0.703,
  LRJC: 0,
  USAMP0: 0x10,
  USBIT1: 0,
  OOX: 0,
  OOY: 0,
}

//AUTOMATED CALIBRATION BRIGHTNESS ALGO PARAMS
export const AUTOMATED_BRIGHTNESS_LOWER_LIMIT = 30;
export const AUTOMATED_BRIGHTNESS_UPPER_LIMIT = 200;


// TEXT SIZE
export const FONTSIZE = { xs: ".5rem", sm: ".7rem", md: ".9rem" };



export const INTEGRATION_TIME_MIN = 3000;
export const INTEGRATION_TIME_MAX = 12000;
export const INTEGRATION_TIME_DEFAULT = 10000;



export const LIST_STYLE = {
  LARGE_ELEMENT_HEIGHT: 80,
  ELEMENT_HEIGHT: 48,
  SMALL_ELEMENT_HEIGHT: 40,
  CHECKBOX_WIDTH: 32,
  FONT_SIZE: 14,
  SMALL_FONT_SIZE: 12,
  SPINNER_SIZE: 20,
  BORDER_RADIUS: 6,
  US_IMAGE_DISPLAY_ASPECT_RATIO: 1.3,
}

export const DEMO_REC_ID = isTestDemo() ? 377 : 1751


// Batch mode
export const BATCH_MODE = {
  FAVOURITE: "favourite",
  DELETE: "delete",
}


export const ZINDEX_DRAWER = 1200;

// HiRes (DEFAULT)
export const HR_GAIN = [0, 0x10, 0, 0, 0, 0]       //decimal 16
// HiDepth
export const HD_GAIN = [1, 0x40, 0, 0, 0, 0]       //decimal 64
// HiRes FIR
export const HR_FIR = [0]
// HiDepth FIR
export const HD_FIR = [1]

// DEFAULT GAIN VALUES IN DB
export const HR_DEFAULT_GAIN_DB = 40.6
export const HD_DEFAULT_GAIN_DB = 43.9


// DEFAULT GAIN VALUES FOR DOPPLER
export const DOPPLER_US_GAIN_RC3 = [0, 0x8, 0, 0, 0, 0]    // 42 dB - (low, USAMP0: 8)
export const DOPPLER_US_GAIN_RC4 = [0, 0x3F, 0, 0, 0, 0]    // 32 dB - (low, USAMP0: 63)

export const LIST_TICKET_SIZE = {
  INIT: 0,
  SMALL: 1,
  LARGE: 2,
}

// RC VERSIONS
export const RC_VERSION_H3 = "H3";
export const RC_VERSION_H4 = "H4";

export const RC_VERSION_R3 = "R3";
export const RC_VERSION_R4 = "R4";

// Video construction parameters
export const FRAME_WIDTH = 1920;
export const FRAME_HEIGHT = 1080;
export const CODEC = 'avc1.64002a';

export const LOWER_BITRATE = 1e6;
export const HIGHER_BITRATE = 1e7;
export const FRAME_NUMBER_THRESHOLD_BITRATE = 100;


export const DERMUSAPP_SUPPORTED_DEVICES = [
  "2107113SG", "2107113SI", "2107113SR",//Xiaomi 11T Pro
  "ThinkPhone by motorola"
]



export const NUM_TO_ALWAYS_RENDER = 10
export const NUM_TO_ALWAYS_RENDER_LARGE = 5


export const US_RANGE = 2047;


export const DOPPLER_SUPPORT_FW_VERSIONS = "2409"//TODO: update on release!!
