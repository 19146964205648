class SourceRecord {
    /**
     * Constructor
     * @param {String} source_record_id source_record_id
     * @param {String} us_frames us_frames
     * @param {String} opt_frames opt_framesid
     */
    constructor(
        source_record_id,
        us_frames,
        opt_frames,
    ) {
        this.source_record_id = source_record_id;
        this.us_frames = us_frames;
        this.opt_frames = opt_frames;
    }
}

export default SourceRecord;