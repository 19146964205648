import * as dateConversion from "../utility/dateConversion";
import Patient from "../Classes/PatientManagement/Patient";
import Lesion from "../Classes/PatientManagement/Lesion";
import Segmentation from "../Classes/PatientManagement/Segmentation";
import Record from "../Classes/PatientManagement/Record";
import Note from "../Classes/PatientManagement/Note";
import { getISONow } from "../utility/dateConversion";

import packageJson from "../../package.json";
import { APP_NAME, MANUFACTURER_MODEL_NAME, SEGMENTATION_LAYER_ENUM, TOOL_ACTION_MODALITY, TOOL_ACTION_TYPE } from "../Constants/Constants";
import User from "../Classes/User";
import { AVG_THICKNESS, MAX_LENGTH, MAX_THICKNESS, SEX_OPT, SPECS, AREA, MM, NA, CLASSIFICATION, SUM_AREA } from "../../../dermuscomponents/src/Constants/Message";
import SourceRecord from "../Classes/PatientManagement/SourceRecord";
import Annotations from "../Classes/PatientManagement/Annotations";
import Measurement from "../Classes/PatientManagement/Measurement";
import VolumeEstimation from "../Classes/PatientManagement/VolumeEstimation";
import FreeTextNote from "../Classes/PatientManagement/FreeTextNote";
import { neutralwhite } from "../../Themes/dermusTheme";
import { relativeToAbolutePointPosition } from "../Imaging/useImage_v2";
import { drawCross } from "../utility/imageAux";

/**
 * Process note: plain text to array
 * @param {String} note noteTmp - string
 * @returns {Array<Object>} tmpArray - object array of selected note tags (label-value pairs)
 */
export const notePlainToArray = (note) => {
  if (!note) {
    return [];
  }
  let noteValues = note.split(";");
  const tmpArray = [];
  for (let i = 0; i < noteValues.length; i++) {
    tmpArray.push({
      value: noteValues[i].trim(),
      label: noteValues[i].trim(),
    });
  }
  return tmpArray;
};

/**
 * Process note: array to plain text
 * @param {Array<Object>} selectedOption - object array of selected note tags (label-value pairs)
 * @returns {String} noteTmp - string
 */
export const noteToPlainText = (selectedOption) => {
  let noteTmp = "";
  for (let i = 0; i < selectedOption.length; i++) {
    noteTmp += selectedOption[i].value;
    i < selectedOption.length - 1 ? (noteTmp += "; ") : (noteTmp += "");
  }
  return noteTmp;
};

/**
 * Process all note tag options
 * @param {Array<String>} noteTagOptions - string array of all potential note tags
 * @returns {Array<Object>} allOptions (label-value pairs)
 */
export const processNoteTagOptions = (noteTagOptions) => {
  const allOptions = [];
  noteTagOptions.forEach(function (element) {
    allOptions.push({ label: element, value: element });
  });
  return allOptions;
};

/**
 * Resturcture received raw patient
 * @param {Object} rawPatient - received raw patient (name, last_record_date, id, birth_year, sex, note - string)
 * @returns {Patient} person (name, lastExamDate, id, birthYear, sex, note - string)
 */
export const processPatient = (rawPatient) => {
  if (rawPatient) {
    return new Patient(
      rawPatient.name,
      dateConversion.getLocalDateStringFromUTCISO(rawPatient.last_record_date),
      rawPatient.last_record_date,
      rawPatient.id,
      parseInt(rawPatient.birth_year),
      rawPatient.sex,
      rawPatient.note,
      rawPatient.favourite,
      rawPatient.diagnosis,
      rawPatient.diagnosis_title,
      rawPatient.free_diagnosis,
      rawPatient.num_of_visits,
      rawPatient.latest_record_coord_x,
      rawPatient.latest_record_coord_y,
      processLesionList(rawPatient.lesions),
      rawPatient.tool_action_text,
      rawPatient.birth_date,
      rawPatient.patient_type,
      rawPatient.animal_type

    );
  }
  return new Patient();
};

/**
 * Resturcture received raw patient list
 * @param {Array<Object>} data - array of raw patient (name, last_record_date, id, birth_year, sex, note - string)
 * @returns {Array<Patient>} list of restructured patient
 */
export const processPatientList = (data) => {
  if (data) {
    return data
      .sort((a, b) =>
        new Date(a.last_record_date) > new Date(b.last_record_date) ? -1 : 1
      )
      .map((element) => processPatient(element));
  }
  return [];
};

/**
 * Resturcture given patient of a raw  list
 * @param {Array<Object>} data - array of raw patient (name, last_record_date, id, birth_year, sex, note - string)
 * @param {String} patId - id of patient to resturcture
 * @param {String} psw - password
 * @returns {Patient} selected patient
 */
export const processOnePatientFromList = (data, patId) => {
  if (data) {
    return processPatient(data.find((element) => +element.id === +patId));
  }
  return new Patient();
};

/**
 * Restructure raw lesion
 * @param {Object} element lesion object (coord_x, coord_y, diagnosis, ground_truth, id, note, optcical, examdate, optical_thumbnail, patient_id, thumbnail, image_url -string)
 * @returns {Lesion} lesion
 */
export const processLesion = (element) => {
  if (element) {
    const coordX = element.coord_x;
    const coordY = element.coord_y;
    return new Lesion(
      coordX,
      coordY,
      element.diagnosis,
      element.diagnosis_title,
      element.ground_truth,
      element.id,
      element.local_id,
      element.note,
      element.favourite,
      element.optical,
      element.optical_url,
      //dateConversion.getLocalDateStringFromUTCISO(element.optical_date),
      //dateConversion.sinceGivenDate(element.optical_date),
      element.last_record_date,
      dateConversion.getLocalDateStringFromUTCISO(element.last_record_date),
      dateConversion.sinceGivenDate(element.last_record_date),
      element.optical_thumbnail,
      element.optical_thumbnail_url,
      element.patient_id,
      element.thumbnail,
      element.image_url,
      element.free_diagnosis,
      element.diagnosis_origin,
      element.ultrasound,
      element.ultrasound_url,
      element.ultrasound_thumbnail,
      element.ultrasound_thumbnail_url,
      element.num_of_visits,
      processRecordList(element.records),
      element.tool_action_text,
      element.has_favourite_record,
      element.doppler,
      element.doppler_url,
      element.doppler_thumbnail,
      element.doppler_thumbnail_url,
    );
  }

  return new Lesion();
};

/**
 * Restructure raw lesion list
 * @param {Array<Object>} data list of raw lesion  (coord_x, coord_y, diagnosis, ground_truth, id, note, optcical, examdate, optical_thumbnail, patient_id, thumbnail, image_url -string)
 * @returns {Array<Lesion>} data list of lesions
 */
export const processLesionList = (data) => {
  if (data) {
    return data
      .sort((a, b) =>
        new Date(a.local_id) > new Date(b.local_id) ? -1 : 1
      )
      .map((element) => {
        return processLesion(element);
      });
  }
  return [];
};

/**
 * Restructure raw record list
 * @param {Array<Object>} data
 * @returns {Record} record
 */
export const processRecordList = (data) => {
  if (data) {
    return data
      .sort((a, b) => (new Date(a.local_id) > new Date(b.local_id) ? -1 : 1))
      .map((element) => {
        return processRecord(element);
      });
  }
  return [];
};

/**
 * Restructure raw record
 * @param {Object} element raw racord
 * @returns {Record} record
 */
export const processRecord = (element) => {
  if (element) {
    return new Record(
      dateConversion.getLocalDateStringFromUTCISO(element.date),
      element.date,
      dateConversion.sinceGivenDate(element.date),
      element.lesion_id,
      element.notes
        .map((note) => processNote(note))
        .sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1)),
      element.optical,
      element.optical_thumbnail,
      element.optical_url,
      element.ultrasound,
      element.ultrasound_thumbnail,
      element.ultrasound_url,


      element.doppler,
      element.doppler_thumbnail,
      element.doppler_url,

      element.clinical,
      element.clinical_thumbnail,
      element.clinical_url,


      element.dermoscopy,
      element.dermoscopy_thumbnail,
      element.dermoscopy_url,

      element.id,
      element.local_id,
      element.device_id,
      element.device_name,
      element.record_type,
      element.source_record?.source_record_id ? new SourceRecord(
        element.source_record.source_record_id,
        element.source_record.us_frames,
        element.source_record.opt_frames
      ) : null,
      element.segmentation?.id ? new Segmentation(
        element.segmentation.id,
        element.segmentation.record_id,
        element.segmentation.date,
        element.segmentation.file_id,
        element.segmentation.file_url,
        element.segmentation.segmentation_type,
        element.segmentation.version,
        element.segmentation.state,
        element.segmentation.processed_frame,
        element.segmentation.source_segmentation_id
      ) : null,

      element.annotations,
      element.measurements,
      element.volume_estimations,
      element.free_texts,
      element.us_metadata,
      element.opt_metadata,
      element.optical_get_urls,
      element.ultrasound_get_urls,
      element.doppler_get_urls,
      element.clinical_get_url,
      element.dermoscopy_get_url,
      element.ultrasound_thumbnail_url,
      element.optical_thumbnail_url,
      element.doppler_thumbnail_url,
      element.favourite,
    );
  }
  return new Record();
};

export const processActionTools = (element) => {
  const optAnnotation = {}
  element.annotations?.OPT?.forEach(element => {
    optAnnotation[element.frame] = new Annotations(element)
  });
  const usAnnotation = {}
  element.annotations?.US?.forEach(element => {
    usAnnotation[element.frame] = new Annotations(element)
  });

  const clinicalAnnotation = {}
  element.annotations?.CLINICAL?.forEach(element => {
    clinicalAnnotation[element.frame] = new Annotations(element)
  });
  const dermoscopyAnnotation = {}
  element.annotations?.DERMOSCOPY?.forEach(element => {
    dermoscopyAnnotation[element.frame] = new Annotations(element)
  });

  const optMeasurement = {}
  element.measurements?.OPT?.forEach(element => {
    optMeasurement[element.frame] = new Measurement(element)
  });

  const usMeasurement = {}
  element.measurements?.US?.forEach(element => {
    usMeasurement[element.frame] = new Measurement(element)
  });

  const usOptVolumeEstimation = {}
  element.volume_estimations?.US_OPT?.forEach(element => {
    usOptVolumeEstimation[element.frame] = new VolumeEstimation(element)
  });

  const dermoscopyMeasurement = {}
  element.measurements?.DERMOSCOPY?.forEach(element => {
    dermoscopyMeasurement[element.frame] = new Measurement(element)
  });

  const clinicalMeasurement = {}
  element.measurements?.CLINICAL?.forEach(element => {
    clinicalMeasurement[element.frame] = new Measurement(element)
  });

  const optFreeText = {}
  element.free_texts?.OPT?.forEach(element => {
    optFreeText[element.frame] = new FreeTextNote(element)
  });
  const usFreeText = {}
  element.free_texts?.US?.forEach(element => {
    usFreeText[element.frame] = new FreeTextNote(element)
  });

  const clinicalFreeText = {}
  element.free_texts?.CLINICAL?.forEach(element => {
    clinicalFreeText[element.frame] = new FreeTextNote(element)
  });

  const dermoscopyFreeText = {}
  element.free_texts?.DERMOSCOPY?.forEach(element => {
    dermoscopyFreeText[element.frame] = new FreeTextNote(element)
  });
  return {
    optAnnotation, usAnnotation, clinicalAnnotation, dermoscopyAnnotation,
    optFreeText, usFreeText, clinicalFreeText, dermoscopyFreeText,
    optMeasurement, usMeasurement, clinicalMeasurement, dermoscopyMeasurement,
    usOptVolumeEstimation
  }
}

/**
 * Restructure raw note
 * @param {Object} note raw note
 * @returns {Note} note
 */
const processNote = (note) => {
  if (note) {
    return new Note(
      dateConversion.getLocalDateStringFromUTCISO(note.date),
      note.doctor_name,
      note.email,
      note.id,
      note.note,
      note.record_id,
      note.remote
    );
  }
  return new Note();
};
/**
 * Restructure raw user
 * @param {Object} data raw user
 * @returns {User} user
 */
export const processUser = (data, session) => {
  if (data.isCompany) {
    data.isCompany = data.isCompany.localeCompare("0") === 0 ? false : true;
  }
  if (data.specialization) {
    let spec = SPECS.find(
      (item) => {
        return item.value
          .toLowerCase()
          .localeCompare(data.specialization.toLowerCase()) === 0
      }
    );
    if (!spec) {
      spec = SPECS.find(
        (item) => {
          return item.label
            .toLowerCase()
            .localeCompare(data.specialization.toLowerCase()) === 0
        }
      );
    }
    data.specialization = spec.label;
  }

  const email = session?.getIdToken()?.payload?.email

  return new User(
    data.prefix,
    data.name,
    data.surname,
    data.specialization,
    email,
    data.company,
    data.city,
    data.country,
  );
};

/**
 * Process optofuse
 * @param {Object} data optofuse
 * @returns {Object} {maskKey: string, maskUrl: string, depthKey: string, depthUrl: string}
 */
export const processOptoFuse = (data) => {
  return {
    maskKey: data.mask_key,
    maskUrl: data.mask_url,
    depthKey: data.depth_key,
    depthUrl: data.depth_url,
    registration: data.registration,
    prevGen: data.prev_gen,
  };
};

/**
 * Create add note body
 * @param {String} note note
 */
export const createAddNoteBody = (note) => ({ date: getISONow(), note });
/**
 * Create addand modify patient body
 * @param {String} birthYear
 * @param {String} sex
 * @param {String} note
 */
export const createAddModifyPatientBody = (birthYear, sex, type, birthDate, id, model, note) => ({
  birth_year: birthYear,
  sex:
    sex === "female"
      ? "female"
      : sex === "male"
        ? "male"
        : sex.toLowerCase().startsWith(SEX_OPT.other)
          ? "other"
          : "unknown",
  name: id,
  birth_date: birthDate,
  patient_type: type,
  animal_type: model,
  note,
});

/**
 * Create DICOM body for new record
 * @param {Float} physicalDeltaX float
 * @param {Float} physicalDeltaY float
 * @param {Integer} numOfUSFrames integer
 * @param {Integer} numOfOpticalFrames integer
 * @param {Array<Integer>} usTimeStampsArray array of integers
 * @param {Array<Integer>} opticalTimeStampsArray array of integers
 * @param {Array<Float>} transformationMatrix 
 * @returns DICOM body
 */
export const createGenerateDICOMBody = (
  physicalDeltaX,
  physicalDeltaY,
  pixelSpacing,
  numOfUSFrames,
  numOfOpticalFrames,
  usTimeStampsArray,
  opticalTimeStampsArray,
  transformationMatrix,
  returnAsJson = false
) => {
  const body = {
    ManufacturerModelName: MANUFACTURER_MODEL_NAME.replace(" ", "_"),
    SoftwareVersions: `${APP_NAME.replace(" ", "")}_v${packageJson.version}`,
    DeviceSerialNumber: "",
    NumOfUSFrames: numOfUSFrames ?? 1,
    NumOfOpticalFrames: numOfOpticalFrames ?? 1,
  };
  if (physicalDeltaX !== null) {
    body["PhysicalDeltaX"] = physicalDeltaX;
  }
  if (physicalDeltaY !== null) {
    body["PhysicalDeltaY"] = physicalDeltaY;
  }
  if (pixelSpacing !== null) {
    body["PixelSpacing"] = pixelSpacing[0] + "\\" + pixelSpacing[1];
  }
  if (usTimeStampsArray !== null) {
    body["UsTimeStampsArray"] = !returnAsJson ? JSON.stringify(usTimeStampsArray) : usTimeStampsArray;
  }
  if (opticalTimeStampsArray !== null) {
    body["OpticalTimeStampsArray"] = !returnAsJson ? JSON.stringify(opticalTimeStampsArray) : opticalTimeStampsArray;
  }
  if (transformationMatrix !== null) {
    body["TransformationMatrix"] = !returnAsJson ? JSON.stringify(transformationMatrix) : transformationMatrix;
  }

  return body;

};

/**
 * Create add lesion body
 * @param {String} patientId
 * @param {*} lesionLocation
 * @param {String} note
 */
export const createAddLesionBody = (patientId, lesionLocation, note, diagnosis, diagnosis_title, free_diagnosis, diagnosis_origin) => {
  const multiplier = lesionLocation.index === 0 ? 1 : -1;
  return {
    patient_id: patientId,
    coord_x: Math.round(multiplier * lesionLocation.x),
    coord_y: Math.round(multiplier * lesionLocation.y),
    note,
    free_diagnosis,
    diagnosis_origin,
    diagnosis,
    diagnosis_title
  };
};

/**
 * Create add lesion body
 * @param {String} patientId
 * @param {*} lesionLocation
 * @param {String} note
 */
export const createModifyLesionBody = (lesionLocation, note) => {
  const multiplier = lesionLocation.index === 0 ? 1 : -1;
  console.log(lesionLocation)
  return {
    coord_x: Math.round(multiplier * lesionLocation.x),
    coord_y: Math.round(multiplier * lesionLocation.y),
    note,
  };
};


/**
 * Create sign up body
 */
export const createSignUpBody = (
  name,
  prefix,
  surname,
  specialization,
  email,
  password,
  company,
  country,
  city
) => ({
  name,
  prefix,
  surname,
  specialization,
  email,
  password,
  company,
  country,
  city
})
/**
 * Create user modification body
 */
export const createUserModifyBody = (
  name,
  affiliation,
  specialization,
  mprn,
  isCompany,
  company_name,
  company_vat,
  companyAddress,
  address
) => {
  const body = {};
  if (name) {
    body.name = name;
  }
  if (affiliation) {
    body.affiliation = affiliation;
  }
  if (specialization) {
    body.specialization = specialization;
  }
  if (mprn) {
    body.mprn = mprn;
  }
  body.isCompany = isCompany ? "1" : "0";
  if (isCompany) {
    if (company_name) {
      body.company_name = company_name;
    }
    if (company_vat) {
      body.company_vat = company_vat;
    }
    if (address) {
      body.address = companyAddress;
    }
  } else {
    body.company_name = "";
    body.company_vat = "";
    if (address) {
      body.address = address;
    }
  }
  return body;
};

/**
 * Create change psw body
 */
export const createChangePswBody = (previous_password, proposed_password) => ({
  previous_password,
  proposed_password,
});

/**
 * Create forgot psw body
 */
export const createForgotPswBody = (email) => ({
  email,
});

/**
 * Create forgot psw body
 */
export const createConfirmPswBody = (email, code, newPsw) => ({
  email,
  code,
  newPsw,
});

/**
 * Create change email body
 */
export const createChangeEmailBody = (email) => ({ email });

/**
 * Create verificaiton email body
 */
export const createVerifyEmailBody = (verification_code) => ({
  verification_code,
});

/**
 * Create save segmentation body
 * @param {Object} polygons polygons
 */
export const createSaveSegmentationBody = (polygons) => {
  const keys = Object.keys(polygons);
  const body = {};
  keys.forEach((item) => {
    body[item] = JSON.stringify(polygons[item]);
  });

  return body;
};


/**
 * Create us segmentation body
 * @param {Booelan} save save to record or not
 */
export const createUSSegmentationBody = (save) => ({
  save,
});

/**
 * Create add source record body
 * @param {String} note note
 */
export const createAddSourceRecord = (source_record_id, us_frames, opt_frames) => ({ source_record: JSON.stringify({ source_record_id, us_frames, opt_frames }) });


/**
 * Create us measurement body
 * @param {Array} ps point [{x, y}]
 * @param {Array} ds distances [{d,dx,dy}]
 * @param {Number} frame frame
 */
export const createUsMeasurementBody = (ps, ds, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.MEASUREMENT,
    modality: TOOL_ACTION_MODALITY.US,
    data: JSON.stringify(
      ps.map((item, index) =>
        ({ x1: item[0].x, y1: item[0].y, x2: item[1].x, y2: item[1].y, dx: ds[index].dx, dy: ds[index].dy, d: ds[index].d, unit: "mm" }))
    ),
    frame,
    date: getISONow()
  }
};
/**
 * Create clinical measurement body
 * @param {Array} ps point [{x, y}]
 * @param {Array} ds distances [{d,dx,dy}]
 * @param {Number} frame frame
 */
export const createClinicalMeasurementBody = (ps, ds, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.MEASUREMENT,
    modality: TOOL_ACTION_MODALITY.CLINICAL,
    data: JSON.stringify(
      ps.map((item, index) =>
        ({ x1: item[0].x, y1: item[0].y, x2: item[1].x, y2: item[1].y, dx: ds[index].dx, dy: ds[index].dy, d: ds[index].d, unit: "mm" }))
    ),
    frame,
    date: getISONow()
  }
};
/**
 * Create dermoscopy measurement body
 * @param {Array} ps point [{x, y}]
 * @param {Array} ds distances [{d,dx,dy}]
 * @param {Number} frame frame
 */
export const createDermoscopyMeasurementBody = (ps, ds, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.MEASUREMENT,
    modality: TOOL_ACTION_MODALITY.DERMOSCOPY,
    data: JSON.stringify(
      ps.map((item, index) =>
        ({ x1: item[0].x, y1: item[0].y, x2: item[1].x, y2: item[1].y, dx: ds[index].dx, dy: ds[index].dy, d: ds[index].d, unit: "mm" }))
    ),
    frame,
    date: getISONow()
  }
};


/**
 * Create measurement modify body
 * @param {Array} ps point [{x, y}]
 * @param {Array} ds distances [{d,dx,dy}]
 * @param {Number} id id
 */
export const createMeasurementModifyBody = (ps, ds) => {
  return {
    data: JSON.stringify(
      ps.map((item, index) =>
        ({ x1: item[0].x, y1: item[0].y, x2: item[1].x, y2: item[1].y, dx: ds[index].dx, dy: ds[index].dy, d: ds[index].d, unit: "mm" }))
    )
  }
};


/**
 * Create opt measurement body
 * @param {Array} ps point [{x, y}]
 * @param {Array} ds distances [{d,dx,dy}]
 * @param {Number} frame frame
 */

//bodys.push(createOptMeasurementBody(m.points.p1, m.points.p2, m.points.dx, m.points.dy, m.points.d, m.points.unit, m.frame));
//export const createOptMeasurementBody = (p1, p2, dx, dy, d, unit, frame = 0) => {
export const createOptMeasurementBody = (ps, ds, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.MEASUREMENT,
    modality: TOOL_ACTION_MODALITY.OPT,
    data: JSON.stringify(
      ps.map((item, index) =>
        ({ x1: item[0].x, y1: item[0].y, x2: item[1].x, y2: item[1].y, dx: ds[index].dx, dy: ds[index].dy, d: ds[index].d, unit: "mm" }))
    ),
    frame,
    date: getISONow()
  }
};


/**
 * Create us - opt volume estimate body
 * @param {Array} psUs US points [{x, y}]
 * @param {Array} dsUs US distances [{d,dx,dy}]
 * @param {Array} psOpt Opt point [{x, y}]
 * @param {Array} dsOpt US distance [{d,dx,dy}]
 * @param {Number} volume volume 
 * @param {Number} frame frame
 */
export const createUsOptVolumeEstimateBody = (psUs, dsUs, psOpt, dsOpt, vol, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.VOLUME_ESTIMATION,
    modality: TOOL_ACTION_MODALITY.US_OPT,
    data: JSON.stringify({
      us: psUs.map((item, index) =>
      ({
        x1: item[0].x,
        y1: item[0].y,
        x2: item[1].x,
        y2: item[1].y,
        dx: dsUs[index].dx,
        dy: dsUs[index].dy,
        d: dsUs[index].d,
        unit: "mm"
      })),
      opt: psOpt.map((item, index) =>
      ({
        x1: item[0].x,
        y1: item[0].y,
        x2: item[1].x,
        y2: item[1].y,
        dx: dsOpt[index].dx,
        dy: dsOpt[index].dy,
        d: dsOpt[index].d,
        unit: "mm"
      })),
      vol: vol
    }),
    frame,
    date: getISONow()
  }
};

/**
 * Create measurement volume estimate body
 * @param {Array} psUs US points [{x, y}]
 * @param {Array} dsUs US distances [{d,dx,dy}]
 * @param {Array} psOpt Opt point [{x, y}]
 * @param {Array} dsOpt US distance [{d,dx,dy}]
 * @param {Number} volume volume 
 * @param {Number} id id
 */
export const createVolumeEstimateModifyBody = (psUs, dsUs, psOpt, dsOpt, vol) => {
  return {
    data: JSON.stringify({
      us: psUs.map((item, index) =>
      ({
        x1: item[0].x,
        y1: item[0].y,
        x2: item[1].x,
        y2: item[1].y,
        dx: dsUs[index].dx,
        dy: dsUs[index].dy,
        d: dsUs[index].d,
        unit: "mm"
      })),
      opt: psOpt.map((item, index) =>
      ({
        x1: item[0].x,
        y1: item[0].y,
        x2: item[1].x,
        y2: item[1].y,
        dx: dsOpt[index].dx,
        dy: dsOpt[index].dy,
        d: dsOpt[index].d,
        unit: "mm"
      })),
      vol: vol
    }),
  }
};

/**
 * Create us annotation body
 * @param {Array<Object>} p1 arr of points [{x, y}]
 * @param {Number} frame frame
 */
export const createUsAnnotationBody = (arrOfPoints, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.ANNOTATION,
    modality: TOOL_ACTION_MODALITY.US,
    data: JSON.stringify({ points: arrOfPoints }),
    frame,
    date: getISONow()
  }
};

/**
 * Create clinical annotation body
 * @param {Array<Object>} p1 arr of points [{x, y}]
 * @param {Number} frame frame
 */
export const createClinicalAnnotationBody = (arrOfPoints, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.ANNOTATION,
    modality: TOOL_ACTION_MODALITY.CLINICAL,
    data: JSON.stringify({ points: arrOfPoints }),
    frame,
    date: getISONow()
  }
};

/**
 * Create dermoscopy annotation body
 * @param {Array<Object>} p1 arr of points [{x, y}]
 * @param {Number} frame frame
 */
export const createDermoscopyAnnotationBody = (arrOfPoints, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.ANNOTATION,
    modality: TOOL_ACTION_MODALITY.DERMOSCOPY,
    data: JSON.stringify({ points: arrOfPoints }),
    frame,
    date: getISONow()
  }
};

/**
 * Create  annotation modify body
 * @param {Array<Object>} p1 arr of points [{x, y}]
 */
export const createAnnotationModifyBody = (arrOfPoints) => {
  return {
    data: JSON.stringify({ points: arrOfPoints }),
  }
};

/**
 * Create opt annotation body
 * @param {Array<Object>} p1 arr of points [{x, y}]
 * @param {Number} frame frame
 */
export const createOptAnnotationBody = (arrOfPoints, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.ANNOTATION,
    modality: TOOL_ACTION_MODALITY.OPT,
    data: JSON.stringify({ points: arrOfPoints }),
    frame,
    date: getISONow()
  }
};

/**
 * Create opt free text body
 * @param {String} text text
 * @param {Number} frame frame
 */
export const createOptFreeTextBody = (text, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.TEXT,
    modality: TOOL_ACTION_MODALITY.OPT,
    data: JSON.stringify({ text }),
    frame,
    date: getISONow()
  }
};
/**
 * Create us free text body
 * @param {String} text text
 * @param {Number} frame frame
 */
export const createUsFreeTextBody = (text, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.TEXT,
    modality: TOOL_ACTION_MODALITY.US,
    data: JSON.stringify({ text }),
    frame,
    date: getISONow()
  }
};
/**
 * Create clinical free text body
 * @param {String} text text
 * @param {Number} frame frame
 */
export const createClinicalFreeTextBody = (text, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.TEXT,
    modality: TOOL_ACTION_MODALITY.CLINICAL,
    data: JSON.stringify({ text }),
    frame,
    date: getISONow()
  }
};
/**
 * Create dermoscopy free text body
 * @param {String} text text
 * @param {Number} frame frame
 */
export const createDermocopyFreeTextBody = (text, frame = 0) => {
  return {
    tool_type: TOOL_ACTION_TYPE.TEXT,
    modality: TOOL_ACTION_MODALITY.DERMOSCOPY,
    data: JSON.stringify({ text }),
    frame,
    date: getISONow()
  }
};

/**
 * Create free text modifx body
 * @param {String} text text
 * @param {Number} id id
 */
export const createFreeTextModifyBody = (text) => {
  return {
    data: JSON.stringify({ text })
  }
};

/**
 * Update/Add calibration
 * @param {*} text 
 * @param {*} frame 
 * @returns 
 */
export const createCalibrationBody = ({
  serialNumber = null,
  fwVersions = null,
  eepromVersion = null,
  calibrationValues = null,
  raw = null,
  date = getISONow()
}) => {
  const body = {}
  if (serialNumber) {
    body["serial_number"] = serialNumber
  }
  if (eepromVersion) {
    body["eeprom_storing_version"] = eepromVersion
  }
  if (fwVersions) {
    body["fw_versions"] = JSON.stringify(fwVersions)
  }
  if (calibrationValues) {
    body["calibration_values"] = JSON.stringify(calibrationValues)
  }
  if (raw) {
    body["raw"] = raw
  }
  if (date) {
    body["date"] = date
  }
  return body
};

export const processCalibration = (element) => {
  return {
    id: element.id,
    date: element.date,
    user: {
      id: element?.user?.id,
      prefix: element?.user?.prefix,
      name: element?.user?.name,
      surname: element?.user?.surname,
      email: element?.user?.email
    },
    serialNumber: element.serial_number,
    eepromVersion: element.eeprom_storing_version,
    fw_versions: element.fw_versions,
    calibrationValues: element.calibration_values,
    raw: element.raw,
  }
}
/**
 * Create device body
 */
export const createDeviceBody = (
  serial_number,
  fw_versions,
) => ({
  serial_number,
  fw_versions: JSON.stringify(fw_versions)
})


/**
 * Create move records body
 */
export const createMoveRecordsBody = (
  ids,
  lesionId,
) => ({
  records_ids: JSON.stringify(ids),
  lesion_id: lesionId
})

/**
 * Create feedback body
 */
export const createFeedbackBody = (
  date,
  user,
  app_version,
  serial_number,
  firmware_version,
  operating_system,
  browser_version,
  url,
  description,
  condition,
  logs,
  mode,
  fileId,
) => ({
  date,
  user,
  app_version,
  serial_number,
  firmware_version,
  operating_system,
  browser_version,
  url,
  description,
  condition,
  file_id: fileId,
  logs,
  mode
})


export const processMetrics = (metrics) => {
  const data = { pointsToDraw: [] }
  const pointsToDraw = []
  data[SEGMENTATION_LAYER_ENUM[1]] = NA
  if (metrics?.epidermis_max) {
    if (Array.isArray(metrics?.epidermis_max)) {
      data[SEGMENTATION_LAYER_ENUM[2]] = [`${AVG_THICKNESS}: ${Math.max(...metrics?.epidermis_max)?.toFixed(2)}${MM}`].join(" | ")
    } else {
      data[SEGMENTATION_LAYER_ENUM[2]] = [`${AVG_THICKNESS}: ${metrics?.epidermis_max?.toFixed(2)}${MM}`].join(" | ")
    }
  } else {
    data[SEGMENTATION_LAYER_ENUM[2]] = NA
  }

  data[SEGMENTATION_LAYER_ENUM[4]] = NA
  data[SEGMENTATION_LAYER_ENUM[8]] = []
  if (metrics?.lesion_max) {

    if (Array.isArray(metrics?.lesion_max)) {
      const maxValue = Math.max(...metrics?.lesion_max)
      const index = metrics?.lesion_max?.indexOf(maxValue);
      data[SEGMENTATION_LAYER_ENUM[8]].push(`${MAX_THICKNESS}: ${maxValue?.toFixed(2)}${MM}`)

      if (metrics?.lesion_max_coord) {
        pointsToDraw.push({ p: metrics.lesion_max_coord[index][0], color: "#F7E243" })
        pointsToDraw.push({ p: metrics.lesion_max_coord[index][1], color: "#F7E243" })
      }
    } else {
      data[SEGMENTATION_LAYER_ENUM[8]].push(`${MAX_THICKNESS}: ${metrics?.lesion_max?.toFixed(2)}${MM}`)
      if (metrics?.lesion_max_coord) {
        pointsToDraw.push({ p: metrics.lesion_max_coord[0], color: "#F7E243" })
        pointsToDraw.push({ p: metrics.lesion_max_coord[1], color: "#F7E243" })
      }
    }
  }
  if (metrics?.diameter_max) {
    if (Array.isArray(metrics?.diameter_max)) {
      const maxValue = Math.max(...metrics?.diameter_max)
      const index = metrics?.diameter_max?.indexOf(maxValue);
      data[SEGMENTATION_LAYER_ENUM[8]].push(`${MAX_LENGTH}: ${maxValue?.toFixed(2)}${MM}`)
      if (metrics?.diameter_coord) {
        pointsToDraw.push({ p: metrics.diameter_coord[index][1], color: neutralwhite })
        pointsToDraw.push({ p: metrics.diameter_coord[index][0], color: neutralwhite })
      }
    } else {
      data[SEGMENTATION_LAYER_ENUM[8]].push(`${MAX_LENGTH}: ${metrics?.diameter_max?.toFixed(2)}${MM}`)
      if (metrics?.diameter_coord) {
        pointsToDraw.push({ p: metrics.diameter_coord[1], color: neutralwhite })
        pointsToDraw.push({ p: metrics.diameter_coord[0], color: neutralwhite })
      }
    }
  }

  if (metrics?.area) {
    if (Array.isArray(metrics?.area)) {
      data[SEGMENTATION_LAYER_ENUM[8]].push(`${SUM_AREA}: ${metrics?.area.reduce((a, c) => { return a + c }, 0)?.toFixed(2)}${MM}²`)
    } else {
      data[SEGMENTATION_LAYER_ENUM[8]].push(`${AREA}: ${metrics?.area?.toFixed(2)}${MM}²`)
    }
  }
  if (metrics?.classification) {
    const percentage = metrics?.classification?.predicted_percentage ? `${Math.floor(parseFloat(metrics?.classification?.predicted_percentage) * 100)?.toFixed()}% ` : ""
    data[SEGMENTATION_LAYER_ENUM[8]].push(`${CLASSIFICATION}: ${percentage}${metrics?.classification?.predicted_class}`)
  }
  data[SEGMENTATION_LAYER_ENUM[8]] = data[SEGMENTATION_LAYER_ENUM[8]].length > 0 ? data[SEGMENTATION_LAYER_ENUM[8]].join(" | ") : NA
  if (metrics?.dermis_max) {
    if (Array.isArray(metrics?.dermis_max)) {
      data[SEGMENTATION_LAYER_ENUM[16]] = [`${MAX_THICKNESS}: ${Math.max(...metrics?.dermis_max)?.toFixed(2)}${MM} `].join(" | ")
    } else {
      data[SEGMENTATION_LAYER_ENUM[16]] = [`${MAX_THICKNESS}: ${metrics?.dermis_max?.toFixed(2)}${MM} `].join(" | ")
    }
  } else {
    data[SEGMENTATION_LAYER_ENUM[16]] = NA
  }
  data[SEGMENTATION_LAYER_ENUM[32]] = NA
  data[SEGMENTATION_LAYER_ENUM[64]] = NA



  const draw = (ctx, activeLayer, croppingPercent, zoom) => {
    if (activeLayer === 8) {//LESION
      pointsToDraw.forEach(item => {
        const px = relativeToAbolutePointPosition({ x: item.p[0] / croppingPercent.x, y: item.p[1] / croppingPercent.y }, zoom, ctx.canvas)
        const config = { LINE_WIDTH: 2, CROSS_SIZE: 7, CROSS_COLOR: item.color };
        drawCross(px.x, px.y, ctx, config)
      })
    }
  }
  data.draw = draw
  return data
};
