class User {
  /**
   * Constructor
   */
  constructor(
    prefix,
    name,
    surname,
    specialization,
    email,
    company,
    city,
    country,
  ) {
    this.name = name;
    this.surname = surname;
    this.specialization = specialization;
    this.prefix = prefix;
    this.email = email;
    this.company = company;
    this.city = city;
    this.country = country;
  }
}
export default User;
