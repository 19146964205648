import { useCallback, useRef, useState } from "react";

const useStateRef = (defaultValue) => {
  const [state, setState] = useState(defaultValue);
  const ref = useRef(state);

  const dispatch = useCallback((val) => {
    ref.current = typeof val === "function" ? val(ref.current) : val;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};
export default useStateRef;
