import { DEFAULT_CONFIG, MODE } from "../../Imaging/useImage_v2";

class Measurement {
    /**
     * Constructor
     * @param {Object} measurement measurement
     */
    constructor(
        measurement,
    ) {
        this.id = measurement.id
        let len = 0;
        if (Array.isArray(measurement.data)) {
            this.points = measurement.data.map(item => [{ x: item.x1, y: item.y1 }, { x: item.x2, y: item.y2 }])
            this.distances = measurement.data.map(item => ({ dy: item.dy, dx: item.dx, d: item.d }))
            len = measurement.data.length
        } else {
            const item = measurement.data
            this.points = [[{ x: item.x1, y: item.y1 }, { x: item.x2, y: item.y2 }]]
            this.distances = [{ dy: item.dy, dx: item.dx, d: item.d }]
            len = 1
        }
        this.colors = {
            colors: DEFAULT_CONFIG[MODE.THICKNESS_MEASUREMENT].colors.toReversed().filter((item, ind) => ind < len),
            options: DEFAULT_CONFIG[MODE.THICKNESS_MEASUREMENT].colors.filter((item, ind) => ind < (DEFAULT_CONFIG[MODE.THICKNESS_MEASUREMENT].colors.length - len))
        }
        this.date = measurement.date
    }
}
export default Measurement;
