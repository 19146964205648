import { Box, CircularProgress, Typography } from "@mui/material";
import { neutral50 } from "../../../Themes/dermusTheme";

/**
 * PrimarySpinner
 * @param {Object} props {size: oneOf(["Small", "Medium", "Large"]), show: show or not (boolean)}
 */
const PrimarySpinner = (props) => {
    const { sx, show, ...restProps } = props
    return show !== false && <CircularProgress size={50} sx={{ color: neutral50, ...sx }} {...restProps} />
}


export const CircularProgressWithLabel = (props) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <PrimarySpinner {...props}
                sx={{
                    "& .MuiCircularProgress-circleDeterminate": {
                        transition: "none"
                    }
                }} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}
export default PrimarySpinner;
