import { ClientId } from "../UserPool";
import { isDemo, isDev, isTest, isTestDemo } from "../utility/functions";
let localEnv
try {
    localEnv = parseInt(process.env.REACT_APP_LOCAL) === 1
} catch (e) {
    localEnv = false
}
export const HOME_URL = "/";
export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const SUPPORT_URL = "/support";
export const FORGOT_PSW_URL = "/resetpassword";
export const SIGN_UP_URL = "/signup";
export const PRIVACY_POLICY_HASH = "#Privacy-Policy";
export const TERMS_AND_CONDITION_HASH = "#Terms-and-Condition";
export const USER_MANUAL_HASH = "#User-Manual";
export const ABOUT_URL = "/about";
export const SETTINGS_URL = "/settings";
export const USER_PREFERENCES_URL = "/userpreferences";
export const FEEDBACK_URL = "/feedback";
export const PATIENTS_URL = "/patients";
export const PATIENT_URL = "/patient";
export const LESION_URL = "/lesion";
export const RECORD_URL = "/record";
export const ADD_PATIENT_URL = "/addpatient";
export const EDIT_PATIENT_URL = "/editpatient";
export const ADD_LESION_URL = "/addlesion";
export const IMPORT_OPTOFUSE_URL = "/import-opto-fuse";
export const DERMUS_URL = "https://www.dermusvision.com/";
export const SKINAID_URL_END = localEnv ? ":3000" : "skinaid.app";
export const DEERMUS_APP_URL_END = localEnv ? ":3001" : "dermus.app";
export const SKINAID_URL = `https://www.${DEERMUS_APP_URL_END}/`;
export const SHARE_URL = "/share";
export const REPORT_URL = "/report";
export const SEGMENTATION_URL = "/segmentation";
export const CAPTURE_URL = "/capture";
export const FW_UPDATE_URL = "/fw-update";
export const DEMO_URL = "/demo";
export const CAPTURE__DEMO_URL = "/capture-demo";
export const CAPTURE_DEMO_URL = "/capturedemo";
export const CALIBRATION_URL = "/calibration";
export const USB_SETTINGS_URL = "/usb-settings";
export const VERIFY_EMAIL_URL = "/verify-email";
export const UPLOAD_TOKEN_URL = "/upload-token";
export const MANUAL_URL = "/manual"
export const FW_UPDATE_HASH = "fw-update";

export const DEMO_SKINAID_FULL_URL = "https://www.demo.skinaid.app";
export const DEMO_CAPTURE_FULL_URL = "https://www.demo.dermus.app";

export const CAPTURE_FULL_URL = localEnv ? `${window.location.protocol}//localhost:3001` : `https://${isTestDemo() ? "rc-demo." : isDemo() ? "www.demo." : (isTest() ? "rc." : isDev() ? "dev." : "www.")}${DEERMUS_APP_URL_END}`
export const SKINAID_FULL_URL = localEnv ? `${window.location.protocol}//localhost:3000` : `https://${isTestDemo() ? "rc-demo." : isDemo() ? "www.demo." : (isTest() ? "rc." : isDev() ? "dev." : "www.")}${SKINAID_URL_END}`
export const SSO_FULL_URL = "https://auth.skinaid.app"
export const SSO_LOGIN_FULL_URL = `${SSO_FULL_URL}/oauth2/authorize?client_id=${ClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=${encodeURIComponent(`${window.location.origin}${LOGIN_URL}`)}`
export const SSO_LOGOUT_FULL_URL = `${SSO_FULL_URL}${LOGOUT_URL}?client_id=${ClientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&logout_uri=${encodeURIComponent(`${window.location.origin}${LOGIN_URL}`)}`
export const SSO_TOKEN_FULL_URL = `${SSO_FULL_URL}/oauth2/token`
export const MANUAL_FULL_URL = "https://dermus.atlassian.net/wiki/spaces/DOC/overview"

