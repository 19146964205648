import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

import * as action from "../store/actionCreators/authenticationActionCreator";
import Pool from "../UserPool";

import { LOGOUT_EP, SIGN_UP_EP, LOGIN_EP, FORGOT_PSW_EP } from "./endPoints";
import qs from "qs";

import {
  ADD_VALID_PSW,
  ADD_VALID_EMAIL,
} from "../../../dermuscomponents/src/Constants/Message";

import { clearStorage, storeItem } from "./localStorageAPI";
import { USER_DATA_SIGNED_IN_LOCAL_STORAGE, USER_SIGNED_IN_LOCAL_STORAGE } from "../Constants/Constants";
import dermusAxios from "./dermusAxios";
import {
  createSignUpBody,
  createForgotPswBody,
  createConfirmPswBody,
} from "../../../dermuscomponents/src/API/dataRestructure";
import { THIS_EMAIL_USED_ALREADY } from "../Constants/Message";
import { isEmail } from "../utility/utility";
import { isDemo, isSkinAid } from "../utility/functions";
import { CAPTURE_FULL_URL, LOGOUT_URL, SKINAID_FULL_URL } from "../Constants/Links";



export const CodeUserNotFoundException = "UserNotFoundException"
export const CodeNotAuthorizedException = "NotAuthorizedException"
export const CodeMismatchException = "CodeMismatchException"
export const LimitExceededException = "LimitExceededException"
export const NetworkError = "NetworkError"
export const NotValidEmail = "NotValidEmail"
export const UsernameExistsException = "UsernameExistsException"
export const WrongVerificaitonLink = "Wrong verification link"
export const AlreadyVerified = "Already verified"
export const CodeUserNotConfirmedException = "UserNotConfirmedException"
export const InvalidLink = "Link is invalid"
/**
 * Get user session
 * @param {Function} dispatch - dispatch to dispatch to redux
 * @param {Function} callback - callback on valid session
 */
export const checkUserValidity = (dispatch, callback) => {
  const user = Pool.getCurrentUser();
  if (user) {
    user.getSession((err, session) => {
      if (err) {
        dispatch(action.getSessionErrorActionCreator());
        logout(dispatch, session);
      } else {
        if (session.isValid()) {
          dispatch(action.setSessionActionCreator(session));
          if (callback) {
            callback();
          }
        } else {
          logout(dispatch, session);
        }
      }
    });
  } else {
    dispatch(action.noUserActionCreator());
  }
};
/**
 * Login
 * @param {Object} username - username
 * @param {Object} password - password
 * @param {Function} dispatch - dispatch to dispatch to redux
 */
export const login = (username, password, dispatch) => {

  if (!isEmail(username)) {
    dispatch(action.loginFailedActionCreator({ message: ADD_VALID_EMAIL, code: CodeUserNotFoundException }));
    return
  }
  if (password.length < 1) {
    dispatch(action.loginFailedActionCreator({ message: ADD_VALID_PSW, code: CodeNotAuthorizedException }));
    return
  }
  if (dispatch) {
    dispatch(action.loginStartActionCreator());
  }
  const user = new CognitoUser({ Username: username, Pool: Pool });
  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  user.authenticateUser(authDetails, {
    onSuccess: (session) => {
      dermusAxios.put(
        //logging
        LOGIN_EP,
        {},
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            Authorization: session.getAccessToken().jwtToken,
          },
        }
      ).then(res => {
        storeItem(USER_DATA_SIGNED_IN_LOCAL_STORAGE, JSON.stringify({
          city: res.data.city, company: res.data.company, country: res.data.country, id: res.data.id,
          name: res.data.name,
          prefix: res.data.prefix,
          surname: res.data.surname,
          specialization: res.data.specialization
        }));
        //dispatch(action.addUserPreferencesActionCreator(res.data.user_preferences))
        dispatch(action.addUserPreferencesActionCreator(res.data.user_preferences))
      })
      storeItem(USER_SIGNED_IN_LOCAL_STORAGE, username);
      if (dispatch) {
        dispatch(
          action.loginSuccessActionCreator(
            session,
            password,
            username
          )
        );
      }
    },

    onFailure: (err) => {
      clearStorage();
      if (dispatch) {
        dispatch(action.loginFailedActionCreator(err));
      }
    },
  });
};

/**
 * Logout user
 * @param {Function} dispatch - dispatch to dispatch to redux
 * @param {CognitoUserSession} session - cognito session
 */
export const logout = (dispatch, session, redirect = true, clear = false, deleteSession = false) => {
  if (dispatch && !isDemo() && deleteSession) {
    dispatch(action.logoutActionCreator());
  }
  const user = Pool.getCurrentUser();
  if (user) {
    if (session) {
      dermusAxios.put(
        //logging
        LOGOUT_EP,
        {},
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            Authorization: session.getAccessToken().jwtToken,
          },
        }
      );
    }
    user.globalSignOut({ onSuccess: (e) => { console.log(e) }, onFailure: (e) => { console.log(e) } })
    //user.signOut();
  }
  //window.location.href = SSO_LOGOUT_FULL_URL;
  if (clear) {
    clearStorage();
  }
  if (redirect) {
    //  window.location.href = LOGOUT_URL

    window.location.href = (!isSkinAid() ? CAPTURE_FULL_URL : SKINAID_FULL_URL) + LOGOUT_URL
  }

};

/**
 * Sign up new user
 * @param {Object} name - name (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} affiliation - affiliation (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} spec - spec (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} mprn - medical practitioner reg num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} email - email (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} emailConf - emailConf (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} psw - psw (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} pswConf - pswConf (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} isCompany - medical practitioner reg num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyName - company name(object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyAddress - company address (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyVAT - company vat num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} address - user adrres (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} isTermsAndCond - muser agreement agree (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} isPrivacyPolicy - data policy agree (object: valid - boolean, touched - boolean, value: string)
 * @param {Function} dispatch - dispatch to dispatch to redux
 */
export const signupValidation = (
  email,
  dispatch
) => {
  if (!isEmail(email)) {
    dispatch(action.signupFailedActionCreator({ message: ADD_VALID_EMAIL, code: NotValidEmail }));
    return false;
  }
  return true;
};

/**
 * Sign up new user
 * @param {Object} name - name (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} affiliation - affiliation (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} spec - spec (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} mprn - medical practitioner reg num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} email - email (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} psw - psw (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} isCompany - medical practitioner reg num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyName - company name(object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyAddress - company address (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} companyVAT - company vat num (object: valid - boolean, touched - boolean, value: string)
 * @param {Object} address - user adrres (object: valid - boolean, touched - boolean, value: string)
 ** @param {Function} dispatch - dispatch to dispatch to redux
 */
export const signup = (
  name,
  prefix,
  surname,
  specialization,
  email,
  password,
  company,
  country,
  city,
  dispatch
) => {
  if (dispatch) {
    dispatch(action.singupStartActionCreator());
  }

  dermusAxios
    .post(
      SIGN_UP_EP,
      qs.stringify(
        createSignUpBody(
          name,
          prefix,
          surname,
          specialization,
          email,
          password,
          company,
          country,
          city
        )
      ),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((d) => dispatch(action.signupSuccessActionCreator(d.data)))
    .catch((e) => {
      if (e.response && e.response.data) {
        const err = e?.response?.data?.message.includes(UsernameExistsException) ?
          { message: THIS_EMAIL_USED_ALREADY, code: UsernameExistsException } : { message: NetworkError, code: NetworkError }
        dispatch(action.signupFailedActionCreator(err));
        return;
      }
      dispatch(action.signupFailedActionCreator(e));
    });
};

/**
 * Reset password
 * @param {Object} email email (object: valid - boolean, touched - boolean, value: string)
 * @param {Function} dispatch dispatch to dispatch to redux
 */
export const resetPassword = (email, dispatch) => {
  if (!isEmail(email)) {
    dispatch(action.resetPswFailedActionCreator({ message: ADD_VALID_EMAIL, code: CodeUserNotFoundException }));
    return;
  }
  dispatch(action.resetPswStartActionCreator());

  dermusAxios
    .post(FORGOT_PSW_EP, qs.stringify(createForgotPswBody(email)))
    .then(() => dispatch(action.resetPwsConfirm()))
    .catch((e) => {
      dispatch(action.resetPswFailedActionCreator({
        code: e?.response?.data?.message.includes(LimitExceededException) ? LimitExceededException : e?.response?.data?.message.includes(CodeUserNotFoundException) ? CodeUserNotFoundException : NetworkError
      }));
    });
};

/**
 * Confirm password (after reset pws)
 * @param {Object} email email 
 * @param {Object} verificationCode verification code 
 * @param {Object} newPassword new password 
 * @param {Function} dispatch  dispatch to dispatch to redux
 * @param {Function} onSuccess function to call on success
 */
export const confirmPassword = (
  email,
  verificationCode,
  newPassword,
  dispatch,
  onSuccess
) => {
  dispatch(action.resetPswStartActionCreator());

  dermusAxios
    .put(
      FORGOT_PSW_EP,
      qs.stringify(
        createConfirmPswBody(
          email,
          verificationCode,
          newPassword
        )
      )
    )
    .then(() => {
      dispatch(action.clearAuthStateActionCreator());
      onSuccess();
    })
    .catch((e) => {
      dispatch(action.resetPswFailedActionCreator({ code: e.response?.data?.message?.includes(CodeMismatchException) ? CodeMismatchException : NetworkError }));
    });
};

