import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import authenticationReducer from "./reducers/authenticationReducer";
import patientManagementReducer from "./reducers/patientManagementReducer";

const reducer = combineReducers({
  auth: authenticationReducer,
  patMan: patientManagementReducer,
});

/**
 * Create own store
 * @returns store
 */
const createOwnStore = () => {
  const store = createStore(reducer, applyMiddleware(thunk));
  return store;
};

export default createOwnStore;
