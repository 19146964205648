import { ClickAwayListener, FormControlLabel, Grow, Paper, Popper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { neutral30, neutralblack, neutralwhite } from "../../../../dermuscomponents/Themes/dermusTheme";
import { DIAGNOSES, FAVOURITES, FROM, MULTI_LINE_DOPPLER, NR_PREV_VISITS, SET_AS_FAVOURITE, SINGLE_LINE_DOPPLER, UNTIL } from "../../../../dermuscomponents/src/Constants/Message";
import { BIRTH_YEAR, DIAGNOSIS_ORIGIN, DIAGNOSIS_ORIGIN_EXTRA_SHORT_LABEL, EXAM_DATE, EXTRA_MODALITY, EXTRA_MODALITY_LABEL, FEMALE, FILTERS_LABEL, FILTERS_USED, LAST_EXAM_DATE, MALE, MULTI_FRAME, OTHER, RECORDING_TYPE, SEX, SINGLE_FRAME, TYPE_OF_DIAGNOSIS } from "../../../../dermuscomponents/src/Constants/Message";
import { Fragment, useState, useEffect } from "react";
import { AgenderIcon, FemaleIcon, MaleIcon } from "../../../../dermuscomponents/src/UI/Icons/Icons";
import PrimaryCheckbox from "../../../../dermuscomponents/src/UI/Checkbox/PrimaryCheckbox";
import ICD11Search from "../../../../dermuscomponents/src/Components/ICD/ICD11Search";
import CustomDatePicker from "../Datepicker/CustomDatePicker";

import { FILTERS, LEVELS, RECORD_TYPE } from "../../../../dermuscomponents/src/Constants/Constants";
import useElementSize from "../../hooks/useElementSize";
import { useDispatch } from "react-redux";
import { setPatientsFilterActionCreator, setPatientFilterActionCreator, setLesionFilterActionCreator } from "../../store/actionCreators/patientManagementActionCreator";
import { getDiagnosisType, getExtraModality, getNumOfVisits, getRecordType, getSex, getUsedFilters } from "../../utility/utility";

const FILTER_WIDTH = 300

export const MODALITY_FILTER = {
    NONE: "NONE",
    CLINICAL: "CLINICAL",
    DERMOSCOPY: "DERMOSCOPY"
}

export const NUM_OF_VISIT_FILTER = {
    ONE: "ONE",
    MORE: "MORE"
}

export const SEX_FILTER = {
    MALE: "MALE",
    FEMALE: "FEMALE",
    OTHER: "OTHER"
}

export const FILTERS_FILTER = {
    NOTE: "NOTE",
    THICKNESS: "THICKNESS",
    ANNOTATION: "ANNOTATION"
}

const FONT_SIZE = 11
const ICON_SIZE = 20
const SMALL_MARGIN = 8
const LARGE_MARGIN = 20

const INIT_TYPE_OF_DIAGNOSIS = {}
INIT_TYPE_OF_DIAGNOSIS[DIAGNOSIS_ORIGIN.finalClinicalDiagnosis] = false
INIT_TYPE_OF_DIAGNOSIS[DIAGNOSIS_ORIGIN.histologicalDiagnosis] = false
INIT_TYPE_OF_DIAGNOSIS[DIAGNOSIS_ORIGIN.preliminaryClinicalDiagnosis] = false

const INIT_MODLAITY = {}
INIT_MODLAITY[MODALITY_FILTER.CLINICAL] = false
INIT_MODLAITY[MODALITY_FILTER.DERMOSCOPY] = false
INIT_MODLAITY[MODALITY_FILTER.NONE] = false

const INIT_SEX = {}
INIT_SEX[SEX_FILTER.FEMALE] = false
INIT_SEX[SEX_FILTER.MALE] = false
INIT_SEX[SEX_FILTER.OTHER] = false

const INIT_NUM_VISITS = {}
INIT_NUM_VISITS[NUM_OF_VISIT_FILTER.MORE] = false
INIT_NUM_VISITS[NUM_OF_VISIT_FILTER.ONE] = false


const INIT_RECORD_TYPE = {}
INIT_RECORD_TYPE[RECORD_TYPE.US_OPT_CINELOOP] = false
INIT_RECORD_TYPE[RECORD_TYPE.US_OPT_FRAMEPAIR] = false
INIT_RECORD_TYPE[RECORD_TYPE.LINE_DOPPLER] = false
INIT_RECORD_TYPE[RECORD_TYPE.LINE_DOPPLER_CINELOOP] = false

const INIT_USED_FILTERS = {}
INIT_USED_FILTERS[FILTERS_FILTER.NOTE] = false
INIT_USED_FILTERS[FILTERS_FILTER.THICKNESS] = false
INIT_USED_FILTERS[FILTERS_FILTER.ANNOTATION] = false

const INIT_SET_FAVOURITE = false

const useCustomFilter = ({ config = {}, level = null, filterInfo = null, lastExam = true }) => {

    //Dispatch
    const dispatch = useDispatch();
    //Search is active
    const [searchIsActive, setSearchIsActive] = useState(false)
    //Exam date
    const [examDate, setExamDate] = useState({ start: filterInfo?.exam_date_start ?? null, end: filterInfo?.exam_date_end ?? null })
    //Birthyear 
    const [birthyear, setBirthyear] = useState({ start: filterInfo?.birth_year_start ?? null, end: filterInfo?.birth_year_end ?? null })
    //Selected diagnosis
    const [selectedDiagnosis, setSelectedDiagnosis] = useState(filterInfo?.selected_diagnosis ?? null)
    //Query string
    const [query, setQuery] = useState(filterInfo?.selected_diagnosis?.title ?? "");
    //Num of visits
    const [numVisits, setNumVisits] = useState(getNumOfVisits(filterInfo))
    //Type of diagnosis
    const [sex, setSex] = useState(getSex(filterInfo))
    //Type of diagnosis
    const [typeOfDiagnosis, setTypeofDiangosis] = useState(getDiagnosisType(filterInfo))
    //Extra modality
    const [extraModality, setExtraModality] = useState(getExtraModality(filterInfo))
    //Record type
    const [recordType, setRecordType] = useState(getRecordType(filterInfo))
    //Filters used
    const [usedFilters, setUSedFilters] = useState(getUsedFilters(filterInfo))
    //Set as favourite
    const [favourites, setFavourites] = useState(filterInfo?.favourites ?? false)
    //Box ref
    const [boxRef, boxSize] = useElementSize()

    /**
     * Dispatch certain filter on certain level
     * @param {*} filter 
     * @param {*} level 
     */
    const dispatchLevel = (filter, level) => {
        switch (level) {
            case LEVELS.PATIENTS:
                dispatch(setPatientsFilterActionCreator(filter));
                break;
            case LEVELS.PATIENT:
                dispatch(setPatientFilterActionCreator(filter));
                break;
            case LEVELS.LESION:
                dispatch(setLesionFilterActionCreator(filter));
                break;
            default:
                dispatch(setPatientsFilterActionCreator(filter));
                dispatch(setPatientFilterActionCreator(filter));
                dispatch(setLesionFilterActionCreator(filter));
        }
    }

    //Clear filters
    const clearFilters = () => {
        //For current level
        setExamDate({ start: null, end: null })
        setBirthyear({ start: null, end: null })
        setSelectedDiagnosis(null)
        setQuery("")
        setNumVisits(INIT_NUM_VISITS)
        setSex(INIT_SEX)
        setTypeofDiangosis(INIT_TYPE_OF_DIAGNOSIS)
        setExtraModality(INIT_MODLAITY)
        setRecordType(INIT_RECORD_TYPE)
        setUSedFilters(INIT_USED_FILTERS)
        setFavourites(INIT_SET_FAVOURITE)

        //For favourite - all level
        const favFilter = {}
        favFilter[FILTERS.FAVOURITES] = INIT_SET_FAVOURITE
        dispatchLevel(favFilter, LEVELS.ALL)

        //Other filters - only certain level
        const filters = null;
        dispatchLevel(filters, level)
    }

    useEffect(() => {
        if (examDate.start || examDate.end) {
            setSearchIsActive(true)
            return
        }
        if (birthyear.start || birthyear.end) {
            setSearchIsActive(true)
            return
        }
        if (selectedDiagnosis) {
            setSearchIsActive(true)
            return
        }
        if (numVisits[NUM_OF_VISIT_FILTER.MORE] !== INIT_NUM_VISITS[NUM_OF_VISIT_FILTER.MORE] ||
            numVisits[NUM_OF_VISIT_FILTER.ONE] !== INIT_NUM_VISITS[NUM_OF_VISIT_FILTER.ONE]) {
            setSearchIsActive(true)
            return
        }
        if (sex[SEX_FILTER.FEMALE] !== INIT_SEX[SEX_FILTER.FEMALE] ||
            sex[SEX_FILTER.MALE] !== INIT_SEX[SEX_FILTER.MALE] ||
            sex[SEX_FILTER.OTHER] !== INIT_SEX[SEX_FILTER.OTHER]) {
            setSearchIsActive(true)
            return
        }
        if (typeOfDiagnosis[DIAGNOSIS_ORIGIN.finalClinicalDiagnosis] !== INIT_TYPE_OF_DIAGNOSIS[DIAGNOSIS_ORIGIN.finalClinicalDiagnosis] ||
            typeOfDiagnosis[DIAGNOSIS_ORIGIN.histologicalDiagnosis] !== INIT_TYPE_OF_DIAGNOSIS[DIAGNOSIS_ORIGIN.histologicalDiagnosis] ||
            typeOfDiagnosis[DIAGNOSIS_ORIGIN.preliminaryClinicalDiagnosis] !== INIT_TYPE_OF_DIAGNOSIS[DIAGNOSIS_ORIGIN.preliminaryClinicalDiagnosis]) {
            setSearchIsActive(true)
            return
        }
        if (extraModality[MODALITY_FILTER.CLINICAL] !== INIT_MODLAITY[MODALITY_FILTER.CLINICAL] ||
            extraModality[MODALITY_FILTER.DERMOSCOPY] !== INIT_MODLAITY[MODALITY_FILTER.DERMOSCOPY] ||
            extraModality[MODALITY_FILTER.NONE] !== INIT_MODLAITY[MODALITY_FILTER.NONE]
        ) {
            setSearchIsActive(true)
            return
        }
        if (recordType[RECORD_TYPE.US_OPT_CINELOOP] !== INIT_RECORD_TYPE[RECORD_TYPE.US_OPT_CINELOOP] ||
            recordType[RECORD_TYPE.US_OPT_FRAMEPAIR] !== INIT_RECORD_TYPE[RECORD_TYPE.US_OPT_FRAMEPAIR] ||
            recordType[RECORD_TYPE.LINE_DOPPLER] !== INIT_RECORD_TYPE[RECORD_TYPE.LINE_DOPPLER] ||
            recordType[RECORD_TYPE.LINE_DOPPLER_CINELOOP] !== INIT_RECORD_TYPE[RECORD_TYPE.LINE_DOPPLER_CINELOOP]) {
            setSearchIsActive(true)
            return
        }
        if (usedFilters[FILTERS_FILTER.NOTE] !== INIT_USED_FILTERS[FILTERS_FILTER.NOTE] ||
            usedFilters[FILTERS_FILTER.THICKNESS] !== INIT_USED_FILTERS[FILTERS_FILTER.THICKNESS] ||
            usedFilters[FILTERS_FILTER.ANNOTATION] !== INIT_USED_FILTERS[FILTERS_FILTER.ANNOTATION]) {
            setSearchIsActive(true)
            return
        }
        if (favourites !== INIT_SET_FAVOURITE) {
            setSearchIsActive(true)
            return
        }
        setSearchIsActive(false)
        return


    }, [examDate, birthyear, selectedDiagnosis, numVisits, sex, typeOfDiagnosis, extraModality, recordType, usedFilters, favourites])
    //Init quersy with prev diagnosis
    useEffect(() => {
        if (selectedDiagnosis !== null) {
            setQuery(selectedDiagnosis.title)
        }// eslint-disable-next-line
    }, [selectedDiagnosis])

    //Delete diagnosis when query is empty
    useEffect(() => {
        if (!query) {
            setSelectedDiagnosis(null)
        }
    }, [query])

    return {
        filterUI: ({ displayFilterComponent, anchorFilter, handleCloseFilter }) =>

            <Popper
                open={displayFilterComponent}
                anchorEl={anchorFilter}
                role={undefined}
                placement={'bottom-end'}
                transition
                disablePortal
                sx={{ zIndex: (theme) => theme.zIndex.drawer }}
            >
                {({ TransitionProps, placement }) => (

                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'top right',
                        }}                    >
                        <Paper sx={{
                            color: neutralblack,
                            backgroundColor: neutralwhite,
                            width: `${FILTER_WIDTH}px`,
                            height: `${boxSize.height}px`,
                            borderRadius: "6px",
                            marginTop: `${SMALL_MARGIN}px`,
                            boxShadow: "3px 4px 13px 6px #DADADA",
                        }}>
                            <ClickAwayListener onClickAway={handleCloseFilter}>
                                <Box ref={boxRef} sx={{
                                    padding: `${SMALL_MARGIN}px`,
                                }}>
                                    {/* EXAM DATE */}
                                    {config.examDate && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px`, marginBottom: "2px" }}>{lastExam ? LAST_EXAM_DATE : EXAM_DATE}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>

                                            <CustomDatePicker
                                                maxDate={examDate.end}
                                                value={examDate.start}
                                                onChange={(newValue) => {
                                                    setExamDate(s => { const newS = { ...s }; newS.start = newValue; return newS })
                                                    dispatchLevel({ [FILTERS.EXAM_DATE_START]: newValue }, level)

                                                }}
                                                label={FROM} />

                                            <CustomDatePicker
                                                minDate={examDate.start}
                                                value={examDate.end}
                                                onChange={(newValue) => {
                                                    setExamDate(s => { const newS = { ...s }; newS.end = newValue; return newS })
                                                    dispatchLevel({ [FILTERS.EXAM_DATE_END]: newValue }, level)
                                                }}
                                                label={UNTIL} />
                                        </Box>
                                    </Fragment>
                                    }

                                    {/* DIAGNOSIS */}
                                    {config.diagnosis && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px` }}>{DIAGNOSES}</Typography>

                                        <ICD11Search
                                            onChooseDiagnosis={(selectedEntity) => {
                                                setSelectedDiagnosis(selectedEntity)
                                                dispatchLevel({ [FILTERS.SELECTED_DIAGNOSIS]: selectedEntity }, level)
                                            }}
                                            setQuery={setQuery}
                                            query={query}
                                            zIndex={(theme) => theme.zIndex.drawer} />
                                    </Fragment>}


                                    {/* NR PREV VISIT */}
                                    {config.numOfVisits && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px` }}>{NR_PREV_VISITS}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", paddingLeft: `${SMALL_MARGIN}px` }}>



                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", marginRight: `${2 * LARGE_MARGIN}px` }}
                                                control={
                                                    <PrimaryCheckbox checked={numVisits[NUM_OF_VISIT_FILTER.ONE]}
                                                        onChange={(event) => {
                                                            setNumVisits(s => {
                                                                const newS = { ...s };
                                                                newS[NUM_OF_VISIT_FILTER.ONE] = !newS[NUM_OF_VISIT_FILTER.ONE];
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.NUM_VISITS.NUM_VISITS_ONE]: event.target.checked }, level)
                                                        }}
                                                        name="1" />
                                                }
                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>1</Typography>}

                                            />
                                            <FormControlLabel
                                                control={
                                                    <PrimaryCheckbox checked={numVisits[NUM_OF_VISIT_FILTER.MORE]}
                                                        onChange={(event) => {
                                                            setNumVisits(s => {
                                                                const newS = { ...s };
                                                                newS[NUM_OF_VISIT_FILTER.MORE] = !newS[NUM_OF_VISIT_FILTER.MORE];
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.NUM_VISITS.NUM_VISITS_MORE]: event.target.checked }, level)
                                                        }}

                                                        name="1+" />
                                                }
                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>1+</Typography>}

                                            />
                                        </Box>
                                    </Fragment>}
                                    {/*BIRTH YEAR*/}
                                    {config.birthYear && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px`, marginBottom: "2px" }}>{BIRTH_YEAR}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>

                                            <CustomDatePicker
                                                maxDate={birthyear.end}
                                                onlyYear={true}
                                                value={birthyear.start}
                                                onChange={(newValue) => {
                                                    setBirthyear(s => { const newS = { ...s }; newS.start = newValue; return newS })
                                                    dispatchLevel({ [FILTERS.BIRTH_YEAR_START]: newValue }, level)
                                                }}
                                                label={FROM} />


                                            <CustomDatePicker
                                                minDate={birthyear.start}
                                                onlyYear={true}
                                                value={birthyear.end}
                                                onChange={(newValue) => {
                                                    setBirthyear(s => { const newS = { ...s }; newS.end = newValue; return newS })
                                                    dispatchLevel({ [FILTERS.BIRTH_YEAR_END]: newValue }, level)
                                                }}
                                                label={UNTIL} />
                                        </Box>
                                    </Fragment>}

                                    {/* SEX */}
                                    {config.sex && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px` }}>{SEX}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", paddingLeft: `${SMALL_MARGIN}px` }}>

                                            <PrimaryCheckbox
                                                checked={sex[SEX_FILTER.FEMALE]}
                                                onChange={(event) => {
                                                    setSex(s => { const newS = { ...s }; newS[SEX_FILTER.FEMALE] = !newS[SEX_FILTER.FEMALE]; return newS })
                                                    dispatchLevel({ [FILTERS.SEX.FEMALE]: event.target.checked }, level)
                                                }}
                                                name={FEMALE} />
                                            <FemaleIcon sx={{ color: neutral30, width: `${ICON_SIZE}px`, height: `${ICON_SIZE}px`, marginRight: `${LARGE_MARGIN}px` }} />

                                            <PrimaryCheckbox checked={sex[SEX_FILTER.MALE]}
                                                onChange={(event) => {
                                                    setSex(s => { const newS = { ...s }; newS[SEX_FILTER.MALE] = !newS[SEX_FILTER.MALE]; return newS })
                                                    dispatchLevel({ [FILTERS.SEX.MALE]: event.target.checked }, level)
                                                }}
                                                name={MALE} />
                                            <MaleIcon sx={{ color: neutral30, width: `${ICON_SIZE}px`, height: `${ICON_SIZE}px`, marginRight: `${LARGE_MARGIN}px` }} />

                                            <PrimaryCheckbox checked={sex[SEX_FILTER.OTHER]}
                                                onChange={(event) => {
                                                    setSex(s => { const newS = { ...s }; newS[SEX_FILTER.OTHER] = !newS[SEX_FILTER.OTHER]; return newS })
                                                    dispatchLevel({ [FILTERS.SEX.OTHER]: event.target.checked }, level)
                                                }}
                                                name={OTHER} />
                                            < AgenderIcon sx={{ color: neutral30, width: `${ICON_SIZE}px`, height: `${ICON_SIZE}px`, marginRight: `${LARGE_MARGIN}px` }} />
                                        </Box>
                                    </Fragment>}


                                    {/* DIAGNOSIS TYPE */}
                                    {config.diagnosisType && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px` }}>{TYPE_OF_DIAGNOSIS}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={typeOfDiagnosis[DIAGNOSIS_ORIGIN.preliminaryClinicalDiagnosis]}
                                                        onChange={(event) => {
                                                            setTypeofDiangosis(s => {
                                                                const newS = { ...s };
                                                                newS[DIAGNOSIS_ORIGIN.preliminaryClinicalDiagnosis] = !newS[DIAGNOSIS_ORIGIN.preliminaryClinicalDiagnosis]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.DIAGNOSIS_TYPE.PRELIMINARY_CLINICAL]: event.target.checked }, level)
                                                        }}
                                                        name={DIAGNOSIS_ORIGIN_EXTRA_SHORT_LABEL.preliminaryClinicalDiagnosis} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{DIAGNOSIS_ORIGIN_EXTRA_SHORT_LABEL.preliminaryClinicalDiagnosis}</Typography>}
                                            />

                                            <FormControlLabel
                                                sx={{ marginLeft: "0px" }}
                                                control={
                                                    <PrimaryCheckbox checked={typeOfDiagnosis[DIAGNOSIS_ORIGIN.finalClinicalDiagnosis]}
                                                        onChange={(event) => {
                                                            setTypeofDiangosis(s => {
                                                                const newS = { ...s };
                                                                newS[DIAGNOSIS_ORIGIN.finalClinicalDiagnosis] = !newS[DIAGNOSIS_ORIGIN.finalClinicalDiagnosis]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.DIAGNOSIS_TYPE.FINAL_CLINICAL]: event.target.checked }, level)
                                                        }}
                                                        name={DIAGNOSIS_ORIGIN_EXTRA_SHORT_LABEL.finalClinicalDiagnosis} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{DIAGNOSIS_ORIGIN_EXTRA_SHORT_LABEL.finalClinicalDiagnosis}</Typography>}
                                            />
                                            <FormControlLabel
                                                sx={{ marginLeft: "0px" }}
                                                control={
                                                    <PrimaryCheckbox checked={typeOfDiagnosis[DIAGNOSIS_ORIGIN.histologicalDiagnosis]}
                                                        onChange={(event) => {
                                                            setTypeofDiangosis(s => {
                                                                const newS = { ...s };
                                                                newS[DIAGNOSIS_ORIGIN.histologicalDiagnosis] = !newS[DIAGNOSIS_ORIGIN.histologicalDiagnosis]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.DIAGNOSIS_TYPE.HISTOLOGICAL]: event.target.checked }, level)
                                                        }}
                                                        name={DIAGNOSIS_ORIGIN_EXTRA_SHORT_LABEL.histologicalDiagnosis} />
                                                }
                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{DIAGNOSIS_ORIGIN_EXTRA_SHORT_LABEL.histologicalDiagnosis}</Typography>}


                                            />

                                        </Box>
                                    </Fragment>}

                                    {/* EXTRA MODALITY */}
                                    {config.extraModality && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px` }}>{EXTRA_MODALITY}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={extraModality[MODALITY_FILTER.NONE]}
                                                        onChange={(event) => {
                                                            setExtraModality(s => {
                                                                const newS = { ...s };
                                                                newS[MODALITY_FILTER.NONE] = !newS[MODALITY_FILTER.NONE]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.EXTRA_MODALITY.NONE]: event.target.checked }, level)
                                                        }}
                                                        name={EXTRA_MODALITY_LABEL.NONE} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{EXTRA_MODALITY_LABEL.NONE}</Typography>}
                                            />
                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={extraModality[MODALITY_FILTER.CLINICAL]}
                                                        onChange={(event) => {
                                                            setExtraModality(s => {
                                                                const newS = { ...s };
                                                                newS[MODALITY_FILTER.CLINICAL] = !newS[MODALITY_FILTER.CLINICAL]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.EXTRA_MODALITY.CLINICAL]: event.target.checked }, level)
                                                        }}
                                                        name={EXTRA_MODALITY_LABEL.CLINICAL} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{EXTRA_MODALITY_LABEL.CLINICAL}</Typography>}
                                            />
                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={extraModality[MODALITY_FILTER.DERMOSCOPY]}
                                                        onChange={(event) => {
                                                            setExtraModality(s => {
                                                                const newS = { ...s };
                                                                newS[MODALITY_FILTER.DERMOSCOPY] = !newS[MODALITY_FILTER.DERMOSCOPY]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.EXTRA_MODALITY.DERMOSCOPY]: event.target.checked }, level)
                                                        }}
                                                        name={EXTRA_MODALITY_LABEL.DERMOSCOPY} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{EXTRA_MODALITY_LABEL.DERMOSCOPY}</Typography>}
                                            />

                                        </Box>
                                    </Fragment>}

                                    {/* RECORD TYPE */}
                                    {config.recordType && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px` }}>{RECORDING_TYPE}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={recordType[RECORD_TYPE.US_OPT_FRAMEPAIR]}
                                                        onChange={(event) => {
                                                            setRecordType(s => {
                                                                const newS = { ...s };
                                                                newS[RECORD_TYPE.US_OPT_FRAMEPAIR] = !newS[RECORD_TYPE.US_OPT_FRAMEPAIR]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.RECORD_TYPE.US_OPT_FRAMEPAIR]: event.target.checked }, level)
                                                        }}
                                                        name={SINGLE_FRAME} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{SINGLE_FRAME}</Typography>}
                                            />
                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={recordType[RECORD_TYPE.US_OPT_CINELOOP]}
                                                        onChange={(event) => {
                                                            setRecordType(s => {
                                                                const newS = { ...s };
                                                                newS[RECORD_TYPE.US_OPT_CINELOOP] = !newS[RECORD_TYPE.US_OPT_CINELOOP]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.RECORD_TYPE.US_OPT_CINELOOP]: event.target.checked }, level)
                                                        }}
                                                        name={SINGLE_FRAME} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{MULTI_FRAME}</Typography>}
                                            />




                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={recordType[RECORD_TYPE.LINE_DOPPLER]}
                                                        onChange={(event) => {
                                                            setRecordType(s => {
                                                                const newS = { ...s };
                                                                newS[RECORD_TYPE.LINE_DOPPLER] = !newS[RECORD_TYPE.LINE_DOPPLER]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.RECORD_TYPE.LINE_DOPPLER]: event.target.checked }, level)
                                                        }}
                                                        name={SINGLE_FRAME} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{SINGLE_LINE_DOPPLER}</Typography>}
                                            />
                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={recordType[RECORD_TYPE.LINE_DOPPLER_CINELOOP]}
                                                        onChange={(event) => {
                                                            setRecordType(s => {
                                                                const newS = { ...s };
                                                                newS[RECORD_TYPE.LINE_DOPPLER_CINELOOP] = !newS[RECORD_TYPE.LINE_DOPPLER_CINELOOP]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.RECORD_TYPE.LINE_DOPPLER_CINELOOP]: event.target.checked }, level)
                                                        }}
                                                        name={SINGLE_FRAME} />
                                                }
                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{MULTI_LINE_DOPPLER}</Typography>}
                                            />




                                        </Box>
                                    </Fragment>}

                                    {/* USED FILTERS */}
                                    {config.usedFilters && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px` }}>{FILTERS_USED}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={usedFilters[FILTERS_FILTER.NOTE]}
                                                        onChange={(event) => {
                                                            setUSedFilters(s => {
                                                                const newS = { ...s };
                                                                newS[FILTERS_FILTER.NOTE] = !newS[FILTERS_FILTER.NOTE]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.USED_FILTERS.NOTE]: event.target.checked }, level)
                                                        }}
                                                        name={FILTERS_LABEL.NOTE} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{FILTERS_LABEL.NOTE}</Typography>}
                                            />
                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={usedFilters[FILTERS_FILTER.THICKNESS]}
                                                        onChange={(event) => {
                                                            setUSedFilters(s => {
                                                                const newS = { ...s };
                                                                newS[FILTERS_FILTER.THICKNESS] = !newS[FILTERS_FILTER.THICKNESS]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.USED_FILTERS.THICKNESS]: event.target.checked }, level)
                                                        }}
                                                        name={FILTERS_LABEL.THICKNESS} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{FILTERS_LABEL.THICKNESS}</Typography>}
                                            />
                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={usedFilters[FILTERS_FILTER.ANNOTATION]}
                                                        onChange={(event) => {
                                                            setUSedFilters(s => {
                                                                const newS = { ...s };
                                                                newS[FILTERS_FILTER.ANNOTATION] = !newS[FILTERS_FILTER.ANNOTATION]
                                                                return newS
                                                            })
                                                            dispatchLevel({ [FILTERS.USED_FILTERS.ANNOTATION]: event.target.checked }, level)
                                                        }}
                                                        name={FILTERS_LABEL.ANNOTATION} />
                                                }

                                                label={<Typography style={{ fontSize: `${FONT_SIZE}px` }}>{FILTERS_LABEL.ANNOTATION}</Typography>}
                                            />


                                        </Box>
                                    </Fragment>}


                                    {/* SET AS FAVOURITE */}
                                    {config.favourites && <Fragment>
                                        <Typography sx={{ fontSize: `${FONT_SIZE}px`, marginTop: `${SMALL_MARGIN}px` }}>{SET_AS_FAVOURITE}</Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                            <FormControlLabel
                                                sx={{ marginLeft: "0px", }}
                                                control={
                                                    <PrimaryCheckbox checked={favourites}
                                                        onChange={(event) => {
                                                            setFavourites(s => !s)
                                                            dispatchLevel({ [FILTERS.FAVOURITES]: event.target.checked }, LEVELS.ALL)

                                                        }}
                                                        name={FAVOURITES} />
                                                }

                                            />

                                        </Box>
                                    </Fragment>}

                                </Box>

                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>,
        extraModality,
        typeOfDiagnosis,
        sex,
        numVisits,
        selectedDiagnosis,
        birthyear,
        examDate,
        diagnosisQuery: query,
        recordType,
        usedFilters,
        favourites,
        searchIsActive,
        clearFilters

    };
};
export default useCustomFilter;