import { Box, CircularProgress } from "@mui/material"
import { neutral40, neutral80, neutralblack, neutralwhite, primary60, primary80, primary85, primary90, secondary30, secondary40, secondary50 } from "../../../Themes/dermusTheme"
import styled from "@emotion/styled";



const StyledButton = styled.button`
cursor: pointer;
background-color: ${primary90};
color: ${neutralblack};
font-weight: bold;
border:0;
box-shadow: none;
border-radius: 6px;
padding: 12px 8px 12px 8px;
min-width: 100px;
&:hover {
  background-color: ${primary85};
  color: ${neutralblack};
}
&:active{
    background-color: ${primary80};
    color: ${neutralblack};
}
&:disabled{
background-color: ${neutral80};
color: ${neutral40};
}`


const StyledButtonSmall = styled.button`
cursor: pointer;
background-color: ${primary90};
color: ${neutralblack};
font-weight: bold;
font-size: 10px;
border:0;
box-shadow: none;
border-radius: 6px;
padding: 8px 4px 8px 4px;
min-width: 80px;
&:hover {
  background-color: ${primary85};
  color: ${neutralblack};
}
&:active{
    background-color: ${primary80};
    color: ${neutralblack};
}
&:disabled{
background-color: ${neutral80};
color: ${neutral40};
}`



const StyledButtonDark = styled.button`
cursor: pointer;
background-color: ${secondary50};
color: ${neutralwhite};
font-weight: bold;
border:0;
box-shadow: none;
border-radius: 6px;
padding: 12px 8px 12px 8px;
min-width: 100px;
&:hover {
  background-color: ${secondary40};
  color: ${neutralwhite};
}
&:active{
    background-color: ${secondary30};
    color: ${neutralwhite};
}
&:disabled{
background-color: ${neutral80};
color: ${neutral40};
}`


const StyledButtonDarkSmall = styled.button`
cursor: pointer;
background-color: ${secondary50};
color: ${neutralwhite};
font-weight: bold;
font-size: 10px;
border:0;
box-shadow: none;
border-radius: 6px;
padding: 8px 4px 8px 4px;
min-width: 80px;
&:hover {
  background-color: ${secondary40};
  color: ${neutralwhite};
}
&:active{
    background-color: ${secondary30};
    color: ${neutralwhite};
}
&:disabled{
background-color: ${neutral80};
color: ${neutral40};
}`


const PrimaryButton = (props) => {
    const { progress, disabled, isSmall, isDark, ...restProps } = props;
    const isDisabled = progress || disabled

    return <Box sx={{ m: 1, position: 'relative', margin: 0 }}>
        {isDark ?
            isSmall ?
                <StyledButtonDarkSmall
                    disabled={isDisabled} {...restProps} > {restProps.children}</StyledButtonDarkSmall>
                :
                <StyledButtonDark disabled={isDisabled} {...restProps}> {restProps.children}</StyledButtonDark>

            :

            isSmall ?
                <StyledButtonSmall disabled={isDisabled} {...restProps}> {restProps.children}</StyledButtonSmall>
                :
                <StyledButton disabled={isDisabled} {...restProps}> {restProps.children}</StyledButton>

        }
        {
            progress && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: primary60,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: isSmall ? '-24px' : '-12px',
                    }}
                />
            )
        }
    </Box >

}
export default PrimaryButton
