
import { createTheme } from "@mui/material/styles";

import Karla from "../assets/fonts/Karla-Regular.ttf"
import KarlaBold from "../assets/fonts/Karla-Bold.ttf"
import MetropolisBold from "../assets/fonts/Metropolis-ExtraBold.ttf"
import Metropolis from "../assets/fonts/Metropolis-Bold.ttf"

const customFontStyle = `
    @font-face {
    font-family: 'Karla';
    font-weight: normal;
    font-style: normal;
    src: local('Karla'),local('Karla-Regular'), url(${Karla}) format('truetype');
    }

    @font-face {
        font-family: 'Karla';
        font-weight: bold;
        font-style: normal;
        src: local('Karla-Bold'), url(${KarlaBold}) format('truetype');
    }


    @font-face {
    font-family: "Metropolis";
    font-weight: normal;
    font-style: normal;
    src: local("Metropolis"), local("Metropolis-Bold"), url(${Metropolis}) format("truetype");
    }

    @font-face {
        font-family: "Metropolis";
        font-weight: bold;
        font-style: normal;
        src: local("Metropolis-ExtraBold"),url(${MetropolisBold}) format("truetype");
    }
    `

export const error30 = "#AC2238";
export const error40 = "#E1465E";
export const error50 = "#EA6279";
export const error70 = "#FDCAD2";
export const neutral10 = "#2C3A4B";
export const neutral20 = "#394452";
export const neutral30 = "#545D69";
export const neutral40 = "#6D7580";
export const neutral50 = "#858C94";
export const neutral80 = "#EBEEF2";
export const neutral60 = "#A5ABB3";
export const neutral70 = "#DADEE3";
export const neutral90 = "#F4F6F9";
export const neutralblack = "#09101D";
export const neutralwhite = "#FFFFFF";
export const primary10 = "#001E2C";
export const primary20 = "#003549";
export const primary25 = "#004058";
export const primary30 = "#004C68";
export const primary35 = "#005978";
export const primary40 = "#006689";
export const primary50 = "#0080AC";
export const primary60 = "#179BCE";
export const primary70 = "#45B6EA";
export const primary75 = "#75CAF3";
export const primary80 = "#98D5F5";
export const primary85 = "#B2DDF3";
export const primary90 = "#CCE5F5";
export const primary95 = "#E6EEF5";
export const primary99 = "#FBFCFF";
export const primary100 = "#00B3F4";
export const secondary30 = "#0B4763";
export const secondary40 = "#1E5A76";
export const secondary50 = "#316D89";
export const secondary60 = "#4C88A4";
export const secondary70 = "#6DA9C5";
export const secondary80 = "#A8CEE0";
export const secondary90 = "#CFE1EA";
export const tertiary30 = "#284235";
export const tertiary40 = "#295E42";
export const tertiary50 = "#2B7B50";
export const tertiary60 = "#3E9C6C";
export const tertiary65 = "#4FAD7F";
export const tertiary70 = "#62BC92";
export const tertiary75 = "#76C8A3";
export const tertiary80 = "#8CD5B6";
export const tertiary85 = "#A7DCC6";
export const tertiary90 = "#C3E4D7";
export const tertiary95 = "#DBEAE5";


export const extraRed = "#E2304C"


export const dermusErrorColor = "dermusErrorColor"
export const dermusNeutralBlackColor = "dermusNeutralBlackColor"
export const dermusNeutral90Color = "dermusNeutral90Color"
export const dermusNeutral30Color = "dermusNeutral30Color"
export const dermusSecondary80Color = "dermusSecondary80Color"
export const defaultTheme = createTheme();
export const dermusTheme = createTheme({
    palette: {
        primary: { main: secondary80, },
        dermusErrorColor: defaultTheme.palette.augmentColor({               // red
            color: {
                main: error40,
            },
            name: dermusErrorColor
        }),
        dermusNeutralBlackColor: defaultTheme.palette.augmentColor({        // black
            color: {
                main: neutralblack,
            },
            name: dermusNeutralBlackColor
        }),
        dermusNeutral90Color: defaultTheme.palette.augmentColor({            // gray
            color: {
                main: neutral90,
            },
            name: dermusNeutral90Color
        }),
        dermusNeutral30Color: defaultTheme.palette.augmentColor({           // dark gray
            color: {
                main: neutral30,
            },
            name: dermusNeutral30Color
        }),
        dermusSecondary80Color: defaultTheme.palette.augmentColor({          // light blue
            color: {
                main: secondary80,
            },
            name: dermusSecondary80Color
        }),
    },
    typography: {
        "fontFamily": 'Karla, Metropolis',
        body2_dermusRed: {
            color: error40,
        },
        body2_dermusWhite: {
            color: neutralwhite,
        },
        p_600: {
            fontWeight: 600,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: customFontStyle,
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        color: neutralblack,
                        backgroundColor: primary90
                    },
                    "&.Mui-selected, &.Mui-selected:hover": {
                        color: neutralblack,
                        backgroundColor: primary90
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    '&[role="menu"]': {
                        backgroundColor: neutral90
                    },
                },
            },
        },
    },
});

dermusTheme.typography.responsive = {
    fontSize: '.4rem',
    [dermusTheme.breakpoints.up('sm')]: {
        fontSize: '.55rem',
    },
    [dermusTheme.breakpoints.up('md')]: {
        fontSize: '.9rem',
    },
}

export default dermusTheme;
