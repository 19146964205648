import * as THREE from "three";
import { WebGLRenderTarget } from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";

// Create THREE scene and camera
export const createSceneAndCamera = () => {
    const scene = new THREE.Scene();
    const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, 10);
    camera.position.z = 1;
    return { scene, camera }
}
//Create THREE  renderer and renderertarget
export const createRender = (canvas, size) => {
    const renderer = canvas ? new THREE.WebGLRenderer({
        preserveDrawingBuffer: true,
        canvas
    }) : new THREE.WebGLRenderer({
        preserveDrawingBuffer: true,
    });
    renderer.setSize(size.width, size.height, false);
    return renderer
}
//Create THREE EffectComposer
export const createComposer = (renderer, scene, camera, passes, rendererTarget) => {
    const composer = new EffectComposer(renderer, rendererTarget);
    const renderPass = new RenderPass(scene, camera);
    composer.addPass(renderPass);

    passes.forEach(pass => {
        composer.addPass(pass);
    })
    return composer;
}
//Create THREE Geometry and material
export const createGeometryAndMaterial = (texture = null) => {
    const geometry = new THREE.PlaneGeometry(2, 2);
    const material = new THREE.MeshBasicMaterial({ map: texture });
    return { geometry, material }
}

export const create2DImageScene = (canvas, size, texture, passes, rendererTargetFormat = THREE.RGBAFormat, rendererType = THREE.FloatType) => {


    const { scene, camera } = createSceneAndCamera();
    const renderer = createRender(canvas, size);

    const rendererTarget = new WebGLRenderTarget(size.width, size.height, { format: rendererTargetFormat, type: rendererType })
    const composer = createComposer(renderer, scene, camera, passes, rendererTarget);
    const { material, geometry } = createGeometryAndMaterial(texture);
    scene.add(new THREE.Mesh(geometry, material));

    return { renderer, composer, scene, camera, material, geometry, rendererTarget };
}

export const create2DSimpleImageScene = (canvas, size, shaderMaterial) => {
    const { scene, camera } = createSceneAndCamera();
    const renderer = createRender(canvas, size);
    const geometry = new THREE.PlaneGeometry(2, 2);
    const material = new THREE.ShaderMaterial(shaderMaterial);
    const rendererTarget = new WebGLRenderTarget(size.width, size.height, { format: THREE.RGBAFormat, type: THREE.FloatType })
    scene.add(new THREE.Mesh(geometry, material));

    return { renderer, scene, camera, material, geometry, rendererTarget };


}