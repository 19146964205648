import { CognitoUserPool } from "amazon-cognito-identity-js";
export let ClientId = ""
try {
  ClientId = process.env.REACT_APP_COGNITO_CLEINT_ID
} catch (e) { }
const UP = new CognitoUserPool({
  UserPoolId: "eu-central-1_s0JUFYi9Q",
  ClientId: ClientId,
});
export default UP;
