import axios, { } from "axios";
import https from "https";
import http from "http";
import { responseHandler, requestHandler, errorHandler } from "./axiosUtil";
import { isDemo, isProd, isTest, isTestDemo } from "../utility/functions";
//

export const BASEURL = `https://${isTestDemo() ? "test"
  : isDemo() ? "demo"
    : isProd() ? "prod"
      : isTest() ? "test"
        : "dev"}.api.dermusvision.com/v1`;
//export const BASEURL = `http://127.0.0.1:5000/v1`;
const whiteList = [];
const MAX_AGE = 0//2 * 60 * 1000; //millisec  -- 2 mins
const MAX_STALE = 0//24 * 60 * 60 * 1000; //millisec  -- 1 day

const instance = axios.create({
  baseURL: BASEURL,
  timeout: 10000,
  httpsAgent: new https.Agent({ keepAlive: true }),
  httpAgent: new http.Agent({ keepAlive: true }),
});

instance.interceptors.request.use(
  (request) => requestHandler(request, MAX_AGE, MAX_STALE),
  (error) => errorHandler(error, MAX_AGE, MAX_STALE)
);
instance.interceptors.response.use(
  (response) => responseHandler(response, whiteList),
  (error) => errorHandler(error, MAX_AGE, MAX_STALE)
);

export default instance;
