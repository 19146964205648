import { enqueueSnackbar } from 'notistack'

export const addSnackbar = ({ message, autoHideDuration = 3000, onClose = () => { }, ...props }) => {
  return enqueueSnackbar(message, {
    autoHideDuration, onClose, ClickAwayListenerProps: { onClickAway: () => null },
    classes: { anchorOriginBottomCenter: { backgroundColor: "red" } },
    ContentProps: { backgroundColor: "red", sx: { backgroundColor: "red" } },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    ...props
  })
}
