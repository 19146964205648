
import * as action from "../../store/actionCreators/authenticationActionCreator";
import PrimaryButton from "../../UI/Buttons/PrimaryButton";

import PrimaryCheckbox from "../../UI/Checkbox/PrimaryCheckbox";
import { DONT_SHOW_AGAIN } from "../../Constants/Message";
import { DRAWER_SIZE, EXTENDED_DRAWER_SIZE, MOBILE_MODE, PREFERENCES } from "../../Constants/Constants";
import { Box, ClickAwayListener, Typography } from "@mui/material";
import { FROM_SCRATCH, THIS_LEVEL, THIS_LEVEL_OR_SCRATCH } from "../../Constants/Message";
import { useDispatch } from "react-redux";
import * as links from "../../Constants/Links";
import { secondary90 } from "../../../Themes/dermusTheme";
import { USER_PREFERENCES_EP } from "../../API/endPoints";
import useHttp, { POST } from "../../API/useHttp";
import { buildSearchQuery, generateToken } from "../../utility/utility";
import { isSkinAid } from "../../utility/functions";
import useElementSize from "../../hooks/useElementSize";
import useWindowSize from "../../hooks/useWindowSize";


const MoveToCaptureDialog = ({
    setShowSelectCaptureDialog,
    userPreferences,
    showToCaptureTip,
    setShowToCaptureTip,
    setStartCaptureFromCurrentLevel,
    handleIconClick,
    location,
    patientId,
    lesionLocalId,
    user
}) => {

    //Window size
    const windowSize = useWindowSize()

    //Box ref
    const [boxRef, boxSize] = useElementSize()


    //Dispatch
    const dispatch = useDispatch();

    //Set user preferences
    const [
        ,
        sendRequestUserPreferences,
        ,
        pendingUserPreferences
    ] = useHttp();

    const handleStartCaptureFromCurrentLevel = (eventValue) => {
        const newUserPrefences = { ...userPreferences }
        newUserPrefences[PREFERENCES.START_FROM_CURRENT_LEVEL] = eventValue;
        if (userPreferences !== null && !pendingUserPreferences) {
            sendRequestUserPreferences(USER_PREFERENCES_EP, POST, true,
                () => { dispatch(action.addUserPreferencesActionCreator(newUserPrefences)) },
                { user_preferences: JSON.stringify(newUserPrefences) })
        }
        setStartCaptureFromCurrentLevel(eventValue);
    }

    const handleShowToCapture = (eventValue) => {
        const newUserPrefences = { ...userPreferences }
        newUserPrefences[PREFERENCES.SHOW_TO_CAPTURE_TIP] = !eventValue;
        if (userPreferences !== null && !pendingUserPreferences) {
            sendRequestUserPreferences(USER_PREFERENCES_EP, POST, true,
                () => { dispatch(action.addUserPreferencesActionCreator(newUserPrefences)) },
                { user_preferences: JSON.stringify(newUserPrefences) })
        }

        //Set rotation hint
        setShowToCaptureTip(eventValue);
    }




    return (<Box
        sx={{
            zIndex: (theme) => theme.zIndex.tooltip + 1,
            backgroundColor: secondary90,
            position: "fixed",
            top: `calc(100% - ${windowSize.width >= MOBILE_MODE ? boxSize.height : boxSize.height + DRAWER_SIZE}px)`,
            left: `${windowSize.width >= MOBILE_MODE ? EXTENDED_DRAWER_SIZE : 0}px`,
            width: `calc(100% - ${windowSize.width >= MOBILE_MODE ? EXTENDED_DRAWER_SIZE : 0}px)`,
            height: `${boxSize.height}px`,
        }}
    >

        <ClickAwayListener onClickAway={() => setShowSelectCaptureDialog(false)}>
            <Box ref={boxRef} sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                <Typography>{THIS_LEVEL_OR_SCRATCH}</Typography>

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <PrimaryButton
                        isDark={true}
                        onClick={() => {
                            setShowSelectCaptureDialog(false)
                            if (setShowToCaptureTip) {
                                handleStartCaptureFromCurrentLevel(true)
                            }
                            //Search
                            let search = null
                            if (patientId && lesionLocalId && !location.pathname.includes("addlesion")) {
                                if (isSkinAid()) {
                                    search = `?${buildSearchQuery({ patient: patientId, lesion: lesionLocalId, userToken: generateToken({ user }) })}`
                                } else {
                                    search = `?${buildSearchQuery({ patient: patientId, lesion: lesionLocalId })}`
                                }
                            } else if (patientId && !location.pathname.includes("addpatient")) {
                                if (isSkinAid()) {
                                    search = `?${buildSearchQuery({ patient: patientId, userToken: generateToken({ user }) })}`
                                } else {
                                    search = `?${buildSearchQuery({ patient: patientId, })}`
                                }
                            } else {
                                if (isSkinAid()) {
                                    search = `?${buildSearchQuery({ userToken: generateToken({ user }) })}`
                                }
                            }
                            handleIconClick(
                                isSkinAid() ? (links.CAPTURE_FULL_URL + links.CAPTURE_URL) : links.CAPTURE_URL,
                                search,
                                isSkinAid())

                        }}>
                        {THIS_LEVEL}
                    </PrimaryButton>

                    <PrimaryButton
                        isDark={true}
                        onClick={() => {
                            setShowSelectCaptureDialog(false)
                            if (setShowToCaptureTip) {
                                handleStartCaptureFromCurrentLevel(false)
                            }
                            let search = null
                            if (isSkinAid()) {
                                search = `?${buildSearchQuery({ userToken: generateToken({ user }) })}`
                            }
                            handleIconClick((isSkinAid() ? (links.CAPTURE_FULL_URL + links.CAPTURE_URL) : links.CAPTURE_URL), search, isSkinAid())

                        }}>
                        {FROM_SCRATCH}
                    </PrimaryButton>
                </Box>

                <PrimaryCheckbox checked={showToCaptureTip}
                    onChange={(event) => handleShowToCapture(event.target.checked)} size="small"
                    label={
                        <Typography >{DONT_SHOW_AGAIN}</Typography>} sx={{ marginLeft: "10px" }} />

            </Box>
        </ClickAwayListener>
    </Box>)
}

export default MoveToCaptureDialog;  