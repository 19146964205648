import { Box, Typography } from "@mui/material"
import { useState, useEffect, useRef } from "react"
import PrimaryButton from "../UI/Buttons/PrimaryButton"
import { useCallback } from "react"
import { DRAWER_SIZE, INSTALL_APP_LOCAL_STORAGE, MOBILE_MODE } from "../Constants/Constants"
import { INSTALL, INSTALL_APP, NOT_NOW } from "../Constants/Message"

import TextButton from "../UI/Buttons/TextButton"
import { neutralwhite } from "../../Themes/dermusTheme"
import { isDemo } from "../utility/functions"
import useWindowSize from "./useWindowSize"

const POP_UP_SIZE = { width: 315, height: 150, bottom: 20 }

const useInstallApp = ({ position = "middle", noHints = false }) => {
    //Window size
    const windowSize = useWindowSize();
    //Event ref
    const installPromptRef = useRef()
    //Show install pop up or not
    const [showInstallPopup, setShowInstallPopup] = useState(false)


    const onClickInstall = useCallback(async () => {
        installPromptRef.current.prompt();
        const { outcome } = await installPromptRef.current.userChoice;
        installPromptRef.current = null;
        if (outcome === 'accepted') {
            sessionStorage.setItem(INSTALL_APP_LOCAL_STORAGE, true)
        } else if (outcome === 'dismissed') {
            sessionStorage.setItem(INSTALL_APP_LOCAL_STORAGE, false)
        }
        setShowInstallPopup(false)
    }, [])

    const onClickNotNow = useCallback(() => {
        sessionStorage.setItem(INSTALL_APP_LOCAL_STORAGE, false)
        setShowInstallPopup(false)
    }, [])
    useEffect(() => {
        const handleInstallPrompt = (event) => {
            event.preventDefault();
            installPromptRef.current = event;
            const installApp = sessionStorage.getItem(INSTALL_APP_LOCAL_STORAGE) === null ? true : false
            if (installApp && !isDemo()) {
                setShowInstallPopup(true)
            }
        }
        const handleInstalled = () => {
            installPromptRef.current = null;
            setShowInstallPopup(false)
        }

        window.addEventListener("beforeinstallprompt", handleInstallPrompt);
        window.addEventListener("appinstalled", handleInstalled);

        return () => {
            window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
            window.removeEventListener("appinstalled", handleInstalled);
        }
    }, [])

    return (!noHints && showInstallPopup) && <Box sx={{
        display: "flex", flexDirection: "column",
        position: "fixed",
        bottom: `${(windowSize.width >= MOBILE_MODE ? 0 : DRAWER_SIZE) + POP_UP_SIZE.bottom}px`,
        right: `${position === "right" ? 24 : ((windowSize.width - (windowSize.width >= MOBILE_MODE ? DRAWER_SIZE : 0) - POP_UP_SIZE.width) / 2)}px`,
        height: `${POP_UP_SIZE.height}px`,
        width: `${POP_UP_SIZE.width}px`,
        overflowY: 'auto',
        backgroundColor: neutralwhite,
        justifyContent: "space-between",
        boxShadow: "0px 0px 5px 2px #DADADA",
        borderRadius: "16px",
        padding: "24px",
        zIndex: (theme) => theme.zIndex.tooltip + 100,
    }}>


        <Box><Typography sx={{ fontSize: "14px" }}>{INSTALL_APP}</Typography></Box>
        <Box sx={{ display: "flex", flexDirection: "row-reverse", }}>
            <PrimaryButton onClick={onClickInstall}>{INSTALL}</PrimaryButton>
            <TextButton sx={{ marginRight: "10px" }} onClick={onClickNotNow}>{NOT_NOW}</TextButton>
        </Box>


    </Box>

}

export default useInstallApp