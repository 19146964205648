class FreeTextNote {
    /**
     * Constructor
     * @param {Object} freetext free text
     */
    constructor(
        freetext,
    ) {
        this.id = freetext.id
        this.text = freetext.data.text
        this.date = freetext.date
    }
}
export default FreeTextNote;
