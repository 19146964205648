import { useEffect, useState } from "react";
import { PING_EP } from "./endPoints";

import axios from "axios";
import { BASEURL } from "./dermusAxios";
import { useRef } from "react";

const axiosInstance = axios.create({
    baseURL: BASEURL,
});


const useIsOnline = (successTime = 1000 * 30, errorTime = 1000 * 5) => {
    //Online or not
    const [isOnline, setIsOnline] = useState({ status: true, prev: true })
    //Pingdata
    const [pingdata, setPingData] = useState(null)
    //PingError
    const [pingError, setPingError] = useState(null)
    //Ping
    const ping = () => {
        axiosInstance.get(PING_EP).then(data => setPingData(data)).catch(e => setPingError(e));
    }

    //Timer
    const tID = useRef(null)

    const forcePing = () => {
        setPingData(null);
        setPingError(null);
        ping()
    }
    useEffect(() => {
        ping();
        // eslint-disable-next-line 
    }, [])

    useEffect(() => {
        if (pingdata) {
            setIsOnline(s => ({ status: true, prev: s.status }))
            tID.current = setTimeout(() => {
                setPingData(null);
                setPingError(null);
                ping()
            }, successTime)
            return () => clearTimeout(tID.current)
        }// eslint-disable-next-line 
    }, [pingdata])

    useEffect(() => {
        if (pingError) {
            setIsOnline(s => ({ status: false, prev: s.status }))
            tID.current = setTimeout(() => {
                setPingData(null);
                setPingError(null);
                ping()
            }, errorTime)
            return () => clearTimeout(tID.current)
        }// eslint-disable-next-line 
    }, [pingError])

    useEffect(() => {
        window.onoffline = forcePing
        return () => window.onoffline = null;
        // eslint-disable-next-line
    }, [])

    //Set up visibility listener -
    useEffect(() => {
        const handleVisibilityChange = async () => {
            if (document.visibilityState === 'visible') {
                setPingData(null);
                setPingError(null);
                ping();
            } else {
                clearTimeout(tID.current)
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
        // eslint-disable-next-line 
    }, [])

    return { isOnline, forcePing }
}

export default useIsOnline