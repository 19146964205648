import { Box, Typography } from "@mui/material"
import { SEGMENTATION_LAYERS, SEGMENTATION_LAYER_COLORS, SEGMENTATION_LAYER_ENUM } from "../../Constants/Constants"
import { error40, neutral90 } from "../../../Themes/dermusTheme"
import { SKIN_AID_ANALYTICS_DISCLAMER } from "../../Constants/Message"

const SegmentationLabel = ({ width, height, activeLayer = 0, overlayExtraData = null }) => {
    return <Box sx={{
        display: "flex", flexDirection: "row", borderRadius: "5px", background: neutral90 + "BF", flexWrap: "wrap", justifyContent: "center", width, height,
        paddingLeft: "3px", paddingRight: "5px"
    }}>
        {activeLayer ?
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Box sx={{ background: SEGMENTATION_LAYER_COLORS[SEGMENTATION_LAYER_ENUM[activeLayer]], borderRadius: "50%", width: "8px", height: "8px" }}></Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: 'calc(100% - 10px)' }}>
                    <Box sx={{ paddingLeft: "2px", paddingRight: "5px", margin: "0px" }}>
                        <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                            {SEGMENTATION_LAYERS[SEGMENTATION_LAYER_ENUM[activeLayer]]}
                        </Typography>
                    </Box>

                    <Box sx={{ paddingLeft: "0px", paddingRight: "0px", margin: "0px", flexDirection: "column" }}>
                        <Typography sx={{ fontSize: "10px" }}>
                            {overlayExtraData && overlayExtraData[SEGMENTATION_LAYER_ENUM[activeLayer]]}
                        </Typography>
                        <Typography sx={{ fontSize: "10px", color: error40 }}>
                            {SKIN_AID_ANALYTICS_DISCLAMER}
                        </Typography>
                    </Box>

                </Box>
            </Box>
            : Object.keys(SEGMENTATION_LAYERS).map(item => {
                return <Box key={item} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Box sx={{ background: SEGMENTATION_LAYER_COLORS[item], width: "8px", height: "8px", borderRadius: "50%" }}></Box>
                    <Box sx={{ paddingLeft: "5px", paddingRight: "5px", margin: "0px" }}>
                        <Typography sx={{ fontSize: "10px" }}>
                            {SEGMENTATION_LAYERS[item]}
                        </Typography>
                    </Box>
                </Box>
            })}
    </Box >

}
export default SegmentationLabel