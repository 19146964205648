class Patient {
  /**
   * Constructir
   * @param {String} name name of patient
   * @param {String} lastExamDate last exam date
   * @param {String} id id
   * @param {String} birthYear year of birth
   * @param {String} sex sex
   * @param {String} note note
   */
  constructor(name, lastExamDate, rawLastExamDate, id, birthYear, sex, note, favourite, diagnosis, diagnosisTitle, freeDiagnosis,
    num_of_visits, latest_record_coord_x, latest_record_coord_y, lesions, tool_action_text, birth_date, patient_type, animal_type) {
    this.name = name;
    this.lastExamDate = lastExamDate;
    this.rawLastExamDate = rawLastExamDate;
    this.id = id;
    this.birthYear = birthYear;
    this.sex = sex;
    this.note = note;
    this.favourite = favourite;

    this.diagnosis = diagnosis;
    this.diagnosisTitle = diagnosisTitle;
    this.freeDiagnosis = freeDiagnosis

    this.numOfVisits = num_of_visits
    this.latestRecorsCoordX = latest_record_coord_x
    this.latestRecorsCoordY = latest_record_coord_y
    this.lesions = lesions
    this.toolActionTexts = tool_action_text

    this.birthDate = birth_date
    this.patientType = patient_type
    this.animalType = animal_type
  }
}
export default Patient;
