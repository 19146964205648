
import { SKINAID_URL_END } from "../Constants/Links";

export const isDev = () =>
    process.env.REACT_APP_ENVIRONMENT
        ? process.env.REACT_APP_ENVIRONMENT === "dev"
        : true;
export const isProd = () =>
    process.env.REACT_APP_ENVIRONMENT
        ? process.env.REACT_APP_ENVIRONMENT === "prod"
        : false;
export const isDemo = () =>
    process.env.REACT_APP_ENVIRONMENT
        ? (process.env.REACT_APP_ENVIRONMENT === "demo" || process.env.REACT_APP_ENVIRONMENT === "testdemo")
        : false;
export const isTestDemo = () =>
    process.env.REACT_APP_ENVIRONMENT
        ? process.env.REACT_APP_ENVIRONMENT === "testdemo"
        : false;
export const isTest = () =>
    process.env.REACT_APP_ENVIRONMENT
        ? process.env.REACT_APP_ENVIRONMENT === "test"
        : false;

export const isSkinAid = () => window.location.origin.includes(SKINAID_URL_END)


export const LOGS = [];
if (!window.location.origin.includes("localhost:")) {


    var log = console.log;

    console.log = function () {
        // 1. Convert args to a normal array
        var args = Array.from(arguments);
        // OR you can use: Array.prototype.slice.call( arguments );
        LOGS.push(args)
        // 2. Prepend log prefix log string
        //args.unshift(LOG_PREFIX + ": ");
        // 3. Pass along arguments to console.log
        log.apply(console, args);
    }

    var error = console.error;

    console.error = function () {
        // 1. Convert args to a normal array
        var args = Array.from(arguments);
        // OR you can use: Array.prototype.slice.call( arguments );
        LOGS.push(args)
        // 2. Prepend log prefix log string
        //args.unshift(LOG_PREFIX + ": ");
        // 3. Pass along arguments to console.log
        error.apply(console, args);
    }

    var warn = console.warn;

    console.warn = function () {
        // 1. Convert args to a normal array
        var args = Array.from(arguments);
        // OR you can use: Array.prototype.slice.call( arguments );
        LOGS.push(args)
        // 2. Prepend log prefix log string
        //args.unshift(LOG_PREFIX + ": ");
        // 3. Pass along arguments to console.log
        warn.apply(console, args);
    }
}