import { DERMUS_APP_SUPPORTED_HOST_DEVICE_LINK, DERMUS_WIKI_LINK, INSTRUCTION_VIDEO_LINK, SKIN_AID_NAME, SKIN_AID_USER_MANUAL_LINK, SSU_NAME, SSU_USER_GUIDE_LINK, VERSION } from "./Constants";
import { Fragment } from "react";
import { isSkinAid } from "../utility/functions";
import { error40 } from "../../Themes/dermusTheme";
//Login
export const INVALID_PSW_OR_E_MAIL = "Please add a valid email and password.";
export const ENTER_YOUR_PSW = ", please enter your password.";
export const SIGN_IN = "Sign in";
export const WELCOME = "Welcome";
export const WELCOME_USER = (user) => user ? `Welcome, ${user}!` : `Welcome!`;
export const TAP_TO_MEASURE = "Tap on two points on one of the images.";
export const X_UNIT = (x, unit) => (x === undefined || x === null ? "-" : `${x} ${unit}`);
//




export const PLEASE_ROTATE = "Please rotate your phone and enable fullscreen layout for the optimal experience."
export const DONT_SHOW_AGAIN = "don't show again";
export const PLUG_AND_PAIR_NEW = <div>
  Please plug in your Dermus device and allow access to Dermus <u><i> Camera </i></u> and <u><i> Ultrasound</i></u>.
</div>
export const VIRTUAL_DEVICE_CONNECTED = "Virtual SkinScanner connected.";

export const NOT_SUPPORTED_NOT_WORK_PROPERLY = <div><a href={DERMUS_APP_SUPPORTED_HOST_DEVICE_LINK} ><u>Unsupported</u></a> environment detected. System might not work properly.</div>;

export const DEVICE_CONNECTED_AND_MIGHT_UNSUPPORTED = (unsupported = false) => <div >
  Device connected. {unsupported && <b style={{ color: error40 }}> <a href={DERMUS_APP_SUPPORTED_HOST_DEVICE_LINK} ><u>Unsupported</u></a> environment detected. System might not work properly.</b>}
</div>;
export const PLEASE_START_SCANNING = "Please start the scanning by pressing the \"Unfreeze\" button on the scanner or on the top right of this screen.";
export const UNSUPPORTED_HOST_DEVICE = "Unsupported host device.";
export const PLEASE_START_SIMULATION = <div>
  Please start scanning simulation by pressing the "Unfreeze" button <b> on the top right of the real screen</b>.
</div>;

export const PAIR_DERMUS_ULTRASOUND = "Pair Dermus Ultrasound";
export const PAIR_DERMUS_CAMERA = "Pair Dermus Camera";

export const OPTICAL_IMAGE_NOTE = "Optical image note"
export const US_IMAGE_NOTE = "Ultrasound image note"
export const DERMOSCOPY_IMAGE_NOTE = "Dermoscopy image note"
export const CLINICAL_IMAGE_NOTE = "Clinical image note"

export const ERROR_OCCURRED = "Error Occurred";
export const ERROR_DURING_LOADING_DATA = "Error during loading data";
export const OK = "OK";
export const CANCEL = "Cancel";
export const SEARCH = "Search...";
export const SEARCH_ID_EXAMDATE_DIAGNOSES = "Search for ID, exam date, diagnoses";
export const SEARCH_ID_EXAMDATE = "Search for ID, exam date";
export const UNKNOWN = "Unknown";
export const CANCEL_CINELOOP = "Cancel cine-loop saving";
export const CANCEL_FRAMES = "Cancel frame pairs saving";
export const ERROR_MISSING_INPUT = "Input information is missing.";
export const TEST_DEV_MODE_MSG =
  "WARNING: EVALUATION MODE ONLY. TO BE USED ONLY BY PERSONNEL APPROVED BY DERMUS KFT.";

export const UPLOADED_FRAMES_AND_CINELOOP = (d) => d > 1 ?
  `The cine-loop and ${d} frame pairs are uploaded.` : `The cine-loop and ${d} frame pair is uploaded.`;
export const UPLOADED_FRAMES = (d) => d > 1 ? `${d} frame pairs are uploaded.` : `${d} frame pair is uploaded.`;
export const UPLOADED_CINELOOP = `The cine-loop is uploaded.`;
export const CANCEL_SAVING = "Cancel saving";
export const CANCELING = "Cancelling saving";

export const PLUG_AND_PAIR_TITLE = "Plug and pair Dermus Device";
export const PLUG_AND_PAIR =
  "Please plug in your Dermus device and pair it with SkinAid.";
export const MORE_DEV_DETECTED = "Warning: more than one devices are detected.";
export const DEVICE_IS_IN_USE =
  "Device is in use, please close all other tabs that use the device.";
export const PROBABLY_DEVICE_IS_IN_USE =
  "The device is probably in use, please close all other tabs that may use the device.";
export const DEVICE_IS_NOT_CONNECTED = "Device is not connected.";
export const DEVICE_IS_CONNECTED = "Device is connected.";
export const ERROR_DURING_META_DATA_COM =
  "An error occurred during metadata commincation. Please try again.";
export const DEVICE_IS_DISCONNECTED = "Device is disconnected, try to fix cable connection.";
export const NOT_SUPPORTED = "Warning: WebUSB is not supported by your browser.";
export const LOAD_DEFAULT_CALIBRATION_PARAMS = "Load default calibration params"

export const RESET = "Reset";
export const WATCHDOG_ERROR = "An error occured in the device, please reset the device with the button below."
export const WATCHDOG = "Watchdog"
export const DERMUS_WIKI = "Dermus Wiki"
export const FW_UPDATES = "FW updates"
export const MAIN_FX3 = "Main Fx3"
export const CAM_FX3 = "Camera Fx3"
export const DONE = "Done"
export const UPDATING_FW = "Updating FW"

export const CURRENT_VERSION_OF_FW = (v) => `Current Firmware Version: ${v ?? "N/A"}`
export const UPDATE_FW = "Update Firmware"
export const IMPORTANT = "Important"
export const UPDATE = "Update"
export const RECONNECT_THE_DEVICE = "Reconnect the device, please."
export const UPDATE_TAKES_MINS = "This update might take a few minutes."
export const OUTDATED_FW = "Your device’s firmware is out of date. It’s strongly recommended you install the new version."
export const DO_NOT_CLOSE_OR_UNPLUG = "Do not close this window or unplug the device; otherwise, the device may be damaged."
export const UPDATING_FW_TO = (v) => `Updating firmware to version ${v}`
export const LATER = "Later"
export const FW_AVAILABLE = (v) => `${v} available`
export const UPTODATE_FW = "Your version is up to date."
export const CURRENT_VERSION_OF_WEBAPP = `Current version of this web app: ${VERSION}`
export const SEE_INFO_ON_WIKI = <p>Please see the information on <a href={DERMUS_WIKI_LINK} target="_blank" rel="noopener noreferrer">{DERMUS_WIKI}</a>.</p>
export const USER_GUIDE_OF_SSU = <p>User guide of <a href={SSU_USER_GUIDE_LINK} target="_blank" rel="noopener noreferrer">{SSU_NAME}</a>.</p>
export const USER_GUIDE_OF_SKINAID = <p>User guide of <a href={SKIN_AID_USER_MANUAL_LINK} target="_blank" rel="noopener noreferrer">{SKIN_AID_NAME}</a>.</p>
export const INSTRUCTION_VIDEO = <p>Quick instructional videos: {INSTRUCTION_VIDEO_LINK}.</p>
export const IF_HAVE_ANY_QUESTION = "If you have any questions or issues, or would like to give any feedback, please contact our Support Team:"

export const FW_UPDATE_FINISHED = "The update is finished. You can exit SkinScanner or start Capture. Please, reconnect the device."
export const START_CAPTURE = "Start Capture"
export const FW_UPDATE_IN_ABOUT = "You can access the update in the About menu any time."
export const ECHO_INTENSITY_LOWER_CASE = "echo intensity"
export const FLOW_INTENSITY_LOWER_CASE = "flow intensity"
export const LOW = "low"
export const HIGH = "high"

export const VALIDATION_ERROR = "Validation error"
export const UPLOAD = "Upload";
export const FPGA = "FPGA"
export const AIO = "AIO"
export const STM32 = "STM32"
export const ERROR_GET_IMAGES = "Error during get image(s)";
export const CURRENT_DEVICE_VERSION_TABLE = "Current device version table"
export const CURRENT_DEVICE_SERIAL_NUMBER = "Current device serial number"
export const CURRENT_DEVICE_MAIN_BOARD = "Current device main board"
export const SERIAL_NUMBER = "Serial number"
export const MAIN_BOARD = "MainBoard"
export const CONNECTED_DEVICE = "Connected Device"
export const WRITE = "write"
export const ENTER_PIN = "Enter the PIN"
export const WRONG_PIN = "Wrong PIN"

export const MAX_ANNOTATION_POINT =
  "The maximum number of action has been reached.";

export const UP_TO_DATE = (n) => n > 1 ? "are up-to-date" : n === 1 ? "is up-to-date" : ""


export const READ_EEPROM = "Reading data from EEPROM...";

export const UNFREEZE_TO_START_RECORDING = "Unfreeze to start recording";


export const UNIT = "unit";

export const ACCEPT = "Accept"
export const IF_DID_NOT_GET_VERIFICATION_EMAIL = `If you didn’t find our letter please check your spam folder or ask for help at `


export const THIS_EMAIL_USED_ALREADY = "This email is used already on SkinAid.";

export const FINISH = "Finish"

export const DEVICE_NOT_OPENED = "Device is not opened"
export const NOT_EPPROM_USE_DEFAULT = "Can't read valid configuration, default one is used."
export const NOT_VALID_EPPROM_VERSION_USE_DEFAULT = "EEPROM version is not supported, default configuration is used."
export const NOT_VALID_EPPROM_VERSION = "EEPROM version is not supported."
export const UNCALIBRATED_DEVICE = "Device uncalibrated, default parameters loaded"

export const MAX_THICKNESS = "max thickness"
export const AVG_THICKNESS = "average thickness"
export const MM = "mm"
export const AREA = "area"
export const SUM_AREA = "sum area"
export const MAX_LENGTH = "max length"
export const CLASSIFICATION = "classification"
export const NA = "N/A"

export const ADD_PATIENT_BY = "Add a patient by tapping the + button or start recording."
export const ADD_LESION_BY = "Add a lesion by clicking on the + button or start recording."
export const ADD_RECORD_BY = "Start recording by tapping the SkinScanner button in the sidebar."
export const ERROR_DURING_LOADING_PATIENT = "Error during loading patient data."
export const ERROR_DURING_LOADING_LESION = "Error during loading lesion data."
export const ERROR_DURING_LOADING_PATIENTS = "Error during loading patients data."
export const LOCAL_ID_LESION_NOT_FOUND = "Lesion is not found."
export const LOCAL_ID_RECORD_NOT_FOUND = "Record is not found."


export const OPT_NOTE = "Opt note: ";
export const US_NOTE = "US note: ";


export const FROM = "From"
export const UNTIL = "Until"
export const DIAGNOSES = "Diagnosis"
export const ENTER_OR_CHOOSE = "Enter here or choose from list"
export const NR_PREV_VISITS = "Nr of previous visits";

export const IMAGE_FROM_UNCALIBRATED_DEVICE = "Images were captured with an uncalibrated device"


export const DELETE_ITEMS = "Delete items?"
export const ARE_YOU_SURE_DELETE_ENTRIES_I = "Are you sure? This will permanently"
export const ARE_YOU_SURE_DELETE_ENTRIES_II = " delete the entries from your catalog."

export const DELETE = "Delete"
export const INSTALL = "Install"
export const NOT_NOW = "Not now"

export const ITEM_DELETED = " item deleted"
export const ITEM_MARKED = " item marked as favourite"
export const ITEM_UNMARKED = " item removed from favorites"
export const ITEMS_DELETED = " items deleted"
export const ITEMS_MARKED = " items marked as favourite"
export const ITEMS_UNMARKED = " items removed from favorites"


export const INSTALL_APP = `Access ${!isSkinAid() ? "Dermus" : "SkinAid"} faster with our app on your home screen.`

export const SETTINGS = "Settings";
export const LOGOUT = "Logout";
export const PATIENTS = "Patients";
export const PATIENT = "Patient";
export const YOUR_PATIENTS = "Your patients";
export const SELECT_PATIENT = "Select patient";
export const LESIONS = "Lesions";
export const LESION = "Lesion";
export const SELECT_LESION = "Select lesion";
export const RECORDS = "Records";
export const RECORD = "Record";
export const PATIENT_ID = "Patient ID";
export const LAST_EXAM_DATE = "Last exam date";
export const EXAM_DATE = "Exam date";

export const DATE = "Date"
export const LOGGED_IN_USER = "Logged in user"
export const APP_VERSION = "App version"
export const FW_VERSION_FEEDBACK = "Firmware version"
export const OPERATING_SYSTEM = "Operating system"
export const BROWSER_VERSION = "Browser version"

export const NEW_FEATURES_WITH_FW = "New update released with firmware enhancements";
export const NEW_FEATURES = "We're rolling out exciting new features on 30 September!";
export const DETAILS = "Details";
export const DISMISS = "Dismiss";
export const ALERT = "Alert"

export const ID = "ID";

export const ANIMAL = "Animal";
export const HUMAN = "Human";
export const TYPE_HERE = "Type here";
export const MODEL = "Model";
export const SEX = "Sex";
export const ANIMAL_NAME = "Name";
export const SPECIES = "Species";
export const TYPE_OF_DIAGNOSIS = "Type of diagnosis";
export const EXTRA_MODALITY = "Extra modality";
export const EXTRA_MODALITY_DOWNLOAD = "The image(s) will be";
export const EXTRA_MODALITY_DOWNLOAD2 = "downloaded as PNG.";
export const FILTERS_USED = "Filters used";
export const SET_AS_FAVOURITE = "Favourites";
export const RECORDING_TYPE = "Recording type";
export const SINGLE_FRAME = "single frame"
export const MULTI_FRAME = "multi-frame"
export const SINGLE_LINE_DOPPLER = "line-doppler frame"
export const MULTI_LINE_DOPPLER = "line-doppler multi-frame"
export const EXTRA_MODALITY_LABEL = { NONE: "none", CLINICAL: "clinical", DERMOSCOPY: "dermoscopy" };
export const BIRTH_YEAR = "Birth year";
export const BIRTH_DATE = "Birth date";
export const OPTICAL_ALT = "Optical image";
export const LOGIN_ALT = "Login image";
export const US_IMAGE_ALT = "US image";
export const OPTICAL_IMAGE = "Optical image";
export const ULTRASOUND_IMAGE = "Ultrasound image";
export const LESION_LOCATION_ALT = "Lesion location";
export const ADD_NEW_PAT = "Add new patient";
export const NEW_PAT = "New patient";
export const ADD_NEW_LESION = "Add new lesion";
export const ADD_RECORD = "Add new record";
export const ADD_RECORD_WITH_SSM = "Add new record with SS-M/U";
export const IMPORT_OPTOFUSE_IMAGES = "Import OptoFuse record";
export const LESION_NOTE = "Lesion note";
export const RECORD_NOTES = "Record notes";
export const DOWNLOAD_IMGS = "Download images";
export const THICKNESS_MEASUREMENT = "Thickness measurement";
export const FREEHAND_DRAWING = "Freehand drawing";
export const MEASUREMENTS = "Measurements";
export const NO_MEASUREMENT = "No measurements added yet";
export const WIDTH_LABEL = "width [mm]";

export const OPTICAL = "Optical - ";
export const US = "US - ";
export const VERTICAL_DIST = (d) => `Vertical distance: ${d} [mm]`;
export const HORIZONTAL_DIST = (d) => `Horizontal distance: ${d} [mm]`;
export const DIST = (d) => `Distance: ${d} [mm]`;
export const RECORD_INFO = "Record information";
export const PATIENT_NOTE = "Patient note";
export const ADD_NOTE = "Add note";
export const EDIT_PATIENT = "Edit patient";
export const DELETE_PATIENT = "Delete patient";
export const EDIT_LESION_NOTE = "Edit lesion note";
export const EDIT_LESION_DIAGNOSIS = "Edit lesion diagnosis";
export const ADD_DIAGNOSIS = "Add diagnosis";
export const CONFIRM_DIAGNOSIS = "Confirm diagnosis";
export const EDIT_DIAGNOSIS = "Edit diagnosis";
export const CONFIRM_UPDATE_DIAGNOSIS = "Confirm / update diagnosis";
export const SELECTED_DIAGNOSIS = "Selected diagnosis";
export const SELECT_DIAGNOSIS = "Please select a diagnosis.";
export const PLEASE_SPECIFY_DIAGNOSIS = "Specify the diagnosis."
export const LOCATION = "location"
export const EDIT_LESION_DATA = "Edit lesion data"
export const EDIT_LESION_LOCATION = "Edit lesion location"
export const ADD_FREE_TEXT_NOTES = "Add free text notes"
export const ADD_OWN_TEXT_NOTES = "Add your own notes"
export const SEARCH_FOR_ICD = "Search for ICD code"
export const ORIGIN_OF_DIAGNOSIS = "Basis of diagnosis"
export const SELECT_FROM_THE_LIST = "Select from the list"
export const PROPERTY_NOTE = "Property note"
export const DIAGNOSIS_ORIGIN = {
  preliminaryClinicalDiagnosis: "preliminary clinical diagnosis",
  finalClinicalDiagnosis: "final clinical diagnosis",
  histologicalDiagnosis: "histological diagnosis"
}
export const DIAGNOSIS_ORIGIN_SHORT_LABEL = {
  preliminaryClinicalDiagnosis: "prelim. clinical",
  finalClinicalDiagnosis: "final clinical",
  histologicalDiagnosis: "histological"
}
export const DIAGNOSIS_ORIGIN_EXTRA_SHORT_LABEL = {
  preliminaryClinicalDiagnosis: "prel. cl.",
  finalClinicalDiagnosis: "final cl.",
  histologicalDiagnosis: "histo."
}


export const FILTERS_LABEL = {
  NOTE: "note",
  THICKNESS: "2-point dist.",
  ANNOTATION: "annotation"
}
export const FAVOURITES = "favourites";
export const FILL_ICD_OR_FREETEXT = "Please fill either the ICD code or the free text field, or both of them."
export const FILL_DIAGNOSIS_ORIGIN = "Please specify the basis of the diagnosis."
export const SPECIFY_CASE = "Specify the case."
export const DIAGNOSIS = "Diagnosis";

export const SELECTED = "Selected"
export const MOVE = "Move"
export const CANNOT_DISPLAY_IMAGE = "SkinAid could not load the image for display.";
export const LAST_EDITED_HINT = "Last edited lesion";
export const SEGMENTATION = "Segmentation";
export const AUTO_SEGMENTATION = "Automated segmentation";
export const ARE_YOU_SURE_OVERWRITE_SEGMENTATION =
  "Are you sure you want to overwrite the segmentation? The previous segmentation will be overwritten.";
export const ARE_YOU_SURE = "Are you sure?";
export const SAVING = "Saving...";

export const ADD_NEW_POINT = "Add new point";
export const REMOVE_POINT = "Delete point";
export const ADD_NEW_POLYGON = "Add new polygon";
export const ARE_YOU_SURE_DELETE_PROFILE =
  "Are you sure you want to delete your profile?";

export const US_LESION_COMPACTNESS = "US lesion compactness is";
export const US_LESION_AXIS_RATIO = "US lesion axis ratio is";
export const US_LESION_PA_RATIO = "US lesion PA ratio is";
export const LESION_MEASUREMENTS = "Lesion measurements";

export const POWERED_BY = "powered by"
export const LOGGING_OUT = "Logging out..."
export const MEASUREMENT_IS_SAVED = "Measurement is saved.";
export const ERROR_MEASUREMENT_SAVING = "Error during saving the measurement";

//Date
export const YEARS_AGO = "years ago";
export const FEW_YEARS_AGO = "few years ago";
export const ONE_YEAR_AGO = "one year ago";
export const MONTHS_AGO = "months ago";
export const FEW_MONTHS_AGO = "few months ago";
export const A_MONTH_AGO = "a month ago";
export const FEW_WEEKS_AGO = "few weeks ago";
export const A_WEEK_AGO = "a week ago";
export const FEW_DAYS_AGO = "few days ago";
export const A_DAY_AGO = "a day ago";
export const FEW_HOURS_AGO = " few hours ago";
export const A_HOUR_AGO = "an hour ago";
export const FEW_MINUTES_AGO = "few minutes ago";
export const RECENTLY = "recently";
export const NO_RECORD_YET = "no record yet";
export const NO_LESION_YET = "no lesion yet";
export const NO_DIAGNOSIS = "no diagnosis";

export const OPTICAL_DATA = "Optical data";
export const US_DATA = "US data";
export const M_DOT = "·";
//Placeholders
export const EMAIL = "Email";
export const PHONE = "Phone";
export const CONFIRM_EMAIL = "Confirm e-mail";
export const CONFIRM_NEW_PASSWORD = "Confirm new password";
export const NEW_PASSWORD = "New password";
export const CONFIRM_PASSWORD = "Confirm password";
export const PASSWORD = "Password";
export const NAME = "Patient ID";
export const NOTE = "Note...";
export const NOTE_TITLE = "Note";
export const MEASUREMENT_TITLE_OPT = "Opt measuerement";
export const MEASUREMENT_TITLE_US = "US measuerement";
export const Name = "Name";
export const SURNAME = "Surname";
export const COUNTRY = "Country";
export const WRITE_YOUR_ANSWER_HERE = "Write yout answer here";
export const WHAT_IS_YOUR_SPEC = "What's your specialization?"
export const COMPANY_INSTITUTE = "Company/Institute";
export const CITY_TOWN = "City/Town";
export const PREFIX = "Prefix"
export const PREFIXES = [{ value: "Dr.", label: "Dr." }, { value: "Prof.", label: "Prof." }]
export const MODELS = [{ value: "MOUSE", label: "mouse" }, { value: "RAT", label: "rat" }, { value: "PIG", label: "pig" }, { value: "RABBIT", label: "rabbit" }, { value: "DOG", label: "dog" }, { value: "CAT", label: "cat" }, { value: "HORSE", label: "horse" }, { value: "PRIMATE", label: "primate" }]
export const AFFILIATION = "Affiliation";
export const USER_NAME = "Name";
export const MPRN = "Medical practitioner reg. num. (if applicable)";
export const COMPANY_NAME = "Company name";
export const COMPANY_ADDRESS = "Company address";
export const ADDRESS = "Address";
export const COMPANY_VAT = "Company VAT number";
export const BILLING_INFO = "Billing details";
export const COMPANY = "Company";
export const MATCH_LOWERCASE = "match"
export const PSW_REQ = "Password requirements"
export const PSW_REQ_LIST = [
  { msg: "At least 8 charachters long", validator: (psw) => psw.length >= 8 },
  { msg: "Min. 1 lowercase letter", validator: (psw) => !(psw.search(/[a-z]/) < 0) },
  { msg: "Min. 1 uppercase letter", validator: (psw) => !(psw.search(/[A-Z]/) < 0) },
  { msg: "Min. 1 number", validator: (psw) => !(psw.search(/[0-9]/) < 0) },
  /*{
    msg: "Min. 1 special character, e.g., ! @ # ?", validator: (psw) => {

      const regExp = /(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/;
      return regExp.test(psw)
    }
  }*/

]
export const INCORRECT_CODE = "Incorrect code. Please try again."
export const PSW_UPDATE_SUCCESS = "Password successfully updated."

//Encrypt-Decrypt
export const CANNOT_DECRYPT_DATA = "Could not decrypt data.";

//Add/modify Patient
export const MALE = "Male";
export const FEMALE = "Female";
export const OTHER = "Other/declined to give";
export const SEX_OPT = {
  male: "male",
  female: "female",
  other: "other"
}
export const CHOOSE_SEX = "Choose sex...";
export const YEAR_OF_BIRTH = "Year of birth";
export const ENTER_HERE = "Enter here";
export const ADD_VALID_NAME = "Please add a valid Patient ID.";
export const ADD_VALID_BIRTH = "Please add a valid birth year.";
export const ADD_VALID_BIRTH_DATE = "Please add a valid birth date.";
export const ADD_VALID_SEX = "Please choose a sex.";
export const GREATER_THAN = "Value must be greater than or equal to 1900.";
export const LESS_THAN = "Value must be less than or equal to 2100.";

export const ALL_MEASUREMENTS_AND_NOTES_ARE_SAVE = "Note: all measurements and notes made on the above frames will be exported into the new records. "

export const MODIFY_PATIENT = "Modify patient";
export const ARE_YOU_SURE_DELETE_PATIENT =
  "Are you sure you want to delete this patient?";
export const CANNOT_LOAD_PATIENT = "Could not load patient data.";

//Sign up
export const SPECS = [
  { value: "dermatologist", label: "dermatologist" },
  {
    value: "general_practitioner",
    label: "general practitioner",
  },
  { value: "surgeon", label: "surgeon" },
  { value: "other_healthcare_professional", label: "other healthcare professional" },
  { value: "other", label: "other" },
];
export const CHOOSE_SPEC = "Choose specialization...";
export const CHOOSE = "choose";

export const CONFIRM_HEALTCARE_PROF = "Here I confirm that I am a healthcare professional."

export const ADD_VALID_USER_NAME = "Please add a valid name.";
export const ADD_VALID_AFF = "Please add a valid affiliation.";
export const ADD_VALID_SPEC = "Please choose a specialization.";
export const ADD_VALID_PSW = "Please add a valid password.";
export const ADD_VALID_EMAIL = "Please add a valid email.";
export const PSWS_NOT_SAME = "The two passwords are not the same.";
export const EMAILS_NOT_SAME = "The two emails are not the same.";

export const ADD_VALID_COMPANY_NAME = "Please add a valid company name.";
export const ADD_VALID_COMPANY_VAT = "Please add a valid company VAT number.";
export const ADD_VALID_COMPANY_ADDRESS = "Please add a valid company address.";
export const ADD_VALID_ADDRESS = "Please add a valid address.";

export const SUCCESS = "Success";
export const SIGN_UP_SUCCESS_CONFIRM =
  "To finish the registration process, please verify your e-mail address by clicking on the link we sent to your email address.";
export const CONFIRM_REGISTRATION =
  "By pressing Confirm, I confirm that the details provided above are correct, and I request to register with SkinAid under the terms already approved by me.";
export const CONFIRM = "Confirm";
export const CONFIRM_SIGN_UP = "Confirm sign up";
export const BACK = "Back";
export const NOT_AGREE_PRIVACY_POLICY =
  "Please read and accept the Privacy Policy to continue the registration process.";
export const NOT_AGREE_TERMS_AND_CONDITION =
  "Please read and accept the Terms and Conditions to continue the registration process.";
export const PRIVACY_POLICY = "Privacy Policy";
export const TERMS_AND_CONDITION = "Terms and Conditions";
export const RELEASE_NOTES_SKIN_SCANNER_U = "Release Notes (SkinScanner-U)";
export const RELEASE_NOTES_SKIN_AID = "Release Notes (SkinAid)";
export const READ_AND_UNDERSTOOT = "I have read and understood the ";
export const LATEST = "Current version";
export const PREV_VERSIONS = "Previous versions";
export const READ_FIRST_TERMS_AND_CONDITION =
  "Please read the Terms and Conditions.";
export const READ_FIRST_PRIVACY_POLICY = "Please read the Privacy Policy.";
export const ABOUT_DERMUS = "About Dermus";
export const ABOUT = "About";
export const READ_USER_MANUAL = "Read User Guide";
export const USER_MANUAL = "User Guide";
export const SIGN_UP = "Sign up";
export const KNOW_MORE = (props) => {
  return (
    <Fragment>
      If you would like to learn more about us, please visit {props.children}.
    </Fragment>
  );
};
export const RELEASE_NOTES_TITLE = "Release notes";
export const NOTES = "Notes";
export const RELEASE_DATE = "Release date";
export const USER_PROFILE = "Profile";
export const SPECIALIZATION = "Specialization";
export const MPRN_FULL = "Medical Practitioner Registration Number";
export const YES = "Yes";
export const NO = "No";
export const DELETE_PROFILE = "Delete profile";
export const MODIFY_PROFILE = "Modify profile";
export const SAVE = "Save";
export const UPDATE_PSW = "Change password";
export const UPDATE_EMAIL = "Change email";
export const NEW_EMAIL = "New email";
export const NEW_PSW = "New password";
export const ACT_PSW = "Current password";


export const PDF_REPORT_READY = "The PDF report is ready. Choose how to receive it:";
export const OR = "or";
export const SENT_SUCCESSFULLY = "Sent successfully.";
export const DONT_RECEIVE = "If you don't receive our email from";
export const SUPPORT_DERMUSVISION = "support@dermusvision.com";
export const FEW_MINUTES = "within a few minutes,";
export const CHECK_SPAM = "please check your spam folder.";


export const CALIBRATION_REPORT = "Dermus SkinScanner Calibration Report"
export const CALIBRAITON_DATE = "calibration date"
export const SKINAID_VERSION = "skinaid version"
export const FW_VERSION = "fw release"
export const OPTICAL_PARAMS = "Optical parameters"
export const US_PARAMS = "Ultrasound parameters"
export const RGA_RATIO = "R/G/B ratio"
export const EXP_TIME_CALIB_REPORT = "exposure time (ms)"
export const OPTICAL_OFFSET_CALIB_REPORT = "optical offset – x,y"
export const OPTICAL_SHARPNESS_CALIB_REPORT = "optical sharpness"
export const OPTICAL_PIXEL_LENGTH_CALIB_REPORT = "optical pixel length (px/mm)"
export const LED_ILLUMINATION_CALIB_REPORT = "led illumination"
export const US_LEFT_RIGHT_JUMP = "Ultrasound left-right jump extent"

export const LATERAL_POSITION_CALIB_REPORT = "lateral position"
export const STARTING_DEPTH_CALIB_REPORT = "starting depth"
export const SIGNAL_LEVEL_CALIB_REPORT = "Signal lvl"
export const NOISE_LEVEL_CALIB_REPORT = "noise lvl"
export const SNR_CALIB_REPORT = "SNR"

export const PASSED = "passed"
export const FAILED = "failed"

//Forgot psw
export const FORGOT_PSW = "Forgot password?";
export const ADD_VALID_VERIFICATION_CODE = "Please add a valid verification code.";
export const VERIFICATION_CODE = "Verification code (sent via e-mail).";
export const RESET_PSW = "Reset password";

//Add lesion
export const ADD_LESION = "Add lesion";
export const CHOOSE_LESION_LOCATION_TITLE = "Choose lesion location";
export const CHOOSE_LESION_LOCATION =
  "No lesion location is selected. Please choose one by clicking on the body diagram.";

//Add record
export const SCREENSHOT = "Screenshot";
export const NEW_RECORD = "New record";
export const ONLY_OPT = "**ONLY_OPTICAL**";

//Annotation
export const ADD_NEW_ANNOTATION = "Add new annotation";
export const ANNOTATION = "Annotation";
export const ANNOTATE = "Annotate";
export const ANNOTATION_FLAG = "**ANNOTATION**";
export const ANNOTATION_US_FLAG = "**ANNOT_US**";
export const MEASUREMENT_US_FLAG_START = "**MEASURE_US";
export const MEASUREMENT_OPT_FLAG_START = "**MEASURE_OPT";
export const MEASUREMENT_US_FLAG = MEASUREMENT_US_FLAG_START + "**";
export const MEASUREMENT_OPT_FLAG = MEASUREMENT_OPT_FLAG_START + "**";
export const FRAME_TITLE = "Frame";
export const MEASUREMENT_US_FRAME_FLAG =
  MEASUREMENT_US_FLAG_START + "_FRAME_**";
export const MEASUREMENT_OPT_FRAME_FLAG =
  MEASUREMENT_OPT_FLAG_START + "_FRAME_**";
export const INSERT_MEASUREMENT_US_FRAME_FLAG = (frame) =>
  `${MEASUREMENT_US_FLAG_START}_FRAME_${frame}**`;
export const INSERT_MEASUREMENT_OPT_FRAME_FLAG = (frame) =>
  `${MEASUREMENT_OPT_FLAG_START}_FRAME_${frame}**`;

export const ERROR_ANNOTATION = "Error during adding the annotation. Please try again.";
export const SAVE_ANNOTATION = "Save annotation";
export const UNDO = "Undo";
export const FINISH_ANNOTATION_BEFORE_SHARE =
  "Please finish the annotation before sharing.";
export const SHARE = "Share";
export const PDF_REPORT = "PDF report";
export const ADD_SECRET =
  "Please add a secret key (the shared repot will be viewable with this key only).";
export const COPY = "Copy";
export const SEND = "Send";
export const SEND_IN_EMAIL = "send PDF report in email";
export const EMAIL_PDF_REPORT = "Email PDF report";
export const PDF_SENT_SUCCESSFULLY = "PDF sent successfully";
export const DOWNLOAD_IN_PROGRESS = "Download(s) in progress";
export const DOWNLOADING_DATA = "Downloading data...";
export const VIDEO_PROCESSING_IN_PROGRESS = "Video processing in progress."
export const IMAGEPAIR_CONSTRUCTION_IN_PROGRESS = "Image pair construction in progress.";
export const DOWNLOAD_SUCCESSFUL = "Download(s) successful.";
export const DOWNLOAD = "Download";
export const SHARED_INFO = "Send or download the report";
export const ADD_VALID_SECRET = "Please add a valid secret key.";
export const REPORT = "Report";
export const SECRET_PLACEHOLDER = "Secret key...";

export const PDF_WARNING = (id, time) =>
  `Report ID: ${id}. Report generated at ${time}. Warning: the current report is not a standalone medical report, it can only serve as an attachment to an existing medical report. Marker-based optical-ultrasound fusion is currently under evaluation, do not use its results for clinical purposes without a permit from the relevant authorities.`;

export const ANNOTATION_DISTANCE_MEASUREMENT = (dist) =>
  `The largest height difference between the annotation points is ${dist} mm. `;

export const PDF_ALREADY_GENERATED =
  "The report has already been generated previously.";
export const REGENERATE_PDF = "Regenerate report";
export const GENERATING_PDF = (percent) => `Generating report ${percent}%`;

// Opto-fuse
export const IMPORT_OPT = "1. Import optical image.";
export const IMPORT_US = "2. Import ultrasound image set folder.";
export const ADD_US_DEPTH = "3. Add ultrasound image depth in millimeters.";
export const ADD_US_WIDTH = "4. Add ultrasound image width in millimeters.";
export const ADD_VALID_DEPTH = "Please add a valid depth parameter, in millimeters.";
export const ADD_VALID_WIDTH = "Please add a valid width parameter, in millimeters.";
export const UPLOAD_OPTOFUSE = "Upload OptoFuse record";
export const ERROR_US_SIZE =
  "Ultrasound frame size is not equal for all images. Please select a folder containing only ultrasound images of the same dimensions.";


export const SHOW_SEGMENTATION = "Show segmentation";
export const HIDE_SEGMENTATION = "Hide segmentation";
export const SHOW_DEPH_MAP = "Show depth map";
export const HIDE_DEPH_MAP = "Hide depth map";

export const NO_DICOM_NO_SEGMENTATION =
  "This record is not in DICOM format, segmentation supports only DICOM files.";

//Capture
export const FREEZE = "FREEZE";
export const UNFREEZE = "UNFREEZE";
export const SAVE_FRAME = "SAVE FRAME";
export const DISCARD_FRAME = "DISCARD FRAME";
export const SAVE_CINELOOP = "SAVE CINE-LOOP";
export const DROP_CINELOOP = "DROP CINE-LOOP";
export const CLOSE_EXAM = "CLOSE EXAM";
export const X_CLOSE = "X  Close";
export const FRAMES = "frames";
export const LOADING_FRAMES = "loading frames...";
export const LOADING = "Loading...";
export const EMPTY_SAVE =
  "Are you sure you want to close the exam without saving the records?";
export const DROP_DATA =
  "ARE YOU SURE YOU WANT TO LEAVE WITHOUT SAVING EXAM DATA?";
export const RECORDINGS_SAVE =
  "The following records will be added to the database: ";
export const FRAME_SAVED = "Frame is selected for saving.";
export const CINELOOP_SAVED = "Cine-loop is selected for saving.";
export const SKIN_AID = "SkinAid";
export const FRAME = "FRAME";

export const NO_RESULT = "No results found."
export const CLEAR = "Clear"
export const X_RESULTS_FOUND = (x) => `${x} result${x > 1 ? "s" : ""} found.`

export const UPLOADING_DATA = "Uploading data";
export const UPLOADING_HQ_FRAMES = (d) =>
  `We are uploading ${d} high-quality frames. It may take several minutes.`;
export const UPLOADING_FRAMES_AND_CINELOOP = (d) =>
  `We are uploading ${d} frame pair${d > 1 ? "s" : ""} and the cine-loop...`;
export const UPLOADING_FRAMES = (d) => `We are uploading ${d} frame pair${d > 1 ? "s" : ""}...`;
export const UPLOADING_CINELOOP = `We are uploading the cine-loop...`;
export const UPLOADING_FRAMES_CINELOOP_DONE = (d) =>
  `We are uploading ${d} frame pair${d > 1 ? "s" : ""}(the cine-loop is already uploaded)...`;
export const UPLOADING_CINELOOP_FRAMEPAIRS_DONE = (d) =>
  `We are uploading the cine-loop(${d} frame pair${d > 1 ? "s are" : " is"} already uploaded)...`;
export const PRE_PROC_PROGRESS =
  "Data preprocessing and record construction are in progress...";
export const UPLOADING_METADATA = "Uploading metadata...";
export const FAILED_TO_UPLOAD_FILES_WITH_ERROR = (errorMsg) =>
  `Failed to upload files (${errorMsg}).`;
export const FAILED_TO_CREATE_RECORDS_WITH_ERROR = (errorMsg) =>
  `Failed to create record(s) (${errorMsg}).`;
export const SOMETHING_WENT_WRONG_WITH_ERROR = (errorMsg) =>
  `Something went wrong (${errorMsg}).`;
export const UPLOAD_FINISHED_WITH_MEASUREMENT_ERROR =
  "Data uploaded succesfully, however measurement uploading has failed.";
export const CHECK_INTERNET_CONNECTION = "Please check you internet connection.";
export const RETRY_UPLOAD = "Retry";

export const EXPORT_RECORDS = "Export records";
export const EXPORT_RECORDS_IN_PROGRESS = "Exporting the selected framepairs and cineloops...";
export const EMPTY_EXPORT = "No framepair or range was selected";
export const SELECT_EXPORT = "Please select at least one framepair or a cineloop range before exporting!";
export const SELECT_SAVE = "Please select at least one framepair or a cineloop range before saving!";

export const ADD_DERMOSCOPY = "Add dermoscopy";
export const ADD_CLINICAL = "Add clinical";
export const ANONYMOUS_NOTE = "Avoid images that may identify the patient.";
export const NOTE_CROPPED = "Note: uploaded images will be cropped to suit the above aspect ratios.";

export const DELETE_MODALITY = "Delete modality";
export const ARE_YOU_SURE_DELETE_MODALITY = "Are you sure? This cannot be undone."

//Other

export const STALE_SERVE = "Warning: request served from cache, the content on display may not be up-to-date.";

export const TESTING_ONLY = "TESTING ONLY";

export const VERTICAL_OFFSET = "Vertical offset";
export const HORIZONTAL_OFFSET = "Horizontal offset";
export const SET_VGA = "Set VGA";
export const SET_FULL_HD = "Set Full HD";
export const MULTI_FRAME_LOOP = "multi-frame loop";
export const SAVE_DETAILS = (x) => `frame: ${x.us + 1} `;


//Layout
export const REGISTER = "Register"
export const DONT_HAVE_AN_ACCOUNT = "Don't have an account?"
export const LOGIN = "Login"
export const LOGIN_LOWERCASE = "login";
export const SIGNUP_LOWERCASE = "signup";
export const RESET_PASS_LOWERCASE = "resetpassword";
export const NOT_GOOD_EMAIL = "Sorry! Your email wasn’t found in the database. Please check you entered it correctly and try again."
export const LIMIT_EXCEED = "Sorry! Limit exceeded."
export const NOT_GOOD_PSW = "Incorrect password. Please try again."
export const NOT_VERIFIED_YET = "This email is not verified. Please check your inbox for the verification link to access your account."
export const BACK_TO_LOGIN = "Back to login"
export const REGISTER_NEW_ACCOUNT = "Register a new account"
export const SUBMIT = "Submit"

export const PATIENT_LOWERCASE = "patient";
export const LESION_LOWERCASE = "lesion";
export const RECORD_LOWERCASE = "record";
export const SKIN_AID_ANALYTICS_DISCLAMER = "SkinAid analytics for research use only"


export const RETURN_TO_PREV_SCREEN = "Return to the previous screen"
export const INCLUDE_SCREENSHOT = "Include screenshot"

export const P_LOWERCASE = "p";
export const L_LOWERCASE = "l";
export const R_LOWERCASE = "r";

export const SIMULATED_IMAGE_CAPTURE = "SkinScanner simulated image capture"

export const NEW_PATIENT = "new patient";
export const NEW_LESION = "New lesion";

export const SELECT_LESION_POSITION = "Select the area on the body image.";

export const PROFILE_LOWERCASE = "profile";
export const SETTINGS_LOWERCASE = "settings";
export const PREFERENCES_LOWERCASE = "preferences";
export const FEEDBACK = "Feedback";
export const SEND_FEEDBACK = "Send feedback";
export const LOGOUT_LOWERCASE = "logout";
export const ABOUT_LOWERCASE = "about";
export const RELEASE_NOTES_LOWERCASE = "release notes";
export const SUPPORT_LOWERCASE = "support";
export const FULLSCREEN_LOWERCASE = "full screen";

export const CONDITIONS = "Conditions"
export const DESCRIPTION = "Description"
export const FEEDBACK_DESCRIPTION_PLACEHOLDER = "Please describe your issue in a few sentences. Was this a one-off issue, or recurring?"
export const FEEDBACK_CONDITION_PLACEHOLDER = "Please describe what you were doing before this happened."

export const AUTO_LOG_DATA = "We will automatically log the following data:"

export const WELCOME_TO_SKINAID = "Welcome to SkinAid!"
export const WELCOME_TO_SKINSCANNER = "Welcome to SkinScanner!"

//POPUP
export const THIS_LEVEL_OR_SCRATCH = "Do you want to add new record to this level or start from scratch?";
export const THIS_LEVEL = "This level";
export const FROM_SCRATCH = "From scratch";

//User preferences
export const USER_PREFERENCES = "User preferences";
export const GENERAL_BEHAVIOUR = "General behaviour";
export const SHOW_INTENSITY = "Show RGB/intensity values on annotation"
export const SET_ALL_DIALOG_VISIBILITIES = "Set all dialog visibilities";
export const DYNAMIC_RULER_COLORING = "Dynamic ruler coloring";
export const INCLUDE_OPT_US_GUIDELINE_TEXT = "Include Opt-US guideline in downloaded images";
export const INCLUDE_HEADER_IN_DOWNLOAD_IMAGES = "Include Header in downloaded images"
export const CAPTURE_SCREEN = "Capture screen";
export const SHARPEN_OPTICAL_IMAGE = "sharpen optical image"
export const SHOW_US_CENTER = "display ultrasound image center"
export const PAN_UP_US_IMG = "Pan up ultrasound image to hide membrane";
export const RECORD_LOOP_LENGTH = "Recorded loop length"
export const BETWEEN_VALUES = "between 0 and 5 (mm)";
export const VIRTUAL_EFFECT = "* virtual effect without deleting data";
export const PRESET_GAIN = "Preset gain on modalities";
export const USG = "USG";
export const OPT = "optical";
export const ULTRASOUND = "ultrasound";
export const SET_MARKER_SIZE = "Set annotation marker size";
export const CATALOG = "Catalog";
export const START_FROM_CURRENT_LEVEL = "Start capture from current level";
export const CATALOG_TICKETS_LARGE_VIEW = "Catalog tickets large view by default";

export const PASSWORD_RESET = "Password reset"
export const ENTER_EMAIL_TO_RESET_PSW = "Enter your email below to receive instructions to reset your password."
export const INCORRECT_EMAIL = "Incorrect email"
export const EMAIL_SENT_TO_RESET_PSW = "Success! We sent you an email with a verification code."
export const RESEND_VERIFICATION_CODE = "Resend code"
export const CONTACT_SUPPORT = "Contact support in email"
export const ENTER_CODE = "Enter code"
export const ENTER_PSW = "Enter password"
export const ENTER_EMAIL = "Enter email"
export const SET_NEW_PSW = "Set yout new password."
export const PSW_REST_SUCCESS = "Password successfully updated."
export const INTENSITY = "Intensity"
export const RGB = "RGB"


export const VERIFY_REGISTRATION = "Verify your registration"
export const PLEASE_CHECK_YOUR_EMAIL = "Please check your "
export const INBOX_FOR_VERIFICATION = " inbox for our verification email."
export const RESEND_EMAIL = "Resend email"
export const CHANGE_EMAIL_ADRESS = "Change email adress"
export const YOU_HAVE_3_DAYS_TO_ACTIVATE = "You will have 3 days to verify your account, after that you will need to contact support to recover your progress."

export const SUCCESSFUL_VERIFICAITON = "Successful verification. Data saved."
export const VERIFICATION_ERROR = "Verification error. "
export const ONE_LAST_THINGS = "One last thing to do:"
export const RECEIVE_TRAINING = "Before you can start using our solution, you need to receive training."

export const WATCH_TRAINING_VIDEOS = "Watch our training videos on our website (25 min.)"
export const COMPLETE_LATER = "I will complete this before my first use"
export const I_ALREADY_VERIFIED = "I already verified my email"

export const YOUR_EMAIL_IS_ALREADY_VERIFIED = "Your email address is already verified."
export const THANK_YOU_FOR_YOUR_REGISTRATION = "Thank you for registering with us!"
export const LOGIN_IN_TO_SKINAID = "Login to SkinAid"
export const VERIFICATION = "Verification"
export const VERIFICATIONE_EXP = "Verification expired"

export const LINK_VERIFICATION_EXP = "The email verification link has expired."
export const CONTACT_SUPPORT_TO_RECOVER = "Please contact support to receive instructions to recover the registration process."

export const RELEASE_NOTES = [
  { version: "v1.0.0", date: "01-09-2020", note: ["First release"] },
  {
    version: "v2.0.0",
    date: "01-01-2021",
    note: [
      "DICOM compatibility added",
      "Annotation added to DICOM file",
      "'Multiple use is logged in' bug fixed",
    ],
  },
  {
    version: "v2.1.0",
    date: "19-04-2021",
    note: [
      "Navigation bar updated",
      "ICD diagnosis option added",
      "Patient ID input removed; Patient ID is now automatically generated",
      "'About' menu added",
      "'Settings' menu added",
      "Predefined tags introduced for 'Notes' fields",
    ],
  },
  {
    version: "v3.0.0",
    date: "25-06-2021",
    note: ["Opto-fuse functionality added"],
  },
  {
    version: "v3.0.1",
    date: "01-07-2021",
    note: [
      "New UI design implemented",
      "Opto-fuse modified to demo version",
      "PDF report generation improved",
    ],
  },
  {
    version: "v3.1.0",
    date: "10-08-2021",
    note: ["Segmentation feature added"],
  },
  {
    version: "v3.2.0",
    date: "24-08-2021",
    note: ["US lesion measurements added"],
  },
  {
    version: "v4.1.0 - R2206",
    date: "04-07-2022",
    note: ["Calibration page added", "Parameters are now loaded from the EEPROM of the imaging device (Dermus SkinScanner) on the capture page"],
  },
  {
    version: "v4.2.0 - R2207",
    date: "26-07-2022",
    note: [
      "Bulk EERPOM read implemented",
      "USB Failure WatchDog and reset option added on '/usb-settings' page",
      "FPGA FW update option added on '/usb-settings' page",
      "STM32 FW update added on '/usb-settings' page",
      "'Optical image size update' bug fixed",
      "US A-line length set to 1250 and now shows a 9 mm region only",
      "Measurement statistics diagram added to single lesion pages",
      "Cine-loop display functionality added",
    ],
  },
  {
    version: "v4.3.0 - R2208",
    date: "02-09-2022",
    note: [
      "Bugfix: 'Optical stream starts before integration time is sent to host' - SKINAID-427",
      "Bugfix: 'Unnecessary paranthesis on Close Exam window' - SKINAID-418",
      "Bugfix: 'Frame numbers start at zero when saving image pair and cineloop' - SKINAID-422",
      "Progress bar for device communication initialization added - SKINAID-417",
      "After registering a new patient, SkinAid now goes to the Lesion Selector page - SKINAID-420",
    ],
  },
  {
    version: "v4.4.0 - R2209",
    date: "14-09-2022",
    note: [
      "Capture screen updated on mobile host devices",
      "Handling inputs from device save button added",
      "SkinAid now goes to the capture screen after successfully saving records",
    ],
  }, {
    version: "v4.5.0 - R2210",
    date: "28-11-2022",
    note: [
      "Thickness measurement upgraded",
      "Option to full screen mode on capture screen added",
      "Saved frames/cineloop are now indicated by the corresponding icons changing color to red",
      "Device information added to record",
      "Capture page linked to single Lesion pages: captures can now be initialized from single lesion pages of SkinAid",
      "Firmware versions are now displayed in the web-app (/usb-settings)"
    ],
  }, {
    version: "v5.0.0 - R2211",
    date: "28-11-2022",
    note: [
      "Optical image stability improved",
      "User is now notified about offline/online changes in internet connection",
      "Saving process (from capture page) upgraded: cancelling option, progress bar added",
      "OK button added for error modal",
      "Linking Capture screen to single lesion pages further improved",
      "Registration process upgraded",
      "Bugfix in Firmware versions display (/usb-settings)",
      "Bugfix: 'US image brightness is different on Capture vs Record review screen'"
    ],
  }, {
    version: "v5.1.0 - R2212",
    date: "20-12-2022",
    note: [
      "Support page added",
      "Cine-loop section selecting and saving functionality added",
      "Option added after successful saving: Go to capture page or review recently saved records",
      "Automatic Watchdog reset added, with user notification on capture screen",
      "Capture screen awake mode added (sleeping mode of host devices disabled when being on the capture page)",
      "DICOM image storing compression improved",
      "Bugfix: order of cine-loop frames in record review screen",
      "Lesion location image is not pixelated anymore when zooming in",
      "Bugfix: lesion location position fix",
      "Diagnosis specification added into record saving workflow"
    ],
  }, {
    version: "v5.1.1 - R2301",
    date: "30-01-2032",
    note: [
      "Measurement on optical image on record review screen",
      "Display all three measurement distances on record review screen",
      "Display red arrow (direction) on record review screen"
    ]
  }, {
    version: "v5.2.0 - R2302",
    date: "30-02-2032",
    note: ["New segmentation algorithm",
      "Segmentation is ran automatically for all newly captured records",
      "New record screen (more similar to capture screen)",
      "Capability to export framepair(s) and cineloop from a cineloop",
      "Bugfix: SCANU-1206, EEPROM data read properly after calibration",
      "Show cineloop icon next to cineloop recording",
      "Show us image with grayscale colormap when segmentation overlay is switched on",
      "New slider UI"

    ],
  }, {
    version: "v5.3.0 - R2303",
    date: "31-03-2023",
    note: [
      "FW version display fix (SCANU-1207)",
      "New capture UI design + annotation on capture screen",
      "More GPU support",
      "Measurement and annotation handle in db",
      "Decrease cache time",
      "Ability to receive envelopes",
      "Login page fix (SKINAID-601)",
      "New navigation bar"
    ],
  }, {
    version: "v5.4.0 - R2304",
    date: "9-05-2023",
    note: [
      "Pan/Zoom/Gain are saved as metadata info",
      "Pan/Zoom loaded on record screen",
      "Store US data in 16bit",
      "Capture enhancement",
      "New login and registration UI",
      "Add user preferences screen"
    ],
  }, {
    version: "v5.5.0 - R2305",
    date: "02-06-2023",
    note: [
      "Demo mode on skinaid.app/capturedemo",
      "New annotation marker",
      "Free text note option on capture screen",
      "Icons changed and redesigned in Capture screen",
      "Custom slider for tuning shows difference from default values",
      "Rulers and number of images are upgraded in Capture screen",
      "Pan & zoom added for optical images also",
      "New release notification added to each user at first login in Patient and Capture screen",
      "USB related messages redesigned"
    ],
  }, {
    version: "v5.6.0 - R2306",
    date: "04-07-2023",
    note: [
      "New Record UI (similar to Capture) ",
      "Add clinical and dermoscopy modality to record",
      "12mm US depth",
      "Decimated A-lines",
      "Get motorpos from A-line",
      "Optical gain discretized"
    ],
  }, {
    version: "v5.7.0 - R2307",
    date: "31-07-2023",
    note: [
      "Calibration framework updated",
      "AIO firmware update",
      "Developer mode for Capture screen",
      "Replay cineloops in Record screen",
      "Bugfixes",
    ],
  }, {
    version: "v5.8.0 - R2308",
    date: "31-08-2023",
    note: [
      "New UI for catalog",
      "Saving calibration to cloud and exporting PDF report",
      "Save HW serial and FW info to Records",
      "Segmentation metrics on Record screen",
      "Explicit edit status for filters",
      "Automatic exposure&WB algorithms",
    ],
  }, {
    version: "v5.9.0 - R2309",
    date: "27-09-2023",
    note: [
      "Option for clearing the filters, show active filtering and # search results",
      "Local ids for lesion and record",
      "New filter options for record",
      "No calibration message on capture and record",
      "Extend demo for catalog: https://demo.skinaid.app",
      "Start new capture dialog (current level or from scratch)",
      "Optical integration time as optical gain (instead of sw gain) and apply similar softgain in record",
      "Fix red triangle behavior on the optical image",
      "'Rovatka' coloring from preferences"
    ],
  }, {
    version: "v6.0.0 - R2310",
    date: "03-11-2023",
    note: [
      "New navigation bar",
      "Batch operation: delete and favourite",
      "Start capture from patient level",
      "Install app pop-up",
      "Add classification score",
      "Draw lesion max thickness and length",
      "Add edit lesion location",
      "Seperate domain for capture (dermus.app) and catalog (skinaid.app)",
      "Optical and US frame synchronization and optimization"
    ],
  }, {
    version: "v6.1.0 - R2311",
    date: "15-12-2023",
    note: [
      "Add HR vs HDepth Option",
      "Add feedback option",
      "Add automatic fw update",
      "Nr of lesions on patient list",
      "Ability to search-filter for the content of Free-text Notes",
      "Add OPT image sharpening",
      "Catalog large entry ticket",
      "Ability to search-filter for the content of Free-text Notes",
      "Interactive image display during cineloop cropping",
    ],
  }, {
    version: "v6.2.0 - R2401",
    date: "08-02-2024",
    note: [
      'Reformed downloading process (cineloop download option)',
      "Option for RGB-Intensity values on annotations",
      "Revised Diagnoses definition and BNO codes",
      "Product switch button on Logi Page",
      "Fix SCANU-1949: memory leak",
      "Fix SCANU-1957: drop incorrect length packet"
    ],
  }, {
    version: "v6.3.0 - R2403",
    date: "09-04-2024",
    note: [
      "Volume estimation for lesions",
      "Detection of an unsupported OS, host device, or browser",
      "Ability to return to existing scan after any activity in the side menu",
      "Ability to filter favorite items of all catalog levels",
      "Search for dates (with YYYY format) both Examination dates and Birth dates",
      "Minor UI enhancements",
    ],
  }, {
    version: "v6.4.0 - R2405",
    date: "11-06-2024",
    note: [
      "Speed-up catalog",
      "Calibration enhancement",
      "11 bit FW bug",
      "FW update based on RC3/4",
      "Animal patient type",
    ],
  }, {
    version: "v6.4.1 - R2405P1",
    date: "02-07-2024",
    note: [
      "Hotfix - Calibration enhancement",
      "Hotfix - Old dicom image display",
    ],
  }, {
    version: "v6.4.2 - R2405P2",
    date: "05-07-2024",
    note: [
      "Hotfix - Error back iamge of patient",
      "Hotfix - New patient stuck",
    ],
  }, {
    version: "v6.4.3 - R2405P3",
    date: "10-07-2024",
    note: [
      "Hotfix - Previously registered patient data at New patient in Upload wizard",
      "Hotfix - Mixed up human and animal patients in Upload wizard",
      "Hotfix - Inconsistent Back button behaviour in Upload wizard",
    ],
  }, {
    version: "v6.5.0 - R2407",
    date: "29-07-2024",
    note: [
      "Clinical and dermoscopy modalities enhancement",
      "Calibration fixes",
      "Maximum framenumber optionally increased",
      "Include Header in downloaded images",
      "Ultrasound center line on both modalities added"
    ],
  }, {
    version: "v6.6.0 - R2409",
    date: "30-09-2024",
    note: [
      "Doppler mode",
      "Frame counter slider enhancement",
      "Search filter updated"
    ],
  }, {
    version: "v6.6.1 - R2409P1",
    date: "24-10-2024",
    note: [
      "Hotfix - Doppler Iphone compatibility",
      "Hotfix - Cineloop download",
      "Hotfix - Fix fir filter for doppler",
    ],
  }, {
    version: "v6.6.2 - R2409P2",
    date: "31-10-2024",
    note: [
      "Hotfix - Segmentation layer selection",
    ],
  }
];


export const WHITE_BALANCE_R = "White balance R";
export const WHITE_BALANCE_G = "White balance G";
export const WHITE_BALANCE_B = "White balance B";
export const LENGTH_OF_LAT_REF = (ind) => `Length of ref ${ind} (in mm)`;
export const INTEGRATION_TIME = "Integration time";
export const MILI_SEC = "ms";
export const CLICK_TO_ADD_REG_POINTS =
  "Click on the optical image to add registration points.";
export const AUTO_WHITE_BALANCE = "Auto-set white balance";
export const AUTO_LED_AND_INT =
  "Auto-set integration time and LED illumination";
export const NEXT = "Next";
export const OKEY = "Okey";
export const COMPLETE = "Complete step";
export const ALREADY_COMPLETED = "Already completed";
export const US_CALIB_AND_REG_NECESSARY =
  "Both US calibraiton and regisration are necessary.";
export const SAVE_COMPLETED_STEPS = "Save completed steps";
export const TO_OVERVIEW = "To Overview";


//CALIBRATION
export const DEVICE = "device";
export const FW_RELEASE = "fw release";
export const LAST_CALIBRATION = "last calibration";
export const WRITE_EEPROM = "WRITE_EEPROM";



// STEP1
export const AUTO_CENTER = "Auto center";
export const CURRENT_OPTICAL_PIXEL_LENGTH_MM = "Current optical pixel length/mm: ";
export const X_SHIFT = "X shift: ";
export const Y_POSITION_PX = "Y position (px):"


// STEP2
export const LED_ILLUMINATION = "LED illumination";
export const CHANGING_NOT_ADVISED = "(changing not advised)";
export const SET_OPTICAL_PARAMETERS = "Set optical parameters";
export const EXPOSURE_TIME = "Exposure time (ms)";
export const BRIGHTNESS_UNIFORMITY = "Brightness uniformity";
export const WHITE_BALANCE = "White balance";

// STEP3
export const MEASURE_SHARPNESS = "measure sharpness";
export const RISE_DISTANCE = "rise distance: ";

export const FWHM_X_VALUES = "FWHM horizontal values";
export const FWHM_Y_VALUES = "FWHM vertical values";

export const FWHM_MEAN = "Mean FWHM";
export const FWHM_MEDIAN = "Median FWHM";


// STEP4
//export const PRINTED_TARGET_DIAMETER = "Printed target diameter (mm):";
//export const AUTO_SCALE = "Auto scale";
export const TARGET_X_Y_LENGTH_MM = "target x/y length mm (optional)";
export const OPTICAL_PIXEL_LENGTH = "optical pixel length (changing not advised)";
export const USAMP0 = "(USAMP0: ";

// STEP5
export const ADVISED_POSITION = "advised position of the target surface";
export const DEPTH = "Depth";
export const DEPTH_MM = "Depth (mm)";
export const MAX_INTENSITY = "Max intensity";
export const SET_GAIN_FOR_MAX_INTENSITY = "Set gain for max intensity";
export const GAIN = "Gain (dB)";
export const GAIN_MODE = "gain mode: ";
export const PICK_WIRE_TARGET = "Pick wire target";
export const FWHM_X = "FWHM x:";
export const FWHM_Z = "FWHM z:";

// STEP6
export const NOISE_LEVEL = "Noise lvl: ";
export const PICKED_LOCATION_INTENSITY = "Picked location intensity: ";
export const SNR = "SNR (dB): ";

// STEP7
export const LATERAL_POSITION = "Lateral position (step)"
export const NRMSE = "Nrmse";
export const THRESHOLD = "Threshold";


// SUMMARY STEP
export const CALIBRATION_SUMMARY = "Calibration Summary";
export const PREVIOUS_PARAMS = "The previous parameters:";
export const NEW_PARAMS = (d) => `The new parameters (at ${d}):`;
export const UNSELECT = "You can unselect any parameter you wish to keep at the previous value.";

export const OPTICAL_UPPERCASE = "OPTICAL";
export const ULTRASOUND_UPPERCASE = "ULTRASOUND";

export const OPTICAL_OFFSET = "Optical offset";

export const CIRCULAR_BRIGHTNESS = "Circular brightness";
export const FWHM = "FWHM";
export const INTENSITY_LEVEL = "Intensity lvl (%)";
export const SIGNAL = "signal";
export const NOISE = "noise";
export const ULTRASOUND_IMAGE_CENTERING = "Ultrasound image centering";
export const OPTICAL_SHARPNESS = "Optical sharpness";


export const LOAD_DEFAULTS = "Load defaults";
export const SAVE_CHANGES = "Save changes";
export const EXPORT_REPORT = "Export report to PDF";
export const CALIBRATION_PDF_REPORT = (serialNumber, date) => `Calibration_report_${serialNumber}_${date}.pdf`



export const CANNOT_PARSE_USB_RESPONSE = "Cannot parse USB response";
export const ERROR_DURING_USB_COMMUNICATION =
  "Error during communicating with the device";
export const ERROR_DURING_EEPROM_WRITE_TRY_AGAIN =
  "Error during saving the calibration results. Please try again.";
export const CANNOT_READ_EEPROM = "Cannot read EEPROM";
export const USB_FAILURE_WATHCDOG = "USB failure watchdog";
export const ERRPR_DURING_UPLOAD_FPGA_FW =
  "Error during update firmware, try again please!";
export const DATA_SENDING_VERIFICATION_FAILED =
  "The configuration or verification is failed.";
export const VALIDATION_TIMEOUT = "Validation timeout";
export const ERROR_DURING_RUNNING_BRIGHTNESS_ALGORITHM = "Communication error during running algorithm"

export const CHOOSE_RECORDS = "Choose which records to add to the database.";
export const CHOOSE_RECORDS_TO_EXPORT = "Choose what to export into new records.";
export const SELECTED_FRAMES = " selected frames";
export const SELECTED_FRAME = " selected frame";
export const CURRENT_FRAME = " current frame";
export const EXIT_CAPTURE = "Note: you are exiting Capture mode.";
export const BACK_TO_VERTICAL = "Please remember to turn your phone back to vertical mode after saving.";
export const NOTE_EXPORT = "Note: all measurements and notes made on the above frames will be exported into the new records.";
export const SUCCESS_SAVED = "Success! Your work is saved.";
export const CHECK_SAVED = "Check the saved recording";
export const RETURN_CURRENT = "Return to current recording";
export const START_NEW_FROM_SCRATCH = "Start new from scratch";


export const CHOOSE_WHAT_TO_DOWNLOAD = "Choose what to download:";
export const SELECTED_FRAMES_AS_PNG = "selected frame(s) as PNG";
export const CURRENT_FRAME_AS_PNG = "current frame as PNG";
export const MULTIFRAME_LOOP_AS_VIDEO = "loop as MP4 video";
export const NOTE_LOOPS = "Note: longer loops may take some time to download.";
export const OPTIONAL = "Optional:";
export const INCLUDE_FOLLOWING_PATIENT_DATA = "Include the following patient data";
export const NOTE_WE_DONT_STORE = "Note: we do not store this patient data. This information will only be stored in the downloaded file(s)";
export const NAME_OF_CURRENT_PATIENT = "Name of the current patient";
export const PATIENT_INSURANCE_NR = "Patient's Insurance Nr.";



export const VALID_FW_IS_NECESSARY = (type) =>
  `Use the appropriate Main FX3 firmware to update ${type} firmware.`;

export const CONNECTION_ESTABLISHED = "Connection established.";
export const CONNECTIONS_LOST_OR_SKINAID_DOWN = "Connection lost or SkinAid is unavailable.";
export const CONNECTION_ERROR_RECONNECT_THE_DEVICE = "Connection Error: Please reconnect the device."

export const LESION_NOT_FOUND = "Lesion is not found on the US image."
export const NO_SEGMENTATION = "There is no segmentation."
export const SEGMENTATION_NOT_READY = "Please wait until segmentation is complete.";

export const ERROR_UPLOAD_CLINICAL = "Error occured during clinical image upload."
export const ERROR_UPLOAD_DERMOSCOPY = "Error occured during dermoscopy image upload."
export const ERROR_DURING_SEND_FEEDBACK = "Error during send feedback."

export const REGISTRATION_STEPS = ["Profession", "User data", "Validation"]
export const REGISTRATION = "Registration"

export const THANK_FEEDBACK = "Thank you for your feedback."
export const RESPOND_SHORTLY = "We will respond shortly."
export const FEEDBACK_DISABLED = "Feedback reporting is disabled in Demo mode";