import { Box, CircularProgress, } from "@mui/material"
import { neutral40, primary60, primary95, secondary50, secondary90 } from "../../../Themes/dermusTheme";
import styled from "@emotion/styled";

const StyledButton = styled.button`
cursor: pointer;
background-color: rgba(0,0,0,0);
color: ${secondary50};
font-weight: bold;
border:0;
min-width: 100px;
box-shadow: none;
border-radius: 6px;
padding: 12px 8px 12px 8px;
&:hover {
  background-color: ${primary95};
  color: ${secondary50};
}
&:active{
    background-color: ${secondary90};
    color: ${secondary50};
}
&:disabled{
    background-color: rgba(0,0,0,0.0);
    color: ${neutral40};
}`
const TextButton = (props) => {
    const { progress, sx, ...restProps } = props;
    return <Box sx={{ m: 1, position: 'relative', margin: 0, ...sx }}>
        <StyledButton
            {...restProps}
            disabled={progress || restProps.disabled}>
            {restProps.children}
        </StyledButton>

        {progress && (
            <CircularProgress
                size={24}
                sx={{
                    color: primary60,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
        )}
    </Box>



}
export default TextButton