import * as action from "../actions/authenticationActions";

/**
 * Set logint start action creator
 */
export const loginStartActionCreator = () => {
  return {
    type: action.LOGIN_START,
  };
};
/**
 * Set sign up start  action creator
 */
export const singupStartActionCreator = () => {
  return {
    type: action.SIGN_UP_START,
  };
};
/**
 * Set reset psw action creator
 */
export const resetPswStartActionCreator = () => {
  return {
    type: action.RESET_PSW_START,
  };
};
/**
 * Set login success action creator
 */
export const loginSuccessActionCreator = (session, psw, signedInUser) => {
  return {
    type: action.LOGIN_SUCESS,
    payload: {
      session,
      psw,
      signedInUser,
    },
  };
};
/**
 * Set user preference
 */
export const addUserPreferencesActionCreator = (userPreferences) => {
  return {
    type: action.USER_PREFERENCES,
    payload: {
      userPreferences
    },
  };
};
/**
 * Set list ticket size
 */
export const addListTicketSizeActionCreator = (listTicketSize) => {
  return {
    type: action.LIST_TICKET_SIZE,
    payload: {
      listTicketSize
    },
  };
};
/**
 * Set user info
 */
export const addUserInfoActionCreator = (userInfo) => {
  return {
    type: action.USER_INFO,
    payload: {
      userInfo
    },
  };
};
/**
 * Set sign up success action creator
 */
export const signupSuccessActionCreator = (info) => {
  return {
    type: action.SIGN_UP_SUCESS,
    payload: { info },
  };
};
/**
 * Set reset pse success action creator
 */
export const resetPswSuccessActionCreator = () => {
  return {
    type: action.RESET_PSW_SUCESS,
  };
};
/**
 * Set login failed action creator
 */
export const loginFailedActionCreator = (error) => {
  return {
    type: action.LOGIN_FAILED,
    payload: { error },
  };
};

/**
 * Set sign up failed action creator
 */
export const signupFailedActionCreator = (error) => ({
  type: action.SIGN_UP_FAILED,
  payload: {
    error,
  },
});

/**
 * Set reset psw failed action creator
 */
export const resetPswFailedActionCreator = (error) => ({
  type: action.RESET_PSW_FAILED,
  payload: {
    error,
  },
});

/**
 * Set lget session action creator
 */
export const getSessionErrorActionCreator = () => ({
  type: action.GET_SESSION_ERROR,
});
/**
 * Set no user action creator
 */
export const noUserActionCreator = () => ({
  type: action.NO_USER,
});

/**
 * Set set session  action creator
 */
export const setSessionActionCreator = (session) => ({
  type: action.SET_SESSION,
  payload: {
    session,
  },
});

/**
 * Set logout action creator
 */
export const logoutActionCreator = () => {
  return {
    type: action.LOGOUT,
  };
};

/**
 * Set clear auth state action creator
 */
export const clearAuthStateActionCreator = () => {
  return {
    type: action.CLEAR_AUTH_STATE,
  };
};

/**
 * Set teset psw confirm action creator
 */
export const resetPwsConfirm = () => {
  return {
    type: action.RESET_PSW_CONF_REQ,
  };
};
