class Note {
  /**
   * Constructor
   * @param {String} date date of note
   * @param {String} doctorName dcotor name who wrote note
   * @param {String} email email of doctor who wrote it
   * @param {String} id id of note
   * @param {String} note note
   * @param {String} recordId record id
   * @param {String} remote remote
   */
  constructor(date, doctorName, email, id, note, recordId, remote) {
    this.date = date;
    this.doctorName = doctorName;
    this.email = email;
    this.id = id;
    this.note = note;
    this.recordId = recordId;
    this.remote = remote;
  }
}
export default Note;
