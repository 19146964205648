class Annotations {
    /**
     * Constructor
     * @param {Object} annotation annotation
     */
    constructor(
        annotation,
    ) {
        this.id = annotation.id
        this.points = annotation.data.points.filter(item => Array.isArray(item))
        this.date = annotation.date
    }
}
export default Annotations;
