// CLOUD
export const ALL_PATIENTS_EP = "/patient";
export const GET_PATIENT_EP = "/patient";
export const ADD_PETIENT_EP = "/patient";
export const UPDATE_PATIENT_EP = "/patient";
export const ALL_LESION_EP_PRE = "/patient";
export const ALL_LESION_EP_POST = "/lesion";
export const ALL_LESIONS = "/lesion";
export const ALL_RECORD_EP_PRE = "/lesion";
export const ALL_RECORD_EP_POST = "/record";
export const UPDATE_RECORD_EP = "record";
export const RECORD_EP = "/record";
export const RECORD_HARD_DELETE_EP = "/record/hard_delete";
export const RECORD_DELETE_ADDITIONAL_MODALITY_EP = "/record/delete_additional_modality";
export const REPORT_EP = "/report";
export const SEND_REPORT_EP = "/send-report";
export const MAKE_EP = "/make";
export const IMAGE_EP = "/image";
export const URLS_KEYS_EP = "/record/urls"
export const UPDATE_LESION_EP = "/lesion";
export const ADD_RECORD_NOTE_EP_PRE = "/record";
export const ADD_RECORD_NOTE_EP_PRO = "/note";
export const ADD_LESION_EP = "/lesion";
export const RECORD_THUMBNAIL_AND_DICOM_EP = "/dicom/record";
export const RECORD_ANNOTATION_EP = "/annotation";
export const ICD_TOKEN_EP = "/ICDToken";
export const NOTE_TAGS = "/note-tags";
export const SIGN_UP_EP = "/signup";
export const LOGOUT_EP = "/logout";
export const LOGIN_EP = "/login";
export const USER_PREFERENCES_EP = "/user-preferences"
export const USER_EP = "/user";
export const FORGOT_PSW_EP = "/forgot-password";
export const CHANGE_PSW_EP = "/user/modify-password";
export const CHANGE_EMAIL_EP = "/user/modify-email";
export const REGISTRATION_EP = "/registration";
export const PING_EP = "/ping"
export const TOOL_ACTION_EP = "/tool-action"
export const RESEND_VERIFICATION_EMAIL_EP = "/resend-verification"
export const VERIFY_EMAIL_EP = "/verify-email"
export const VERIFY_TUTORIAL_EP = "/verify-tutorial";
export const CAPTURE_VISIT_EP = "/capturedemo-visit"
export const TOOL_ACTION_MODIFY_EP = "/direct-tool-action"
export const DEVICE_EP = "/device"
export const FW_VERSIONS_EP = "/fw-versions"
export const FW_EP = "/fw"
export const CREATE_DEMO_DATA_EP = "/create-demo-data"
export const CALIBRATION_EP = "/calibration"
export const SEND_FEEDBACK_EP = "/send-feedback";
export const UPLOAD_FEEDBACK_FILE_EP = "/upload-feedback-file";

export const MOVE_RECORDS_EP = "/record/move"
// ICD
export const ICD_EP = "https://id.who.int/icd/release/11/2020-09/mms/codeinfo/";

// ALGO CLOUD
export const ALGO_SCALE_DETECTION_EP = "/optical-scale-transform";
export const ALGO_DEPTH_INFO_EP = "/opto-fuse";
export const ALGO_US_SEG_EP = "/us-segmentation";
export const ALGO_METRIC_EP = "/calculate-metrics";
export const GET_US_SEG_EP = "/us-segmentation-data";
export const ALGO_DATA_US_SEG_EP = "/data-us-segmentation";
export const ALGO_US_MEASUREMENTS = "/us-measurements";
