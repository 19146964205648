import { Box, Checkbox } from "@mui/material"
import { dermusErrorColor, neutral50 } from "../../../Themes/dermusTheme"
import { IndeterminateCheckBoxIcon } from "../Icons/Icons"

const ZIndexProps = { zIndex: 0 }
const PrimaryCheckbox = (props) => {
    const { ZIndex, onChange, checked, label, sx, disabled, ...restProps } = props
    return <Box sx={{ display: "flex", flexDirection: "row", ...sx }}>
        <Checkbox
            indeterminateIcon={<IndeterminateCheckBoxIcon sx={{ color: neutral50 }} />}
            disabled={disabled}
            sx={{ margin: "0px", padding: "0px", ...(ZIndex ? ZIndexProps : {}) }}
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
            color={dermusErrorColor}
            {...restProps}
        /><Box sx={{ marginLeft: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {label}
        </Box>

    </Box >
}

export default PrimaryCheckbox

