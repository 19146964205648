import { Component } from "react";
import axios from "axios";
import { SEND_FEEDBACK_EP } from "../../API/endPoints";
import { BASEURL } from "../../API/dermusAxios";
import qs from "qs"

import platform from "platform";
import { createFeedbackBody } from "../../API/dataRestructure";
import { VERSION } from "../../Constants/Constants";
import { getLocalNow } from "../../utility/dateConversion";
import { Box } from "@mui/material";
import { withRouter } from 'react-router-dom';
import { LOGS } from "../../utility/functions";

const info = {}
const isWin11 = async () => {
  const tmp = await navigator.userAgentData.getHighEntropyValues(["platformVersion"])
  info.opSys = (parseInt(tmp.platformVersion.split('.')[0]) >= 13) ? "Windows 11" : "Windows 10"
}
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    info.session = props.session
    info.userInfo = props.userInfo
    info.opSys = platform.os.toString()
    if (info.opSys?.startsWith("Windows 10")) {
      isWin11()
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidUpdate(prevProps) {
    info.session = prevProps.session
    info.userInfo = prevProps.userInfo
  }
  componentDidCatch(error, errorInfo) {
    if (!window.location.origin.includes("localhost:"))
      axios.post(BASEURL + SEND_FEEDBACK_EP, qs.stringify(
        createFeedbackBody(getLocalNow(),
          info.userInfo.prefix + " " + info.userInfo.name + " " + info.userInfo.surname,
          VERSION,
          "", "",
          info.opSys, platform.name + " " + platform.version,
          window.location.href,
          error.toString(), errorInfo.componentStack, LOGS.join("\\n"), "dev")), {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: info.session.getAccessToken().jwtToken,
        },
      })
  }



  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <Box sx={{ margin: "20px" }}>
          <p>Something went wrong:</p>
          {error.message && <span>{error.message}</span>}
        </Box>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
