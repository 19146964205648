import { DEFAULT_CONFIG, MODE, OPT_CONFIG } from "../../Imaging/useImage_v2";

class VolumeEstimation {
    /**
     * Constructor
     * @param {Object} volumeEstimation volume estimation
     */
    constructor(
        volumeEstimation,
    ) {
        this.id = volumeEstimation.id
        //US
        let len = 0;
        this.us = {}
        if (Array.isArray(volumeEstimation.data.us)) {
            this.us.points = volumeEstimation.data.us.map(item => [
                { x: item.x1, y: item.y1 },
                { x: item.x2, y: item.y2 }])
            this.us.distances = volumeEstimation.data.us.map(item => (
                { dy: item.dy, dx: item.dx, d: item.d }))
            len = volumeEstimation.data.us.length
        } else {
            const item = volumeEstimation.data.us
            this.us.points = [[{ x: item.x1, y: item.y1 }, { x: item.x2, y: item.y2 }]]
            this.us.distances = [{ dy: item.dy, dx: item.dx, d: item.d }]
            len = 1
        }
        this.us.colors = {
            colors: DEFAULT_CONFIG[MODE.VOLUME_ESTIMATION].colors.toReversed().filter((item, ind) => ind < len),
            options: DEFAULT_CONFIG[MODE.VOLUME_ESTIMATION].colors.filter((item, ind) => ind < (DEFAULT_CONFIG[MODE.VOLUME_ESTIMATION].colors.length - len))
        }

        //OPT
        len = 0;
        this.opt = {}
        if (Array.isArray(volumeEstimation.data.opt)) {
            this.opt.points = volumeEstimation.data.opt.map(item => [
                { x: item.x1, y: item.y1 },
                { x: item.x2, y: item.y2 }])
            this.opt.distances = volumeEstimation.data.opt.map(item => (
                { dy: item.dy, dx: item.dx, d: item.d }))
            len = volumeEstimation.data.opt.length
        } else {
            const item = volumeEstimation.data.opt
            this.opt.points = [[{ x: item.x1, y: item.y1 }, { x: item.x2, y: item.y2 }]]
            this.opt.distances = [{ dy: item.dy, dx: item.dx, d: item.d }]
            len = 1
        }
        this.opt.colors = {
            colors: OPT_CONFIG[MODE.VOLUME_ESTIMATION].colors.toReversed().filter((item, ind) => ind < len),
            options: OPT_CONFIG[MODE.VOLUME_ESTIMATION].colors.filter((item, ind) => ind < (OPT_CONFIG[MODE.VOLUME_ESTIMATION].colors.length - len))
        }
        //VOL
        this.vol = volumeEstimation.data.vol
        //DATE
        this.date = volumeEstimation.date
    }
}
export default VolumeEstimation;
