import React, { useEffect, useState } from "react";

import * as ECT from "@whoicd/icd11ect";
import "./ICD.css";

import { SEARCH_FOR_ICD } from "../../../../dermuscomponents/src/Constants/Message";
import { Box, ClickAwayListener } from "@mui/material";
import { neutral90, neutralblack } from "../../../Themes/dermusTheme";
import useElementSize from "../../hooks/useElementSize";


// SEARCH BOX PADDING
const PADDING = 10;

/**
 *
 * @param {Object} props {onChooseDiagnosis: function}
 */
const ICD11Search = (props) => {
  //ID
  const [id] = useState("xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  }))
  //It is in focus or not
  const [focused, setFocused] = useState(false)
  //Search
  const [searchRef, searchSize] = useElementSize()

  //Init icd-11
  useEffect(() => {
    dispatchEvent(new Event("load"));
    ECT.Handler.selectedEntityFunction = (selectedEntity) => {
      props.onChooseDiagnosis(selectedEntity);
      props.setQuery(selectedEntity.title);
      setFocused(false)
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <Box ref={searchRef} sx={{ width: "100%", padding: "10px" }}>
        <input
          type="text"
          className="ctw-input"
          autoComplete="off"
          data-ctw-ino={id}
          value={props.query}
          onChange={(e) => props.setQuery(e.target.value)}
          placeholder={SEARCH_FOR_ICD}
          data-cy="ctw-input"
          onFocus={() => setFocused(true)}
          style={{
            color: neutralblack, paddingLeft: `${PADDING}px`, paddingRight: `${PADDING}px`, paddingTop: "5px", paddingBottom: "5px",
            borderRadius: "6px",
            background: neutral90,
            boxShadow: "0px",

          }}
        />
        <Box sx={{
          width: `${searchSize.width - 2 * PADDING}px`, display: focused ? "block" : "none", position: "absolute", backgroundColor: "#f1f1f1", overflow: "auto", zIndex: props.zIndex || 1
        }}>
          <Box className="ctw-window" data-ctw-ino={id}  ></Box>
        </Box>
      </Box>
    </ClickAwayListener >
  );
};

export default ICD11Search;
