import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalenderIcon } from "../../../../dermuscomponents/src/UI/Icons/Icons";
import { neutral90 } from "../../../../dermuscomponents/Themes/dermusTheme";

const CustomDatePicker = ({ value, onChange, label, onlyYear, minDate, maxDate, width }) => {
    return (
        < LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                format={onlyYear ? "YYYY" : "DD/MM/YY"}
                minDate={minDate}
                maxDate={maxDate}
                sx={{
                    width: width ? width : "114px",
                    height: "36px",
                    border: 0,
                    background: neutral90,
                    borderRadius: "6px",
                    "& .MuiOutlinedInput-root": {
                        '& fieldset': {     // - The <fieldset> inside the Input-root
                            border: 0,
                        },
                        '&:hover fieldset': {
                            border: 0,
                        },
                        '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
                            border: 0,
                        },
                    },
                }}
                placeholder={label}
                value={value}
                onChange={(e) => onChange(e)}
                slots={{ openPickerIcon: CalenderIcon }}
                slotProps={{
                    openPickerIcon: { fontSize: 'small' },
                    textField: {
                        inputProps: { style: { fontSize: "13px", padding: "8px 0px 8px 4px", margin: "0px" } },
                    },
                    actionBar: {
                        // The actions will be the same between desktop and mobile
                        actions: ["clear", "accept"]
                    },
                }}
                views={onlyYear ? ['year'] : ['year', 'month', 'day']}
            />
        </LocalizationProvider >
    );
};

export default CustomDatePicker;